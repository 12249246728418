import { adapter as cardAdapter } from '@molecules/Card';

export default ({ id, categories, highlightedCards }) => {
  const mappedHighlightedCards =
    highlightedCards && highlightedCards.map((card) => cardAdapter(card));

  const parsedHighlightedCards =
    mappedHighlightedCards &&
    mappedHighlightedCards.reduce((unique, item) => {
      if (!unique.find((card) => card.categoryType === item.categoryType)) {
        return [...unique, item];
      }
      return unique;
    }, []);

  return {
    id,
    categories: categories.map(({ name, cards }) => {
      const mappedCards = cards.map((card) => cardAdapter(card));

      let finalCards = [...mappedCards];
      if (parsedHighlightedCards.some((card) => card.categoryType === name)) {
        const highlightedCard = parsedHighlightedCards.find(
          (card) => card.categoryType === name
        );
        finalCards = finalCards.filter(
          (card) => card.id !== highlightedCard.id
        );
        finalCards.unshift(highlightedCard);
      }
      return {
        name,
        cards: finalCards,
      };
    }),
  };
};
