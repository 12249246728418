import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { adapter as linkAdapter } from '@atoms/Link';

export default ({
  title,
  subtitle,
  body,
  tooltip,
  cta,
  imageDesktop,
  imageMobile,
  imagePosition,
  themeMode,
}) => ({
  title,
  subtitle,
  body,
  tooltip,
  cta: cta && linkAdapter(cta),
  image: imageMobile &&
    imageDesktop && {
      small: getImageFields(imageMobile, IMG_TYPES.fluid),
      large: getImageFields(imageDesktop, IMG_TYPES.fluid),
    },
  imagePosition,
  themeMode,
});
