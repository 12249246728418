import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { breakpoints } from '@utils';
import { CardSquare } from '@molecules/Card';
import CardsAccordion from '@organisms/CardsAccordion';

const Title = styled.h2`
  ${({ theme }) => css`
    ${({ isCalculator }) =>
      isCalculator ? theme.typography.titleL : theme.typography.titleXL};
    color: ${theme.colors.white};
    padding: 0 var(--outer-gap);
    margin-bottom: ${theme.spacing(3)};
    ${({ isCalculator }) => isCalculator && `text-align: center`};

    ${theme.mediaquery.lg(css`
      max-width: 35%;
    `)}
    ${theme.mediaquery.md(css`
      max-width: 40%;
      box-sizing: content-box;
    `)}

    ${theme.mediaquery.sm(css`
      padding: 0 ${({ isCalculator }) =>
        isCalculator ? 0 : `calc(var(--outer-gap) + var(--col) * 3`});
      margin-bottom: ${theme.spacing(4)};
      max-width: ${({ isCalculator }) => (isCalculator ? '60%' : '')};
    `)}
  `}
`;

const SubTitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    padding: 0 var(--outer-gap);
    margin-bottom: ${theme.spacing(3)};

    ${theme.mediaquery.md(css`
      max-width: 40%;
      box-sizing: content-box;
    `)}

    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const StyledSection = styled.section`
  ${({ isCalculator }) =>
    isCalculator &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
    `}
`;

const RelatedCardsCarousel = ({
  title,
  subtitle,
  cards,
  hiddenLabel,
  visibleLabel,
  className,
  cardsShown = 3,
  isCalculator = false,
}) => {
  if (cards.length === 0) {
    return null;
  }

  return (
    <StyledSection className={className} isCalculator={isCalculator}>
      {!!title && <Title isCalculator={isCalculator}>{title}</Title>}
      {!!subtitle && <SubTitle>{subtitle}</SubTitle>}

      <CardsAccordion
        cards={cards}
        hiddenLabel={hiddenLabel}
        visibleLabel={visibleLabel}
        isCalculator={isCalculator}
        renderCard={(card) => (
          <CardSquare
            {...{
              ...card,
              background: {
                ...card.background,
                sizes: `(min-width: ${breakpoints.sm}px) 23vw, 43vw`,
              },
            }}
          />
        )}
        cardsShown={cardsShown}
        maxHeight="500rem"
      />
    </StyledSection>
  );
};

RelatedCardsCarousel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(CardSquare.propTypes)).isRequired,
  hiddenLabel: PropTypes.string,
  visibleLabel: PropTypes.string,
  className: PropTypes.string,
  cardsShown: PropTypes.number,
  isCalculator: PropTypes.bool,
};

export default RelatedCardsCarousel;
