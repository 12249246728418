export default ({
  id,
  enableTwitter,
  enableFacebook,
  enableCopyToClipboard,
  shareDescription,
  alignment,
}) => ({
  id,
  enableTwitter,
  enableFacebook,
  enableCopy: enableCopyToClipboard,
  twitterCopy: shareDescription && shareDescription.shareDescription,
  centered: alignment === 'center',
});
