import React, { useContext, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import styled, { css } from 'styled-components';
import { SettingsContext } from '@stores/Settings';
import ChipSelector from '@molecules/ChipSelector';
import { grid } from '@styles/grid';
import { breakpoints } from '@utils';
import { useWindowSize } from '@hooks';
import Card from '@molecules/Card';

const MENU_ANIMATION_DURATION = 750;
const BEZIER = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)';

const regularSizes = `(min-width: ${breakpoints.xs}px) 158px, (min-width: ${breakpoints.sm}px) 21vw, (min-width: ${breakpoints.md}px) 16vw, 87vw`;
const highlightedSizes = `(min-width: ${breakpoints.xs}px) 340px, (min-width: ${breakpoints.sm}px) 44vw, (min-width: ${breakpoints.md}px) 34vw, 87vw`;

const TopContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(20)} ${theme.spacing(5)} 0 0;
  `}
`;

const TitleWrapper = styled.div`
  ${grid};
`;

const FilterTitle = styled.div`
  ${({ theme }) => css`
    ${theme.typography.titleXS}
    margin-bottom: ${theme.spacing(2)};
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    color: ${theme.colors.white};
    grid-column: 1 / -1;

    ${theme.mediaquery.sm(css`
      text-align: center;
      grid-column: 7 / span 12;
    `)}
  `}
`;

const NoArticles = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.white};
    grid-column: 1 / -1;

    ${theme.mediaquery.sm(css`
      text-align: center;
    `)}
  `}
`;

const FiltersContainer = styled.div`
  grid-column: 1 / -1;

  ${({ theme }) => css`
    ${theme.mediaquery.md(css`
      grid-column: 1 / 12;
    `)}
  `}
`;

const ChipSelectorStyled = styled(ChipSelector)`
  ${({ theme }) => css`
    & > div {
      justify-content: flex-start;
    }
    margin-bottom: ${theme.spacing(5)};
  `}
`;

const GridContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const Grid = styled.ul`
  ${({ theme }) => css`
    ${grid};
    grid-row-gap: var(--inner-gap);
    max-width: calc(340px + var(--inner-gap) * 2);
    margin: var(--inner-gap) auto;
    opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
    transition: opacity ${MENU_ANIMATION_DURATION}ms ${BEZIER};

    ${theme.mediaquery.sm(css`
      --columns: 8;
      padding: 0 calc(var(--outer-gap) + var(--col) * 6);
      max-width: none;
    `)}

    ${theme.mediaquery.md(css`
      --columns: 12;
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
    `)}
  `}
`;

const Item = styled.li`
  grid-column: 1 / -1;

  ${({ theme, highlighted }) => css`
    ${theme.mediaquery.xs(css`
      grid-column: ${!highlighted && 'span 2'};
    `)}

    ${theme.mediaquery.sm(css`
      grid-column: ${!highlighted && 'span 4'};
    `)}

  ${theme.mediaquery.md(css`
      grid-column: ${highlighted ? 'span 6' : 'span 3'};
      grid-row: ${highlighted ? 'span 2' : 'initial'};
    `)}
  `}
`;

// Hardcoded positions of Highlighted cards in the grid (UX design)
const HIGHLIGHTED_CARD_POSITION_DESKTOP = [1, 8, 12, 16, 23];
const HIGHLIGHTED_CARD_POSITION_MOBILE = [3, 8, 13, 18, 23];

const Article = ({ cards, highlightedCards }) => {
  const windowSizes = useWindowSize();
  const [highlightedPositions, setHighlightedPositions] = useState(
    HIGHLIGHTED_CARD_POSITION_MOBILE
  );
  // updates on first render. We need to check the window on client
  useEffect(() => {
    setHighlightedPositions(
      windowSizes[0] <= breakpoints.sm
        ? HIGHLIGHTED_CARD_POSITION_MOBILE
        : HIGHLIGHTED_CARD_POSITION_DESKTOP
    );
  }, [windowSizes]);

  const [selectedCategoryFilters, setSelectedCategoryFilters] = useState([]);
  const [selectedContentTypeFilters, setSelectedContentTypeFilters] = useState(
    []
  );
  const [isVisible, setIsVisible] = useState(false);

  const { allArticlesPageTitle, translations } = useContext(SettingsContext);
  const {
    editorialLabel,
    influencerLabel,
    recipeLabel,
    videoLabel,
    categoryFilterLabel,
    contentTypeFilterLabel,
    noArticlesLabel,
  } = translations;

  const contentTypeLabelMap = useMemo(
    () => ({
      editorial: editorialLabel,
      recipe: recipeLabel,
      influencer: influencerLabel,
      video: videoLabel,
    }),
    [editorialLabel, recipeLabel, influencerLabel, videoLabel]
  );

  // Determine which cards are the highlighted ones in the array of cards
  const highlightedIds = highlightedCards.map((c) => c.id);
  const cleanCards = cards.filter((card) => !highlightedIds.includes(card.id));

  // gives the hardcoded positions of Highlighted cards in the grid
  const shuffledCards = useMemo(() => {
    highlightedCards.map((item, index) => {
      const position = highlightedPositions[index] - 1;

      return cleanCards.splice(position, 0, item);
    });
    return cleanCards;
  }, [cleanCards, highlightedCards, highlightedPositions]);

  // divides the cards in chunks to display in rows
  const visibleCards = useMemo(
    () =>
      [...shuffledCards].filter((card) => {
        if (selectedContentTypeFilters.length) {
          if (!selectedContentTypeFilters.includes(card?.type)) {
            return false;
          }
        }

        if (selectedCategoryFilters.length) {
          if (!selectedCategoryFilters.includes(card?.categoryType)) {
            return false;
          }
        }

        return true;
      }),
    [shuffledCards, selectedCategoryFilters, selectedContentTypeFilters]
  );

  // prepares the type filters
  const contentTypeFilters = useMemo(
    () =>
      cards.length &&
      cards.reduce((unique, item) => {
        return unique.some((i) => i?.type === item.type)
          ? unique
          : [
              ...unique,
              {
                ...item,
                key: item.type,
                label: contentTypeLabelMap[item.type],
              },
            ];
      }, []),
    [cards, contentTypeLabelMap]
  );

  // prepare the category type filters
  const categoryTypeFilters = useMemo(
    () =>
      cards.length &&
      cards.reduce((unique, item) => {
        return unique.some((i) => i?.categoryType === item.categoryType)
          ? unique
          : [
              ...unique,
              {
                ...item,
                key: item.categoryType,
                label: item.categoryType,
              },
            ];
      }, []),
    [cards]
  );

  // handles change of filters
  const updateFilters = (newFilters, type) => {
    if (type === 'category') {
      setSelectedCategoryFilters(newFilters);
    } else if (type === 'content-type') {
      setSelectedContentTypeFilters(newFilters);
    }
  };

  const getSelectedFilters = (urlParams) => {
    const params = urlParams.replace('?', '');
    params.split('&').forEach((filter) => {
      const [filterType, filterValue] = filter.split('=');
      const filterValueArray = filterValue.split(',');
      switch (filterType) {
        case 'ct':
          setSelectedContentTypeFilters(filterValueArray);
          break;
        case 'c':
          setSelectedCategoryFilters(filterValueArray);
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (isVisible) {
      const categoryParam =
        selectedCategoryFilters.length > 0
          ? `c=${selectedCategoryFilters.join(',')}`
          : null;
      const contentTypeParam =
        selectedContentTypeFilters.length > 0
          ? `ct=${selectedContentTypeFilters.join(',')}`
          : null;
      const urlParams = `?${categoryParam || ''}${
        categoryParam && contentTypeParam ? '&' : ''
      }${contentTypeParam || ''}`;
      navigate(urlParams, {
        replace: true,
        state: { keepScrollPosition: true },
      });
    }
  }, [selectedCategoryFilters, selectedContentTypeFilters, isVisible]);

  useEffect(() => {
    const urlParams = window.location.search;
    if (urlParams && urlParams !== '') {
      getSelectedFilters(urlParams);
    }
    setTimeout(() => {
      setIsVisible(true);
    }, 300);
  }, []);

  return (
    <>
      <TopContainer>
        <TitleWrapper>
          <Title>{allArticlesPageTitle}</Title>
        </TitleWrapper>
      </TopContainer>
      <GridContainer>
        <Grid isVisible>
          <FiltersContainer>
            <FilterTitle>{contentTypeFilterLabel}</FilterTitle>
            <ChipSelectorStyled
              items={contentTypeFilters}
              onChange={(newFilters) =>
                updateFilters(newFilters, 'content-type')
              }
              selectedChips={selectedContentTypeFilters}
            />
            <FilterTitle>{categoryFilterLabel}</FilterTitle>
            <ChipSelectorStyled
              items={categoryTypeFilters}
              onChange={(newFilters) => updateFilters(newFilters, 'category')}
              selectedChips={selectedCategoryFilters}
            />
          </FiltersContainer>
        </Grid>
        <Grid isVisible={isVisible}>
          {visibleCards.length ? (
            visibleCards.map((card, index) => {
              const isHighlighted =
                !selectedContentTypeFilters.length &&
                !selectedCategoryFilters.length &&
                highlightedPositions.includes(index + 1);
              return (
                <Item key={card.id} highlighted={isHighlighted}>
                  <Card
                    {...{
                      ...card,
                      background: {
                        ...card.background,
                        sizes: isHighlighted ? highlightedSizes : regularSizes,
                      },
                    }}
                    highlighted={isHighlighted}
                  />
                </Item>
              );
            })
          ) : (
            <NoArticles>{noArticlesLabel}</NoArticles>
          )}
        </Grid>
      </GridContainer>
    </>
  );
};

Article.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)),
  highlightedCards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)),
};

export default Article;
