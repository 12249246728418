// TODO : remove disable-rule and refactor code
/* eslint-disable no-nested-ternary */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { Transition } from 'react-transition-group';
import { useSwipeable } from 'react-swipeable';

import { debounce } from '@utils';
import Img, { IMG_FITS } from '@atoms/Img';
import VideoPush from '@molecules/VideoPush';
import TextModule from '@atoms/TextModule';
import Dots from '@atoms/Dots';
import NavigationButton, { NAVIGATION_TYPES } from '@atoms/NavigationButton';
import { fullbleedgrid } from '@styles/grid';

const ANIMATION_DURATION = 580;

const Outer = styled.section``;

const Container = styled.div`
  ${fullbleedgrid};

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      align-items: center;
    `)}
`;

const CurrentCol = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: 1 / span 13;
    `)}
  `}
`;

const CurrentOuter = styled.div`
  position: relative;
  overflow: hidden;
  /* prevents an overflow issue on safari (found in this gist's comments : https://gist.github.com/ayamflow/b602ab436ac9f05660d9c15190f4fd7b) */
  ${({ theme }) => css`
    z-index: ${theme.zIndex.featuredPostCarousel};
    border-top-right-radius: ${theme.spacing(5)};
    border-bottom-right-radius: ${theme.spacing(5)};
  `}
`;

const CurrentInner = styled.div`
  position: relative;
  padding-bottom: 100%;

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      padding-bottom: 80%;
    `)}
`;

const generatePictureAnimation = (exiting, right) => {
  return keyframes`
    0% {
      opacity: ${exiting ? 1 : 0};
      transform: translateX(${exiting ? 0 : right ? 20 : -20}%);
    }
    100% {
      opacity: ${exiting ? 0 : 1};
      transform: translateX(${exiting ? (right ? 20 : -20) : 0}%);
    }
  `;
};

const pictureAnimationMap = {
  enteringright: generatePictureAnimation(false, true),
  enteringleft: generatePictureAnimation(false, false),
  exitingright: generatePictureAnimation(true, false),
  exitingleft: generatePictureAnimation(true, true),
};

const PictureAnimation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ state, sliding }) =>
    state === 'exited'
      ? css`
          display: none;
        `
      : state === 'entered'
      ? css`
          display: block;
        `
      : pictureAnimationMap[state + sliding] &&
        css`
          animation: ${pictureAnimationMap[state + sliding]}
            ${ANIMATION_DURATION}ms cubic-bezier(0.58, 0, 0.56, 1) both;
        `}
`;

const CopyCol = styled.div`
  ${({ theme }) => css`
    grid-column: 2 / -2;
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      grid-column: 16 / span 6;
      margin-top: 0;
    `)}
  `}
`;

const CopyInner = styled.div`
  position: relative;
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 2;
  bottom: 0;
  transform: translateY(50%);
`;

const LeftContainer = styled(ArrowContainer)`
  ${({ theme }) => css`
    left: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      left: ${theme.spacing(10)};
    `)}
  `}
`;

const RightContainer = styled(ArrowContainer)`
  ${({ theme }) => css`
    right: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      right: ${theme.spacing(10)};
    `)}
  `}
`;

const textAnimationEntering = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const textAnimationExiting = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;

const TextAnimation = styled.div`
  top: 0;
  position: ${({ state }) => (state.match(/^enter/) ? 'relative' : 'absolute')};
  ${({ state }) => state === 'exited' && `display: none`};
  animation: ${({ state }) =>
      state === 'entering'
        ? textAnimationEntering
        : state === 'exiting'
        ? textAnimationExiting
        : null}
    ${ANIMATION_DURATION}ms linear;
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleM};
    `)}
  `}
`;

const marginTop = css`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(2)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(4)};
    `)}
  `}
`;

const StyledTextModule = styled(TextModule)`
  display: block;
  padding: 0;
`;

const Subtitle = styled.div`
  ${marginTop};
`;

const StyledDots = styled(Dots)`
  ${marginTop};
`;

const NextCol = styled.div`
  display: none;

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      display: block;
      position: relative;
      overflow: hidden;
      grid-column: span 4 / -1;
      height: calc(var(--col) * 6 - var(--inner-gap) + var(--outer-gap));
    `)}
`;

const NextInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: calc(var(--col) * 4 - var(--inner-gap) + var(--outer-gap));
  height: 100%;
`;

const FeaturedPostCarousel = ({ items, ...rest }) => {
  const outer = React.createRef();
  const currentOuter = React.createRef();
  const [currentKey, setCurrentKey] = useState(0);
  const [[showLeftArrow, showRightArrow], setShowArrows] = useState([
    false,
    items.length > 1,
  ]);
  const [lastSlide, setLastSlide] = useState(undefined);
  const [nextSize, setNextSize] = useState(0);
  const nextKey = currentKey + 1 >= items.length ? null : currentKey + 1;

  const slideTo = (toKeyDirty) => {
    const toKey = Math.min(Math.max(0, toKeyDirty), items.length - 1);
    const direction = toKey > currentKey ? 'right' : 'left';
    setLastSlide(direction);
    setTimeout(() => {
      // setTimeout: ensure that the last component state that Transition sees is one which will animate *out*
      setCurrentKey(toKey);
      setShowArrows([toKey > 0, toKey < items.length - 1]);
    });
  };

  const slide = (direction) => {
    const toKey = currentKey + direction;
    slideTo(toKey);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      slide(+1);
    },
    onSwipedRight: () => {
      slide(-1);
    },
  });

  const onResize = () => {
    if (currentOuter.current) {
      setNextSize(Math.round(currentOuter.current.clientWidth * 0.65));
    }
  };

  const onKeypress = (e) => {
    const direction = {
      ArrowRight: +1,
      ArrowLeft: -1,
    }[e.code];
    if (direction) {
      slide(direction);
    }
  };

  useEffect(() => {
    onResize();

    const debouncedOnResize = debounce(onResize, 250);
    window.addEventListener('resize', debouncedOnResize, false);

    const el = outer.current;
    el.addEventListener('keyup', onKeypress);

    return () => {
      window.removeEventListener('resize', debouncedOnResize, false);
      el.removeEventListener('keyup', onKeypress);
    };
  });

  return (
    <Outer {...rest} ref={outer} tabIndex={0}>
      <Container {...swipeHandlers}>
        <CurrentCol>
          <CurrentOuter ref={currentOuter}>
            <CurrentInner>
              {items.map(({ id, picture, video }, key) => (
                <Transition
                  key={`current_${id}`}
                  in={key === currentKey}
                  timeout={ANIMATION_DURATION}
                >
                  {(state) => (
                    <PictureAnimation state={state} sliding={lastSlide}>
                      {video ? (
                        state !== 'exited' ? (
                          <VideoPush
                            cloudinaryVideo={video.cloudinaryVideo}
                            videoCover={{ small: picture, large: picture }}
                            squareCorners
                            disableFullscreen
                          />
                        ) : (
                          <>{/* stop videos from playing when not visible */}</>
                        )
                      ) : (
                        <Img
                          small={picture}
                          fit={IMG_FITS.cover}
                          css={css`
                            position: absolute;
                          `}
                        />
                      )}
                    </PictureAnimation>
                  )}
                </Transition>
              ))}
            </CurrentInner>
          </CurrentOuter>
          {!!showLeftArrow && (
            <LeftContainer>
              <NavigationButton onClick={() => slide(-1)} />
            </LeftContainer>
          )}
          {!!showRightArrow && (
            <RightContainer>
              <NavigationButton
                direction={NAVIGATION_TYPES.right}
                onClick={() => slide(+1)}
              />
            </RightContainer>
          )}
        </CurrentCol>
        <CopyCol>
          <CopyInner>
            {items.map(({ id, title, subtitle }, key) => (
              <Transition
                key={`copy_${id}`}
                in={key === currentKey}
                timeout={ANIMATION_DURATION}
              >
                {(state) => (
                  <TextAnimation state={state}>
                    <Title>{title}</Title>
                    {subtitle && (
                      <Subtitle>
                        <StyledTextModule enableRenderer doc={subtitle} />
                      </Subtitle>
                    )}
                  </TextAnimation>
                )}
              </Transition>
            ))}
          </CopyInner>
          {items.length > 1 && (
            <StyledDots
              items={items.map(({ id }, key) => ({
                id,
                label: key,
                active: key === currentKey,
              }))}
              onSelect={(_, key) => slideTo(key)}
            />
          )}
        </CopyCol>
        <NextCol>
          {!!nextSize && (
            <NextInner>
              {items.map(({ id, picture }, key) => (
                <Transition
                  key={`next_${id}`}
                  in={key === nextKey}
                  timeout={ANIMATION_DURATION}
                >
                  {(state) => (
                    <>
                      <PictureAnimation state={state} sliding={lastSlide}>
                        <Img small={picture} fit={IMG_FITS.cover} />
                      </PictureAnimation>
                    </>
                  )}
                </Transition>
              ))}
            </NextInner>
          )}
        </NextCol>
      </Container>
    </Outer>
  );
};

FeaturedPostCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.object,
      picture: PropTypes.object.isRequired,
      video: PropTypes.object,
    })
  ).isRequired,
};

export default React.memo(FeaturedPostCarousel);
