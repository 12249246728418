import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

export const BUTTON_TYPES = Object.freeze({
  primary: 'primary',
  secondary: 'secondary',
});

export const buttonStyle = (theme, type, lightTheme = false) => css`
  border-radius: ${theme.spacing(2)};
  padding: ${theme.spacing(2)} ${theme.spacing(3)};
  cursor: pointer;
  ${theme.typography.titleS};

  ${type === BUTTON_TYPES.primary
    ? css`
        border: 0;
        background-color: ${lightTheme
          ? theme.colors.black
          : theme.colors.white};
        color: ${lightTheme ? theme.colors.white : theme.colors.black};
        transition: background-color 200ms ease-in-out;

        &:hover {
          background-color: ${theme.colors.hoverGray};
        }

        &:active {
          background-color: ${theme.colors.pressedGray};
        }
      `
    : css`
        border: 1px solid
          ${lightTheme ? theme.colors.black : theme.colors.white};
        background-color: ${lightTheme
          ? theme.colors.white
          : theme.colors.black};
        color: ${lightTheme ? theme.colors.black : theme.colors.white};
      `}
`;

const Outer = styled.button`
  ${({ theme, type }) => css`
    ${buttonStyle(theme, type)}
  `}
`;

const Inner = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = React.memo(({ type, children, ...rest }) => {
  return (
    <Outer type={type} {...rest}>
      <Inner>{children}</Inner>
    </Outer>
  );
});

Button.propTypes = {
  type: PropTypes.oneOf(Object.values(BUTTON_TYPES)),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

Button.defaultProps = {
  type: BUTTON_TYPES.primary,
};

export default Button;
