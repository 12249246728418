import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import { SettingsContext } from '@stores/Settings';

import Facebook from '@icons/facebook.svg';
import Pinterest from '@icons/pinterest.svg';
import Instagram from '@icons/instagram.svg';
import Twitter from '@icons/twitter.svg';
import Youtube from '@icons/youtube.svg';

const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: calc(35vw / 3.5) 0;

  ${({ theme }) => css`
    background-color: ${theme.colors.white};
    color: ${theme.colors.black};
  `}
`;

const PushContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--outer-gap);
    padding: 0 var(--outer-gap);
    width: 100%;

    ${theme.mediaquery.sm(css`
      flex-direction: row;
      width: 70vw;
    `)}
  `}
`;

const TextsContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    flex: 0 0 auto;

    ${theme.mediaquery.sm(css`
      width: 50%;
    `)}
  `}
`;

const SideTitle = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    display: -webkit-box;
    position: relative;

    ${theme.mediaquery.md(css`
      margin-bottom: 0;
      margin-top: ${theme.spacing(3)};
    `)}

    ${theme.mediaquery.lg(css`
      width: 80%;
    `)}
  `}
`;

const SocialMediaContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${theme.mediaquery.sm(css`
      width: 50%;
      align-items: center;
    `)}
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
`;

const SocialMediaTitle = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    margin-top: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      text-align: center;
    `)}
  `}
`;

const Social = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;

  ${({ theme }) => css`
    margin-top: ${theme.spacing(3)};
    gap: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      justify-content: center;
    `)}
  `}
`;

const SocialItem = styled.a`
  ${({ theme }) => css`
    svg {
      &,
      path {
        fill: ${theme.colors.black};
      }
    }
  `}
`;

const icon = (name) =>
  ({
    facebook: Facebook,
    pinterest: Pinterest,
    instagram: Instagram,
    twitter: Twitter,
    youTube: Youtube,
  }[name]);

const SocialMedia = (props) => {
  const { sideTitle, socialMediaTitle, image } = props;
  const { footer } = useContext(SettingsContext);

  const {
    facebookLink,
    twitterLink,
    instaLink,
    pinterestLi,
    youtubeLink,
  } = footer;

  const socialLinks = [
    {
      name: 'facebook',
      url: facebookLink,
    },
    {
      name: 'twitter',
      url: twitterLink,
    },
    {
      name: 'instagram',
      url: instaLink,
    },
    {
      name: 'pinterest',
      url: pinterestLi,
    },
    {
      name: 'youTube',
      url: youtubeLink,
    },
  ].filter((item) => item.url);

  return (
    <Container>
      <PushContainer>
        <TextsContainer>
          <SideTitle>{sideTitle}</SideTitle>
        </TextsContainer>
        <SocialMediaContainer>
          {image && (
            <ImageContainer>
              <Img
                type={IMG_TYPES.fluid}
                fits={IMG_FITS.none}
                small={image.small}
                medium={image.large}
                alt={sideTitle}
              />
            </ImageContainer>
          )}
          {socialMediaTitle && (
            <SocialMediaTitle>{socialMediaTitle}</SocialMediaTitle>
          )}
          {!!socialLinks && (
            <Social>
              {socialLinks.map((socialLink) => {
                const Icon = icon(socialLink.name);
                return (
                  <SocialItem
                    key={socialLink.name}
                    target="_blank"
                    href={socialLink.url}
                    rel="noopener"
                  >
                    <Icon width="24px" height="24px" />
                  </SocialItem>
                );
              })}
            </Social>
          )}
        </SocialMediaContainer>
      </PushContainer>
    </Container>
  );
};

SocialMedia.propTypes = {
  sideTitle: PropTypes.string.isRequired,
  socialMediaTitle: PropTypes.string.isRequired,
  image: PropTypes.object,
};

export default SocialMedia;
