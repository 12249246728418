import React, { useState, useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ClipboardJS from 'clipboard';

import { SettingsContext } from '@stores/Settings';

import Facebook from '@icons/facebook.svg';
import Twitter from '@icons/twitter.svg';
import CopyUrl from '@icons/link.svg';

import Bubble from '@atoms/Bubble';

const POPUP_SIZE = 570;
const BORDER_RADIUS = '8px';

const ShareContainer = styled.ul`
  display: inline-grid;
  grid-template-columns: repeat(3, minmax(0, 30px));
  grid-column-gap: 2px;
  height: 30px;
  padding-left: var(--outer-gap);

  ${({ theme, centered }) => css`
    ${theme.mediaquery.sm(css`
      padding-left: calc(var(--outer-gap) + var(--col) * 2);
      ${centered &&
      css`
        padding: 0;
        display: grid;
        justify-content: center;
      `}
    `)}

    ${theme.mediaquery.md(css`
      padding: 0;
      margin: 0 !important;
      position: absolute;
      top: ${theme.spacing(0.4)};
      right: calc(var(--outer-gap) + var(--col) * 6);
      grid-template-rows: repeat(3, minmax(0, 30px));
      grid-template-columns: initial;
      height: initial;
      width: 30px;
      grid-row-gap: ${theme.spacing(2)};
      justify-content: stretch;
    `)}
  `}
`;

const ShareItem = styled.li`
  grid-column: span 1;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;

  &:first-of-type {
    border-top-left-radius: ${BORDER_RADIUS};
    border-bottom-left-radius: ${BORDER_RADIUS};
  }

  &:last-of-type {
    border-top-right-radius: ${BORDER_RADIUS};
    border-bottom-right-radius: ${BORDER_RADIUS};
  }

  svg {
    display: block;

    path {
      fill: ${({ theme }) => theme.colors.black};
    }
  }

  ${({ theme }) =>
    theme.mediaquery.md(css`
      border-radius: ${BORDER_RADIUS};
    `)}
`;

const Button = styled.button`
  outline: none;
  cursor: pointer;
  background: transparent;
`;

const StyledBubble = styled(Bubble)`
  position: absolute;
  bottom: 50px;
  width: 260px;
  max-width: 70vw;
  left: -8px;
  transform: none;

  > div:after {
    left: 16px;
    transform: none;
  }
`;

const styledIcon = css`
  width: 16px;
  height: 16px;
`;

const Share = ({
  enableFacebook = true,
  enableTwitter = true,
  enableCopy = true,
  twitterCopy,
  centered,
}) => {
  const { translations } = useContext(SettingsContext);
  const [bubbleOpened, setBubbleOpened] = useState(false);
  const [documentURL, setDocumentURL] = useState('');
  const copyToClipboardButton = useRef(null);

  const socialWindow = (url) => {
    const left = (window.screen.width - POPUP_SIZE) / 2;
    const top = (window.screen.height - POPUP_SIZE) / 2;
    const params = `menubar=no,toolbar=no,status=no,width=570,height=570,top=${top},left=${left}`;
    window.open(url, 'NewWindow', params);
  };

  useEffect(() => {
    setDocumentURL(document.URL);
  }, []);

  const pageUrl = encodeURIComponent(documentURL);

  useEffect(() => {
    if (enableCopy) {
      const clipboard = new ClipboardJS(copyToClipboardButton.current);

      clipboard.on('success', () => {
        setBubbleOpened(true);

        setTimeout(() => {
          setBubbleOpened(false);
        }, 4000);
      });
    }
  }, [enableCopy]);

  return (
    <ShareContainer centered={centered}>
      {!!enableFacebook && (
        <ShareItem>
          <Button
            onClick={() => {
              socialWindow(`https://www.facebook.com/sharer.php?u=${pageUrl}`);
            }}
          >
            <Facebook css={styledIcon} />
          </Button>
        </ShareItem>
      )}
      {!!enableTwitter && (
        <ShareItem>
          <Button
            onClick={() => {
              socialWindow(
                `https://twitter.com/intent/tweet?url=${pageUrl}&text=${
                  twitterCopy || ''
                }`
              );
            }}
          >
            <Twitter css={styledIcon} />
          </Button>
        </ShareItem>
      )}
      {!!enableCopy && (
        <ShareItem style={{ position: 'relative' }}>
          <StyledBubble
            onClose={() => setBubbleOpened(false)}
            isOpen={bubbleOpened}
          >
            {translations.shareUrlPopupLabel}
          </StyledBubble>
          <Button ref={copyToClipboardButton} data-clipboard-text={documentURL}>
            <CopyUrl css={styledIcon} />
          </Button>
        </ShareItem>
      )}
    </ShareContainer>
  );
};

Share.propTypes = {
  enableFacebook: PropTypes.bool,
  enableTwitter: PropTypes.bool,
  enableCopy: PropTypes.bool,
  twitterCopy: PropTypes.string,
  centered: PropTypes.bool.isRequired,
};

export default Share;
