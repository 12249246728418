import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import Modal from '@molecules/Modal';
import { buttonStyle } from '@atoms/Button';
import TextModule from '../../atoms/TextModule/TextModule';

const Button = styled.button`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin-top: ${theme.spacing(4)};
    display: inline-block;
  `}
  &[disabled] {
    opacity: 0.4;
  }
`;

const InnerContainer = styled.a`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    padding: ${theme.spacing(2)};
    text-align: center;

    ${theme.mediaquery.sm(css`
      padding: ${theme.spacing(8)};
    `)}
  `}
`;

const Disclaimer = ({
  disclaimerTitle,
  disclaimerText,
  checkboxLabel,
  buttonLabel,
  disclaimerVisible,
  setDisclaimerVisible,
  isSavedInStorage,
  setIsSavedInStorage,
  handleLocalStorage,
}) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked((v) => !v);
  };

  useEffect(() => {
    if (
      !isSavedInStorage &&
      !!disclaimerTitle &&
      JSON.parse(
        localStorage.getItem(`${disclaimerTitle} displayed`) === 'true'
      )
    ) {
      setIsSavedInStorage(true);
    }
  }, [isSavedInStorage, disclaimerTitle, setIsSavedInStorage]);

  return (
    <>
      {!isSavedInStorage && disclaimerVisible && (
        // TODO support keyboard navigation and esc to close without skipping terms.
        <Modal isOpen={disclaimerVisible && !isSavedInStorage} isDisclaimer>
          <InnerContainer>
            {disclaimerText && (
              <TextModule doc={disclaimerText} enableRenderer align="center" />
            )}
            <Checkbox
              label={checkboxLabel}
              checked={checked}
              onChange={handleChange}
              id="disclaimer"
            />
            <Button
              onClick={() => {
                setDisclaimerVisible(false);
                handleLocalStorage();
                setIsSavedInStorage(false);
              }}
              disabled={!checked}
            >
              {buttonLabel}
            </Button>
          </InnerContainer>
        </Modal>
      )}
    </>
  );
};

const Input = styled.input`
  height: 0;
  width: 0;
  opacity: 0;
  z-index: -1;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  margin: 0.6em 1em;
`;

const rotate = keyframes`
 from {
    opacity: 0;
    transform: rotate(0deg);
  }
  to {
    opacity: 1;
    transform: rotate(45deg);
  }
`;

const Indicator = styled.div`
  width: 1.2em;
  height: 1.2em;
  background: #e6e6e6;
  position: absolute;
  top: 0em;
  left: -1.6em;
  border: 1px solid #757575;
  border-radius: 0.2em;

  ${Input}:not(:disabled):checked & {
    background: #d1d1d1;
  }

  ${Label}:hover & {
    background: #ccc;
  }

  &::after {
    content: '';
    position: absolute;
    display: none;
  }

  ${Input}:checked + &::after {
    display: block;
    top: 0.1em;
    left: 0.35em;
    width: 35%;
    height: 70%;
    border: solid #263238;
    border-width: 0 0.2em 0.2em 0;
    animation-name: ${rotate};
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &::disabled {
    cursor: not-allowed;
  }
`;

const Checkbox = ({ value, checked, onChange, name, id, label }) => {
  return (
    <Label htmlFor={id}>
      {label}
      <Input
        id={id}
        type="checkbox"
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <Indicator />
    </Label>
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  label: PropTypes.string,
};

Disclaimer.propTypes = {
  disclaimerTitle: PropTypes.string.isRequired,
  disclaimerText: PropTypes.object,
  checkboxLabel: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  disclaimerVisible: PropTypes.bool,
  setDisclaimerVisible: PropTypes.func,
  isSavedInStorage: PropTypes.bool,
  setIsSavedInStorage: PropTypes.func,
  handleLocalStorage: PropTypes.func,
};

export default Disclaimer;
