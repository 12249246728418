import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  id,
  coverImageDesktop,
  coverImagemobilie,
  videoDesktop,
  videoMobile,
  autoplay,
}) => ({
  id,
  videoCover: {
    small: getImageFields(coverImagemobilie, IMG_TYPES.fluid),
    large: getImageFields(coverImageDesktop, IMG_TYPES.fluid),
  },
  cloudinaryVideo: videoDesktop && videoDesktop[0],
  mobileCloudinaryVideo: videoMobile && videoMobile[0],
  autoplay,
});
