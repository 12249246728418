import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import Hero, { adapter as heroAdapter } from '@organisms/Hero';
import PromotionalOverlay from '@organisms/PromotionalOverlay';
import { getSettings, getPageMeta, getCommonJSON } from '@utils';
import CategoryWatermark from '@atoms/CategoryWatermark';
import PageComponents from './PageComponents';
import trackEvent from './trackEvent';

const CategoryWrapper = styled.div`
  width: 100vh;
  height: 100vw;
  position: fixed;
  top: 100vh;
  left: 0;
  pointer-events: none;
  transform: rotate(-90deg);
  transform-origin: top left;
  z-index: 1;
  position: fixed;
  display: flex;
  align-items: flex-end;
  justify-content: center;
`;

const ContentPage = ({ data, pageContext, path }) => {
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulContentPage;
  const pageSeo = getPageMeta(page.seo, settings.brandName);
  const heroProps = heroAdapter(page.hero);
  const promotionalOverlayProps = settings?.promotionalOverlay;
  const jsonld = [
    ...getCommonJSON(page, settings, heroProps, settings.brandName),
  ];

  const author = page?.author;
  const category = page.category && page.category.name;
  const isFunnelFirst =
    page.pageComponents[0]?.__typename === 'ContentfulProgramFunnel';

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  // GTM Data Layer push
  useEffect(() => {
    const viewedArticleEvent = {
      event: 'Viewed Article',
      articleName: page.hero.headline,
      contentCategory: page.category.name,
      primaryTribe: page.tribe?.name,
      signatureProg: page.program
        ? page.program.map(({ name }) => name).join(', ')
        : 'None',
    };

    if (page.secondaryTribe) {
      viewedArticleEvent.secondaryTribe = page.secondaryTribe
        .map(({ name }) => name)
        .join(', ');
    }

    dataLayer.push(viewedArticleEvent);

    if (author) {
      const viewedInfluencerContentEvent = {
        event: 'Viewed Influencer Content',
        influencerName: author.name,
        articleType: page.entryType,
        articleName: page.hero.headline,
        articleContentCategory: page.category.name,
        articlePrimaryTribe: page.tribe?.name,
        articleSignatureProg: viewedArticleEvent.signatureProg,
      };
      if (page.secondaryTribe) {
        viewedInfluencerContentEvent.secondaryTribe =
          viewedArticleEvent.secondaryTribe;
      }

      dataLayer.push(viewedInfluencerContentEvent);
    }
  }, [page, author]);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} jsonld={jsonld} />
      <CategoryWrapper aria-hidden>
        <CategoryWatermark category={category} />
      </CategoryWrapper>
      <Hero
        {...heroProps}
        path={path}
        influencer={author}
        isFunnelFirst={isFunnelFirst}
        pageId={pageContext.id}
      />
      <PageComponents modules={page.pageComponents} />
      {!!promotionalOverlayProps && (
        <PromotionalOverlay {...promotionalOverlayProps} />
      )}
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getContentPageData($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    contentfulContentPage(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      seo {
        ...Metadata
      }
      hero {
        ...Hero
      }
      author {
        ...InfluencerProfile
      }
      category {
        name
      }
      tribe {
        name
      }
      entryType
      secondaryTribe {
        name
      }
      program {
        name
      }
      pageComponents {
        ...ContentPageComponents
      }
    }
  }
`;

ContentPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    domain: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default ContentPage;
