import React from 'react';
import styled, { css } from 'styled-components';
import { buttonStyle } from '@atoms/Button';
import Link from './Link';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
  `}
`;

const PageComponent = (props) => (
  <Container>
    <StyledLink {...props} />
  </Container>
);

export default PageComponent;
