import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba } from '@utils';
import { HeroContext } from '@stores/Hero';

const Category = styled.div`
  ${({ theme }) => css`
    ${theme.typography.titleXXL}
    pointer-events: none;

    color: ${rgba(theme.colors.white, 0.1)};
  `}

  transition: opacity 0.5s cubic-bezier(0.58, 0, 0.56, 1);
  opacity: ${({ heroIsVisible }) => (heroIsVisible ? '0' : '1')};
`;

const CategoryWatermark = ({ category }) => {
  const { heroIsVisible } = useContext(HeroContext);

  return <Category heroIsVisible={heroIsVisible}>{category}</Category>;
};

CategoryWatermark.propTypes = {
  category: PropTypes.string,
};

export default CategoryWatermark;
