import React from 'react';
import PropTypes from 'prop-types';
import TextModule from '@atoms/TextModule';
import styled, { css } from 'styled-components';
import { grid } from '@styles/grid';

const Container = styled.div`
  ${grid}
`;
const child = css`
  grid-column: 2 / -1;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
`;
const Column = styled.div`
  ${({ theme }) => css`
    ${child};
    grid-column: 1 / -1;
    margin-bottom: ${theme.spacing(4)};

    ${theme.mediaquery.sm(css`
      grid-column-start: auto;
      grid-column-end: span 11;

      &:first-of-type {
        grid-column-start: 2;
      }
    `)}

    ${theme.mediaquery.md(css`
      margin-bottom: 0;
    `)}
  `}
`;

const StyledTextModule = styled(TextModule)`
  display: block;
  padding: 0;
`;

const TwoColumnsText = ({ left, leftAlignment, right, rightAlignment }) => {
  return (
    <Container>
      {[
        { id: 'left', modules: left, alignment: leftAlignment },
        { id: 'right', modules: right, alignment: rightAlignment },
      ].map(({ modules, alignment, id }) => (
        <Column key={id}>
          <StyledTextModule
            doc={modules}
            enableRenderer
            alignment={alignment}
          />
        </Column>
      ))}
    </Container>
  );
};

TwoColumnsText.propTypes = {
  left: PropTypes.array,
  right: PropTypes.array,
  leftAlignment: PropTypes.string,
  rightAlignment: PropTypes.string,
};

export default React.memo(TwoColumnsText);
