import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import Marquee from '@atoms/Marquee';
import { breakpoints, rgba } from '@utils';
import RetailersList from '@molecules/RetailersList';

const TopContainer = styled.div`
  position: relative;
  margin-bottom: ${({ theme }) => theme.spacing(8)};
`;

const TopContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ theme }) => css`
    background-image: linear-gradient(
      to bottom,
      ${rgba(theme.colors.black, 0)},
      ${theme.colors.black}
    );
  `}
`;

const ProductImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0) rotate(10deg);
  width: 75vw;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      width: 30vw;
    `)}
  `}
`;

const StyledMarquee = styled(Marquee)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  `}
`;

const StyledRetailersList = styled(RetailersList)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(5)};
  `}
`;

const EcommercePush = ({ background, product, title, retailers }) => (
  <section>
    {background && (
      <TopContainer>
        <Img
          type={IMG_TYPES.fluid}
          fits={IMG_FITS.cover}
          small={background.small || background.large}
          medium={background.large}
        />
        <TopContainerOverlay />
        {product && (
          <>
            <StyledMarquee
              lines={3}
              filled={product.flavour}
              outlined={`${product.category} ${product.protein}`}
            />
            <ProductImageContainer>
              <Img
                type={IMG_TYPES.fluid}
                fits={IMG_FITS.cover}
                small={{
                  ...product.image,
                  sizes: `(min-width: ${breakpoints.sm}px) 30vw ,75vw`,
                }}
              />
            </ProductImageContainer>
          </>
        )}
      </TopContainer>
    )}
    <Title>{title}</Title>
    <StyledRetailersList retailers={retailers} />
  </section>
);

EcommercePush.propTypes = {
  background: PropTypes.shape({
    small: PropTypes.object,
    large: PropTypes.object,
  }),
  product: PropTypes.shape({
    image: PropTypes.object,
    flavour: PropTypes.string,
    category: PropTypes.string,
    protein: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired,
    })
  ).isRequired,
};

export default EcommercePush;
