import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Label = styled.button`
  cursor: pointer;
  user-select: none;
  width: 100%;
  outline: 0;
  text-align: left;
`;

const Content = styled.div`
  max-height: ${({ collapsedHeight }) => `${collapsedHeight}px`};
  transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  ${({ open, maxHeight }) =>
    open &&
    css`
      max-height: ${maxHeight};
      transition: max-height 0.3s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    `};
`;

const Accordion = React.memo(
  ({
    className,
    initial = false,
    hiddenLabel,
    visibleLabel,
    children,
    onChange = (v) => v,
    labelOnTop = true,
    collapsedHeight = 0,
    maxHeight = '100rem',
  }) => {
    const [show, toggle] = useState(initial);

    useEffect(() => {
      toggle(initial);
    }, [initial]);

    return (
      <div className={className}>
        {!labelOnTop && (
          <Content
            collapsedHeight={collapsedHeight}
            open={!!show}
            maxHeight={maxHeight}
          >
            {children}
          </Content>
        )}
        <Label
          as="button"
          onClick={() => {
            toggle(!show);
            onChange(!show);
          }}
        >
          {show ? visibleLabel : hiddenLabel}
        </Label>
        {labelOnTop && (
          <Content
            collapsedHeight={collapsedHeight}
            open={!!show}
            maxHeight={maxHeight}
          >
            {children}
          </Content>
        )}
      </div>
    );
  }
);

Accordion.propTypes = {
  className: PropTypes.string,
  initial: PropTypes.bool,
  hiddenLabel: PropTypes.node.isRequired,
  visibleLabel: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  labelOnTop: PropTypes.bool,
  collapsedHeight: PropTypes.number,
  maxHeight: PropTypes.string,
};

export default Accordion;
