import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_TYPES, IMG_FITS } from '@atoms/Img';
import { rgba } from '@utils';
import Link, { LINK_TYPES } from '@atoms/Link';
import PlusOneButton from '@atoms/PlusOneButton';
import { useWindowSize } from '@hooks';
import { cardIcons } from './types';

const Container = styled(Link)`
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: var(--inner-gap);
  align-items: center;

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      display: block;
    `)}
`;

const ImgContainer = styled.div`
  position: relative;
  height: 0;
  padding-top: 100%;
  grid-column: span 1;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ theme }) => rgba(theme.colors.black, 0.4)};
  }
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
`;

const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
    z-index: 1;
  `}
`;

const TitleContainer = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(1)};
    `)}
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleXS};
    color: ${theme.colors.white};
  `}
`;

const Title = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
  `}
`;

const CardSquare = ({ background, type, title, subtitle, slug }) => {
  const Icon = cardIcons[type];
  const [viewportWidth] = useWindowSize();

  const [screenSize, setScreenSize] = useState('null');

  useEffect(() => {
    if (viewportWidth > 768) {
      setScreenSize('medium');
    } else {
      setScreenSize('small');
    }
  }, [viewportWidth]);

  return (
    <Container title={title} url={slug} type={LINK_TYPES.internal}>
      <ImgContainer>
        <StyledImg
          small={background}
          type={IMG_TYPES.fluid}
          fit={IMG_FITS.cover}
        />
        <IconContainer>
          <Icon />
        </IconContainer>
      </ImgContainer>
      <TitleContainer>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Title>{title}</Title>
        {screenSize === 'small' && (
          <PlusOneButton favoriteId={slug} position="left" slug={slug} />
        )}
      </TitleContainer>
      {screenSize === 'medium' && (
        <PlusOneButton favoriteId={slug} position="left" slug={slug} />
      )}
    </Container>
  );
};

CardSquare.propTypes = {
  background: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

export default React.memo(CardSquare);
