import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const REPEAT = 4; // times to repeat the sequence (think wide screens)

const Container = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1px; /* Accomodates for the text-stroke going over */
`;

const Line = styled.div`
  pointer-events: none;
  display: flex;

  ${({ index }) => {
    switch (index) {
      case 1:
        return css`
          transform: translateX(-15%);
        `;
      case 2:
        return css`
          transform: translateX(-5%);
        `;
      default:
        return css`
          transform: translateX(0);
        `;
    }
  }}
`;

const Text = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    color: ${theme.colors.white};
    pointer-events: none;
    white-space: nowrap;
  `}
`;

const OutlinedText = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 1px ${theme.colors.white};
    display: inline-block;
    transform: scale(0.982);
    transform-origin: center left;
    white-space: pre;

    &:before,
    &:after {
      content: ' ';
    }
  `}
`;

const Marquee = ({ lines, filled, outlined, className }) => {
  return (
    <Container className={className}>
      {Array.from(new Array(lines)).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Line index={index} key={`${index}_${filled}`}>
          {Array.from(new Array(REPEAT)).map((__, repeatKey) => (
            // eslint-disable-next-line react/no-array-index-key
            <Text key={`${index}_${repeatKey}_${filled}`}>
              {filled}
              <OutlinedText>{outlined}</OutlinedText>
            </Text>
          ))}
        </Line>
      ))}
    </Container>
  );
};

Marquee.propTypes = {
  lines: PropTypes.number.isRequired,
  filled: PropTypes.string.isRequired,
  outlined: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default React.memo(Marquee);
