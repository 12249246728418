import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { grid } from '@styles/grid';
import Link from '@atoms/Link';
import { buttonStyle, BUTTON_TYPES } from '@atoms/Button';
import InfluencerTagline, {
  adapter as influencerAdapter,
} from '@molecules/InfluencerTagline';
import ALIGNMENT from './alignment';

const Button = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    margin-top: ${theme.spacing(5)};
    display: inline-block;
  `}
`;

const ContentContainer = styled.div`
  ${({ theme }) => css`
    ${grid};
    padding-top: ${theme.spacing(20)};
    margin-bottom: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      padding-top: ${theme.spacing(32)};
      margin-bottom: ${theme.spacing(10)};
    `)}
  `}
`;

const Content = styled.div`
  ${({ theme, position, textAlign }) => css`
    grid-column: 1 / -1;

    ${theme.mediaquery.sm(css`
      grid-column: ${position === 'center' ? 7 : 2} / span 12;
      text-align: ${textAlign};
    `)}
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    color: ${theme.colors.white};
  `}
`;

const HeroBlurred = ({ headline, alignment, influencer, cta }) => {
  const { position, textAlign } = ALIGNMENT[alignment];

  return (
    <ContentContainer>
      <Content position={position} textAlign={textAlign}>
        {!!influencer && (
          <InfluencerTagline {...influencerAdapter(influencer)} />
        )}
        <Title>{headline}</Title>
        {cta && <Button {...cta} />}
      </Content>
    </ContentContainer>
  );
};

HeroBlurred.propTypes = {
  headline: PropTypes.string.isRequired,
  alignment: PropTypes.string.isRequired,
  influencer: PropTypes.object,
  cta: PropTypes.any,
};

export default HeroBlurred;
