import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from '@atoms/Img';
import { fullbleedgrid } from '@styles/grid';
import VideoPush from '@molecules/VideoPush';
import Link from '@atoms/Link';
import { buttonStyle, BUTTON_TYPES } from '@atoms/Button';
import InfluencerTagline, {
  adapter as influencerAdapter,
} from '@molecules/InfluencerTagline';
import Modal from '@molecules/Modal';
import PlusOneButton from '@atoms/PlusOneButton';

export const VIDEO_MODE = Object.freeze({
  bg: 'background video',
  cta: 'modal with cta',
});

const Wrapper = styled.section`
  ${({ theme, landscapeMobile }) => css`
    ${fullbleedgrid};
    margin-top: ${theme.spacing(15)};
    margin-bottom: ${theme.spacing(8)};

    ${() =>
      !landscapeMobile &&
      css`
        align-items: end;
      `}

    ${theme.mediaquery.sm(css`
      margin-top: 0;
      margin-bottom: ${theme.spacing(10)};
      align-items: end;
    `)}
  `}
`;

const ImageContainer = styled.div`
  ${({ theme, landscapeDesktop, landscapeMobile }) => css`
    grid-column: ${landscapeMobile ? '1 / -1' : '3 / -1'};
    grid-row: 1;
    position: relative;

    ${theme.mediaquery.sm(css`
      grid-column: ${landscapeDesktop ? 'span 16 / -1' : '11 / span 11'};
    `)}
  `}
`;

const BackgroundVideo = styled(VideoPush)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

const VideoCta = styled.button`
  ${({ theme }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    margin-right: ${theme.spacing(2)};
  `}
`;

const ContentContainer = styled.div`
  ${({ theme, landscapeMobile }) => css`
    z-index: 0;

    ${() =>
      landscapeMobile
        ? css`
            grid-column: 2 / -2;
            grid-row: 2;
            margin-top: -${theme.spacing(5)};
          `
        : css`
            grid-column: 2 / -3;
            grid-row: 1;
            margin-bottom: ${theme.spacing(5)};
          `}

    ${theme.mediaquery.sm(css`
      grid-column: 6 / span 11;
      grid-row: 1;
      margin-bottom: ${theme.spacing(12)};
      margin-top: 0;
    `)}
  `}
`;

const StyledTagline = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleXS};
    margin-bottom: ${theme.spacing(1)};

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleS};
      margin-bottom: ${theme.spacing(2)};
    `)}
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleXL};
    `)}
  `}
`;

const StyledInfluencerTagline = styled(InfluencerTagline)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(1)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(2)};
    `)}
  `}
`;

const Button = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    display: inline-block;
  `}
`;

const HeroClassic = ({
  headline,
  tagline,
  image,
  video,
  videoMode = VIDEO_MODE.bg,
  videoCtaLabel,
  influencer,
  imageMobileRatio,
  imageDesktopRatio,
  cta,
  path,
}) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const hasPlusOneButton = path !== 'home' && path !== '/' && path !== '';
  const landscapeDesktop = imageDesktopRatio >= 1;
  const landscapeMobile = imageMobileRatio >= 1;

  const sizesDesktop = landscapeDesktop ? '65vw' : '43vw';
  const sizesMobile = landscapeMobile ? '100vw' : '70vw';

  return (
    <Wrapper landscapeMobile={landscapeMobile}>
      <ImageContainer
        landscapeDesktop={landscapeDesktop}
        landscapeMobile={landscapeMobile}
      >
        <Img
          small={{ ...image.small, sizes: sizesMobile }}
          medium={{ ...image.large, sizes: sizesDesktop }}
        />
        {!!video && videoMode === VIDEO_MODE.bg && (
          <BackgroundVideo
            squareCorners
            {...video}
            disableFullscreen
            controls={false}
            threshold={0}
            autopause={false}
          />
        )}
      </ImageContainer>
      <ContentContainer landscapeMobile={landscapeMobile}>
        <Title>{headline}</Title>
        {!!tagline && <StyledTagline>{tagline}</StyledTagline>}
        {!!influencer && (
          <StyledInfluencerTagline {...influencerAdapter(influencer)} />
        )}

        <ButtonsContainer>
          {!!video && videoMode === VIDEO_MODE.cta && (
            <VideoCta
              onClick={() => {
                setVideoVisible(true);
              }}
            >
              {videoCtaLabel}
            </VideoCta>
          )}
          {cta && <Button {...cta} />}
        </ButtonsContainer>
        {hasPlusOneButton && (
          <PlusOneButton position="left" favoriteId={path} slug={path} />
        )}
      </ContentContainer>
      {!!video && videoMode === VIDEO_MODE.cta && (
        <Modal onClose={() => setVideoVisible(false)} isOpen={videoVisible}>
          <VideoPush squareCorners {...video} disableFullscreen forceSound />
        </Modal>
      )}
    </Wrapper>
  );
};

HeroClassic.propTypes = {
  tagline: PropTypes.string,
  headline: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  image: PropTypes.object,
  video: PropTypes.shape(VideoPush.propTypes),
  videoMode: PropTypes.string,
  videoCtaLabel: PropTypes.string,
  influencer: PropTypes.object,
  cta: PropTypes.any,
  imageMobileRatio: PropTypes.number,
  imageDesktopRatio: PropTypes.number,
};

export default HeroClassic;
