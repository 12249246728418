import * as THREE from 'three';

import BottomCircle from './BottomCircle';
import MuscleHuman from './MuscleHuman';

import {
  SCENE_STATES,
  MODEL_STATES,
  CIRCLE_STATES,
  BODY_TYPES,
} from './States';

class MuscleSelector {
  constructor(
    canvas,
    selectedBodyTypeCallback,
    selectedMuscleCallback,
    selectableMuscles
  ) {
    this.selectedMuscleCallback = selectedMuscleCallback;
    this.selectedBodyTypeCallback = selectedBodyTypeCallback;

    this.selectableMuscles = selectableMuscles;

    this.canvas = canvas;
    this.scene = new THREE.Scene();

    this.state = SCENE_STATES.DEFAULT;

    this.mouseDown = false;
    this.previousTouchX = 0;

    this.controlSpeed = 0.008;
    this.lastDrawCall = 0;

    this.setupRenderer();
    this.setupCamera();
    this.registerEventListeners();
    this.setup3DObjects();

    this.renderer.setAnimationLoop((time) => {
      this.update(time);
    });

    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
  }

  setup3DObjects() {
    this.bottomCircle = new BottomCircle();
    this.scene.add(this.bottomCircle);

    this.leftModel = new MuscleHuman(
      BODY_TYPES.FEMININE,
      this.selectableMuscles
    );
    this.scene.add(this.leftModel);

    this.rightModel = new MuscleHuman(
      BODY_TYPES.MASCULINE,
      this.selectableMuscles
    );
    this.scene.add(this.rightModel);

    THREE.DefaultLoadingManager.onLoad = () => {
      this.leftModel.isRotating = true;
      this.rightModel.isRotating = true;
    };
  }

  registerEventListeners() {
    window.addEventListener(
      'mousemove',
      (e) => {
        this.onMouseMove(e);
      },
      false
    );
    this.canvas.addEventListener(
      'touchmove',
      (e) => {
        const touch = e.touches[0];
        if (this.previousTouchX) {
          e.movementX = touch.pageX - this.previousTouchX;
        } else {
          e.movementX = 0;
        }
        this.previousTouchX = touch.pageX;

        this.onMouseMove(e);
      },
      false
    );
    this.canvas.addEventListener(
      'mousedown',
      (e) => {
        this.mouseDown = true;
        this.onClick(e);
      },
      false
    );
    this.canvas.addEventListener(
      'touchstart',
      (e) => {
        this.mouseDown = true;
        this.onClick(e);
      },
      false
    );
    window.addEventListener(
      'mouseup',
      () => {
        this.mouseDown = false;
      },
      false
    );
    window.addEventListener(
      'touchend',
      () => {
        this.mouseDown = false;
      },
      false
    );
  }

  setupRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
      canvas: this.canvas,
    });

    this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
    this.renderer.setPixelRatio(window.devicePixelRatio);
  }

  setState(state) {
    switch (state) {
      case SCENE_STATES.LEFT_ACTIVE:
      case SCENE_STATES.RIGHT_ACTIVE:
        this.controlsEnabled = true;
        break;

      case SCENE_STATES.MOVE_OUT:
        this.controlsEnabled = false;
        break;

      default:
        break;
    }

    this.state = state;
  }

  onMouseMove(event) {
    const rect = this.renderer.domElement.getBoundingClientRect();
    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

    if (
      this.mouseDown &&
      this.activeModel?.state === MODEL_STATES.ACTIVE &&
      this.controlsEnabled
    ) {
      this.activeModel.rotation.y += event.movementX * this.controlSpeed;
    }

    switch (this.state) {
      case SCENE_STATES.LEFT_ACTIVE:
      case SCENE_STATES.RIGHT_ACTIVE: {
        const intersect = this.checkMuscleIntersection();
        this.activeModel.setHoveredMuscle(intersect);
        break;
      }
      case SCENE_STATES.DEFAULT: {
        const intersect = this.checkLeftOrRightIntersection();

        if (intersect === this.leftModel) {
          this.leftModel.targetOpacity = 1;
          this.rightModel.targetOpacity = 0.2;
        } else if (intersect === this.rightModel) {
          this.rightModel.targetOpacity = 1;
          this.leftModel.targetOpacity = 0.2;
        } else {
          this.rightModel.targetOpacity = 1;
          this.leftModel.targetOpacity = 1;
        }
        break;
      }
      default:
        break;
    }
  }

  setupCamera() {
    const aspect = this.canvas.clientWidth / this.canvas.clientHeight;

    this.camera = new THREE.PerspectiveCamera(40, aspect, 1, 500);
    this.camera.position.z = 20;
    this.camera.position.y = 5;
  }

  goBack() {
    this.activeModel.setSelectedMuscle(false);

    if (this.activeModel === this.rightModel) {
      this.rightModel.setState(MODEL_STATES.MOVE_OUT);
      setTimeout(() => {
        this.leftModel.setState(MODEL_STATES.FADE_IN);
      }, 800);
      this.bottomCircle.setState(CIRCLE_STATES.FADE_OUT);
    } else {
      this.leftModel.setState(MODEL_STATES.MOVE_OUT);
      setTimeout(() => {
        this.rightModel.setState(MODEL_STATES.FADE_IN);
      }, 800);
      this.bottomCircle.setState(CIRCLE_STATES.FADE_OUT);
    }
    this.selectedBodyTypeCallback('');
    this.selectedMuscleCallback('');
    this.setState(SCENE_STATES.DEFAULT);
    this.activeModel = null;
  }

  goIntoFocusView(model) {
    this.activeModel = model;
    if (model === this.rightModel) {
      this.rightModel.setState(MODEL_STATES.MOVE_IN);
      this.leftModel.setState(MODEL_STATES.FADE_OUT);
      this.bottomCircle.setState(CIRCLE_STATES.FADE_IN);
      this.selectedBodyTypeCallback('masculine');

      this.setState(SCENE_STATES.RIGHT_ACTIVE);
    } else {
      this.leftModel.setState(MODEL_STATES.MOVE_IN);
      this.rightModel.setState(MODEL_STATES.FADE_OUT);
      this.bottomCircle.setState(CIRCLE_STATES.FADE_IN);
      this.selectedBodyTypeCallback('feminine');
      this.setState(SCENE_STATES.LEFT_ACTIVE);
    }
  }

  onClick() {
    switch (this.state) {
      case SCENE_STATES.DEFAULT: {
        const leftOrRight = this.checkLeftOrRightIntersection();
        if (leftOrRight) {
          this.goIntoFocusView(leftOrRight);
        }

        break;
      }

      case SCENE_STATES.LEFT_ACTIVE:
      case SCENE_STATES.RIGHT_ACTIVE: {
        const selectedMuscle = this.checkMuscleIntersection();
        if (selectedMuscle) {
          this.selectedMuscleCallback(selectedMuscle.name);
          this.activeModel.setSelectedMuscle(selectedMuscle);
        }
        break;
      }

      default:
        break;
    }
  }

  checkMuscleIntersection() {
    this.raycaster.setFromCamera(this.mouse, this.camera);

    let intersects;

    if (this.activeModel) {
      intersects = this.raycaster.intersectObjects(
        this.activeModel.wireframeMesh.children,
        true
      );
    }

    if (intersects.length > 0 && intersects[0].object.visible) {
      return intersects[0].object;
    }
    return false;
  }

  checkLeftOrRightIntersection() {
    if (this.leftModel.wireframeMesh && this.rightModel.wireframeMesh) {
      if (this.mouse.x > 0 && this.mouse.x < 1) {
        return this.leftModel;
      }
      if (this.mouse.x > -1 && this.mouse.x < 0) {
        return this.rightModel;
      }
      return false;
    }
    return false;
  }

  update(time) {
    const deltaTime = (time - this.lastDrawCall) / 100;
    this.lastDrawCall = time;

    this.renderer.render(this.scene, this.camera);

    this.leftModel.update(deltaTime);
    this.rightModel.update(deltaTime);
    this.bottomCircle.update(deltaTime);

    switch (this.state) {
      case SCENE_STATES.DEFAULT:
        break;
      default:
        break;
    }
  }
}

export default MuscleSelector;
