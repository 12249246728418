import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import {
  RelatedCardsAccordion,
  RelatedCardsCarousel,
} from '@organisms/RelatedCards';
import RelatedThumbnails from '@organisms/RelatedThumbnails/RelatedThumbnails';
import RelatedThumbnailsCarousel from '@organisms/RelatedThumbnails/RelatedThumbnailsCarousel';
import CalculatorProductPush from '@organisms/CalculatorProductPush/CalculatorProductPush';
import CalculatorProductPushCarousel from '@organisms/CalculatorProductPush/CalculatorProductPushCarousel';
import { buttonStyle } from '@atoms/Button';
import Dropdown from '@atoms/Dropdown';
import Tooltip from '@atoms/Tooltip';
import InputForm from '@atoms/Input';

import { grid } from '@styles/grid';

import { useWindowSize } from '@hooks';
import { breakpoints } from '@utils';
import { SettingsContext } from '@stores/Settings';

const Container = styled.section`
  ${grid}
`;

const Inner = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / -1;
    ${theme.mediaquery.md(css`
      grid-column: 6 / -6;
    `)}
  `}
`;
const InnerResults = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / -1;
    ${theme.mediaquery.md(css`
      grid-column: 4 / -4;
    `)}
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;

    ${theme.mediaquery.md(css`
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const SecondaryTitle = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;

    ${theme.mediaquery.md(css`
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
`;

const StyledSubtitleWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-flow: row nowrap;
`;

const SubTitle = styled.p`
  position: relative;
  ${({ theme }) => css`
    ${theme.typography.bodyM};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing(1)};
    text-align: center;

    ${theme.mediaquery.md(css`
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const FormContainer = styled.form`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    ${theme.mediaquery.md(css`
      padding: 0 calc(var(--col) * 2);
    `)}
  `}
`;

const StyledDropdown = styled(Dropdown)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(6)};
    width: 100%;
    justify-content: flex-start;
    min-width: ${theme.spacing(18)};

    > svg {
      right: ${theme.spacing(2)};
    }

    > select {
      width: 100%;
      padding: ${theme.spacing(1)} ${theme.spacing(3)};
      border: 1px solid rgba(102, 102, 102, 0.4);
      border-radius: ${theme.spacing(2)};

      :focus {
        outline: none;
        border: 1px solid ${theme.colors.white};
        border-radius: ${theme.spacing(2)};
      }
    }
  `}
`;

const UserInputsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(6)} 0 0;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      flex-direction: row;
      justify-content: space-between;
    `)}

    & > div {
      flex: 1;
    }

    input {
      width: 100%;
      text-align: left;
      padding: 0 ${theme.spacing(3)};
      font-size: 0.9rem;
    }
  `}
`;

const PhysicalInputsContainer = styled.div`
  ${({ theme, useBirthDate }) => css`
    display: flex;
    flex-direction: ${useBirthDate ? 'column' : 'row'};
    justify-content: ${useBirthDate ? 'flex-start' : 'space-between'};
    padding: ${theme.spacing(6)} 0 0;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      flex-direction: row;
      justify-content: space-between;
    `)}

    & > div {
      flex: 1;
    }

    input[type='date'] {
      width: 100%;
      text-align: left;
      padding: 0 ${theme.spacing(3)};
      font-size: 0.9rem;
    }
  `}
`;

const QuestionsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.spacing(6)};

    ${theme.mediaquery.md(css`
      margin: 0;
    `)}
  `}
`;

const StyledSubmitButton = styled.button`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin: ${theme.spacing(1)} auto;
    display: inline-block;
    text-align: center;
    ${theme.mediaquery.md(css`
      margin: ${theme.spacing(3)} auto;
    `)}
    transition:all 0.2s ease-in-out;

    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
  `}
`;

const ResultsTitleWrapper = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing(5)} auto;
    text-align: center;
    ${theme.mediaquery.md(css`
      margin: ${theme.spacing(10)} auto;
      padding: 0 calc(var(--col) * 2);
    `)}
  `}
`;

const Calculator = ({
  sendUserData,
  nameLabel,
  nameTooltip,
  emailLabel,
  emailTooltip,
  phoneLabel,
  phoneTooltip,
  ageLabel,
  ageUnitLabel,
  ageTooltip,
  useBirthDate,
  birthDateLabel,
  birthDateTooltip,
  bodyweightLabel,
  bodyweightUnitLabel,
  calculateButtonLabel,
  durationOfWorkoutLabel,
  genderLabel,
  genderList,
  genderTooltip,
  nextButtonLabel,
  numberOfWorkoutsLabel,
  recalculateButtonLabel,
  subtitle,
  title,
  titleTooltip,
  secondaryTitle,
  trainingObjectivesLabel,
  trainingObjectivesTooltip,
  typeOfDietLabel,
  typeOfDietTooltip,
  typeOfWorkoutLabel,
  typeOfWorkoutTooltip,
  numberOfWorkoutsDropdown,
  durationOfWorkoutDropdown,
  typeOfWorkoutDropdown,
  trainingObjectivesDropdown,
  typeOfDietDropdown,
  resultsTitleDailyAmount,
  occasionSplitTitle,
  occasionSplitTooltip,
  occasionSplitTooltip2,
  productPushTitle,
  recipesTitle,
  explanationOfResults,
  explanationTooltip,
  productPushCta,
  resultsTexts,
}) => {
  const { emailDisplayName, receivingEmail, emailConsent } = useContext(
    SettingsContext
  );
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  const windowWidth = useWindowSize()[0];
  const onMobile = useMemo(() => windowWidth < breakpoints.sm, [windowWidth]);

  const BUTTONSTATES = Object.freeze({
    initial: nextButtonLabel,
    ready: calculateButtonLabel,
    done: recalculateButtonLabel,
  });

  function getGramage({ nbWorkouts, type, duration }) {
    if (nbWorkouts >= 4 && type === 1) return [1.4, 1.6];
    if (nbWorkouts === 7 && duration >= 1) return [1.1, 1.6];
    if (nbWorkouts >= 4 && duration === 2) return [1.1];
    if (nbWorkouts >= 4 && duration === 1) return [0.83, 1.1];
    if (duration === 2) return [0.83, 1.1];
    return [0.83];
  }

  function average(nums) {
    return nums.reduce((a, b) => a + b) / nums.length;
  }

  const numberOfWorkoutsAnswers = useMemo(
    () => [
      {
        level: 2,
        label: numberOfWorkoutsDropdown.betweenOneAndTwoLabel,
      },
      { level: 3, label: numberOfWorkoutsDropdown.betweenTwoAndThree },
      { level: 3, label: numberOfWorkoutsDropdown.lessThanFourLabel },
      {
        level: 6,
        label: numberOfWorkoutsDropdown.betweenFourAndSixLabel,
      },
      { level: 7, label: numberOfWorkoutsDropdown.everydayLabel },
    ],
    [
      numberOfWorkoutsDropdown.betweenFourAndSixLabel,
      numberOfWorkoutsDropdown.betweenOneAndTwoLabel,
      numberOfWorkoutsDropdown.betweenTwoAndThree,
      numberOfWorkoutsDropdown.everydayLabel,
      numberOfWorkoutsDropdown.lessThanFourLabel,
    ]
  );
  const durationOfWorkoutAnswers = useMemo(
    () => [
      {
        level: 0,
        label: durationOfWorkoutDropdown.lessThanOneHourLabel,
      },
      { level: 1, label: durationOfWorkoutDropdown.aboutOneHourLabel },
      {
        level: 2,
        label: durationOfWorkoutDropdown.moreThanOneHourLabel,
      },
    ],
    [
      durationOfWorkoutDropdown.aboutOneHourLabel,
      durationOfWorkoutDropdown.lessThanOneHourLabel,
      durationOfWorkoutDropdown.moreThanOneHourLabel,
    ]
  );
  const typeOfWorkoutAnswers = useMemo(
    () => [
      { level: 0, label: typeOfWorkoutDropdown.enduranceLabel },
      { level: 1, label: typeOfWorkoutDropdown.strenghtLabel },
      {
        level: 1,
        label: `${typeOfWorkoutDropdown.enduranceLabel} & ${typeOfWorkoutDropdown.strenghtLabel}`,
      },
    ],
    [typeOfWorkoutDropdown.enduranceLabel, typeOfWorkoutDropdown.strenghtLabel]
  );
  const trainingObjectivesAnswers = useMemo(
    () => [
      { label: trainingObjectivesDropdown.muscleDefinitionLabel },
      { label: trainingObjectivesDropdown.gainMuscleMassLabel },
      { label: trainingObjectivesDropdown.otherLabel },
    ],
    [
      trainingObjectivesDropdown.gainMuscleMassLabel,
      trainingObjectivesDropdown.muscleDefinitionLabel,
      trainingObjectivesDropdown.otherLabel,
    ]
  );
  const typeOfDietAnswers = useMemo(
    () => [
      { label: typeOfDietDropdown.noSignificantDietaryRestrictionsLabel },
      { label: typeOfDietDropdown.vegetarianLabel },
      { label: typeOfDietDropdown.lactoseIntolerantLabel },
    ],
    [
      typeOfDietDropdown.lactoseIntolerantLabel,
      typeOfDietDropdown.noSignificantDietaryRestrictionsLabel,
      typeOfDietDropdown.vegetarianLabel,
    ]
  );

  const questionsArray = useMemo(
    () => [
      {
        answers: numberOfWorkoutsAnswers,
        placeholder: 'Choose an option below',
        label: numberOfWorkoutsLabel,
        tooltip: '',
        id: 'nbWorkouts',
      },

      {
        answers: durationOfWorkoutAnswers,
        placeholder: 'Choose an option below',
        label: durationOfWorkoutLabel,
        tooltip: '',
        id: 'durationWorkout',
      },

      {
        answers: typeOfWorkoutAnswers,
        placeholder: 'Choose an option below',
        label: typeOfWorkoutLabel,
        tooltip: typeOfWorkoutTooltip.typeOfWorkoutTooltip,
        id: 'typeOfWorkout',
      },

      {
        answers: trainingObjectivesAnswers,
        placeholder: 'Choose an option below',
        label: trainingObjectivesLabel,
        tooltip: trainingObjectivesTooltip,
        id: 'trainingObjectives',
      },
      {
        answers: typeOfDietAnswers,
        placeholder: 'Choose an option below',
        label: typeOfDietLabel,
        tooltip: typeOfDietTooltip,
        id: 'typeOfDiet',
      },
    ],
    [
      durationOfWorkoutAnswers,
      durationOfWorkoutLabel,
      numberOfWorkoutsAnswers,
      numberOfWorkoutsLabel,
      trainingObjectivesAnswers,
      trainingObjectivesLabel,
      trainingObjectivesTooltip,
      typeOfDietAnswers,
      typeOfDietLabel,
      typeOfDietTooltip,
      typeOfWorkoutAnswers,
      typeOfWorkoutLabel,
      typeOfWorkoutTooltip.typeOfWorkoutTooltip,
    ]
  );

  const [submitButtonText, setSubmitButtonText] = useState(
    BUTTONSTATES.initial
  );

  // A new object is returned with questionsArray.id as key.
  const initialValues = questionsArray.reduce(
    (acc, currentValue) => ({
      [currentValue.id]: currentValue.answers[0],
      ...acc,
    }),
    {}
  );

  const [formData, setFormData] = useState(initialValues);

  const [resultToShow, setResultToShow] = useState({});

  const [countOfRenderedQuestions, setCountOfRenderedQuestions] = useState(0);
  const [grammage, setGrammage] = useState([0.83]);

  const nameSelectorRef = useRef(null);
  const [nameValue, setNameValue] = useState('');
  const emailSelectorRef = useRef(null);
  const [emailValue, setEmailValue] = useState('');
  const phoneSelectorRef = useRef(null);
  const [phoneValue, setPhoneValue] = useState('');

  const genderSelectorRef = useRef(null);
  const [genderValue, setGenderValue] = useState(genderList[1]);
  const ageSelectorRef = useRef(null);
  const [ageValue, setAgeValue] = useState(27);
  const birthDateSelectorRef = useRef(null);
  const [birthDateValue, setBirthDateValue] = useState(Date.now());

  const weightSelectorRef = useRef(null);
  const [weightValue, setWeightValue] = useState(90);

  const handleValue = useCallback(
    (e) => {
      switch (e.target) {
        case nameSelectorRef.current:
          setNameValue(String(e.target.value));
          break;
        case emailSelectorRef.current:
          setEmailValue(String(e.target.value));
          break;
        case phoneSelectorRef.current:
          setPhoneValue(String(e.target.value));
          break;
        case ageSelectorRef.current:
          setAgeValue(Number(e.target.value));
          break;
        case birthDateSelectorRef.current:
          setBirthDateValue(e.target.value);
          break;
        case weightSelectorRef.current:
          setWeightValue(Number(e.target.value));
          break;
        case genderSelectorRef.current:
          setGenderValue(e.target.value);
          break;

        default:
          setFormData({
            ...formData,
            [e.target.name]: {
              level: questionsArray
                .filter((i) => i.id === e.target.name)[0]
                .answers.filter((item) => item.label === e.target.value)[0]
                .level,
              label: e.target.value,
            },
          });
          break;
      }
    },
    [formData, questionsArray]
  );

  const sendEmail = useCallback(async () => {
    const birthDate = birthDateValue ? new Date(birthDateValue) : '';
    const birthDateStr = birthDateValue
      ? `${birthDate.getDate()}/${
          birthDate.getMonth() + 1
        }/${birthDate.getFullYear()}`
      : '';
    const message = `A user has used the calculator`;
    const emailMarkup = `<html lang="en">
      <span>${message}</span>
      <br /><br />
      <span>${nameLabel}: ${nameValue}</span>
      <br />
      <span>${emailLabel}: ${emailValue}</span>
      <br />
      <span>${phoneLabel}: ${phoneValue}</span>
      <br />
      <span>${useBirthDate ? birthDateLabel : ageLabel}: ${
      useBirthDate ? birthDateStr : ageValue
    }</span>
      <br />
      <span>${
        bodyweightLabel.split(' *')[0]
      }: ${weightValue} ${bodyweightUnitLabel}</span>
    </html>`;

    const options = {
      from: `${emailDisplayName} <onboarding@resend.dev>`,
      to: [`${receivingEmail}`],
      subject: 'Calculator form submission',
      html: emailMarkup,
    };

    const response = await fetch('/.netlify/functions/sendEmail/sendEmail', {
      method: 'POST',
      body: JSON.stringify(options),
    });

    if (response.ok) {
      setIsEmailSent(true);
      // console.log('Email sent: ', response);
    } else {
      console.error('Error sending email');
    }
  }, [
    nameLabel,
    nameValue,
    emailLabel,
    emailValue,
    phoneLabel,
    phoneValue,
    ageLabel,
    ageValue,
    useBirthDate,
    birthDateLabel,
    birthDateValue,
    bodyweightLabel,
    bodyweightUnitLabel,
    weightValue,
    emailDisplayName,
    receivingEmail,
  ]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (!sendUserData || (sendUserData && isConsentGiven)) {
        const submittedValues = {
          event: 'Calculate Nutrition Calculator',
          action: 'submit',
          name: nameValue,
          email: emailValue,
          phone: phoneValue,
          age: ageValue,
          birthDate: birthDateValue,
          bodyweight: weightValue,
          gender: genderValue,
          nbOfWorkouts: formData.nbWorkouts.label,
          durationOfWorkouts: formData.durationWorkout.label,
          typeOfWorkout: formData.typeOfWorkout.label,
          typeOfDiet: formData.typeOfDiet.label,
          trainingObjectives: formData.trainingObjectives.label,
        };

        if (countOfRenderedQuestions === questionsArray.length) {
          dataLayer.push(submittedValues);
        } else if (countOfRenderedQuestions > questionsArray.length) {
          dataLayer.push({
            ...submittedValues,
            event: 'Recalculate Nutrition Calculator',
          });
        }
      }

      if (countOfRenderedQuestions === questionsArray.length) {
        setCountOfRenderedQuestions(questionsArray.length + 1);
        setSubmitButtonText(BUTTONSTATES.done);
        if (sendUserData && isConsentGiven && !isEmailSent) {
          sendEmail();
        }
      } else if (countOfRenderedQuestions === questionsArray.length - 1) {
        setCountOfRenderedQuestions((x) => x + 1);
        setSubmitButtonText(BUTTONSTATES.ready);
      } else {
        setCountOfRenderedQuestions((x) => x + 1);
      }
    },
    [
      nameValue,
      emailValue,
      phoneValue,
      ageValue,
      birthDateValue,
      weightValue,
      genderValue,
      formData.nbWorkouts.label,
      formData.durationWorkout,
      formData.typeOfWorkout,
      formData.typeOfDiet.label,
      formData.trainingObjectives.label,
      countOfRenderedQuestions,
      questionsArray.length,
      BUTTONSTATES.done,
      BUTTONSTATES.ready,
      sendUserData,
      isConsentGiven,
      isEmailSent,
      sendEmail,
    ]
  );

  const selectResult = useCallback(
    (goalProteinIntake) => {
      // Find the result that fits the goal protein intake
      const resultToReturn = resultsTexts.find(
        (result) =>
          result.proteinMinValue < goalProteinIntake &&
          goalProteinIntake < result.proteinMaxValue
      );
      if (resultToReturn) {
        return resultToReturn;
      }
      // If no result is found, return the closest one
      const closest = resultsTexts.reduce(function findClosestMeanMatch(
        prev,
        curr
      ) {
        return Math.abs(
          average([curr.proteinMinValue, curr.proteinMinValue]) -
            goalProteinIntake
        ) <
          Math.abs(
            average([prev.proteinMinValue, prev.proteinMinValue]) -
              goalProteinIntake
          )
          ? curr
          : prev;
      });
      if (closest) {
        return closest;
      }
      // If a result is still not found, fallback to the first one
      return resultsTexts[0];
    },
    [resultsTexts]
  );

  // Calculate the goal protein intake, when all questions are answered
  useEffect(() => {
    if (
      countOfRenderedQuestions > questionsArray.length &&
      grammage &&
      weightValue
    ) {
      const goalProteinIntake = average(grammage) * weightValue;
      setResultToShow(selectResult(goalProteinIntake));
    }
  }, [
    countOfRenderedQuestions,
    grammage,
    questionsArray.length,
    resultsTexts,
    selectResult,
    weightValue,
  ]);

  useEffect(() => {
    // Calculate the grammage when the form data changes
    setGrammage(
      getGramage({
        nbWorkouts: formData.nbWorkouts.level,
        type: formData.typeOfWorkout.level,
        duration: formData.durationWorkout.level,
      })
    );
  }, [
    formData.nbWorkouts.level,
    formData.typeOfWorkout,
    formData.durationWorkout,
  ]);

  const prettyGrammage = grammage[1]
    ? `${Math.round(grammage[0] * weightValue)} - ${Math.round(
        grammage[1] * weightValue
      )}`
    : Math.round(grammage[0] * weightValue);

  return (
    <Container>
      <Inner>
        <StyledTitleWrapper>
          {!!title && <Title>{title}</Title>}
          <Tooltip tooltip={titleTooltip} left />
        </StyledTitleWrapper>
        {!!secondaryTitle && <SecondaryTitle>{secondaryTitle}</SecondaryTitle>}
        {!!subtitle && <SubTitle>{subtitle}</SubTitle>}
        <FormContainer onSubmit={handleSubmit}>
          {sendUserData && (
            <UserInputsContainer>
              <InputForm
                type="text"
                value={nameValue}
                label={nameLabel}
                tooltip={nameTooltip}
                onChange={handleValue}
                ref={nameSelectorRef}
                name="Name"
                width="100%"
                required
              />
              <InputForm
                type="email"
                value={emailValue}
                label={emailLabel}
                tooltip={emailTooltip}
                onChange={handleValue}
                ref={emailSelectorRef}
                name="Email"
                width="100%"
                required
              />
              <InputForm
                type="tel"
                value={phoneValue}
                label={phoneLabel}
                tooltip={phoneTooltip}
                onChange={handleValue}
                ref={phoneSelectorRef}
                name="Phone"
                width="100%"
                required
              />
            </UserInputsContainer>
          )}
          <PhysicalInputsContainer useBirthDate={useBirthDate}>
            {useBirthDate ? (
              <InputForm
                type="date"
                value={birthDateValue}
                label={birthDateLabel}
                tooltip={birthDateTooltip}
                onChange={handleValue}
                ref={birthDateSelectorRef}
                name="BirthDate"
                required
              />
            ) : (
              <InputForm
                type="number"
                value={ageValue}
                label={ageLabel}
                tooltip={ageTooltip}
                unitsLabel={ageUnitLabel}
                onChange={handleValue}
                ref={ageSelectorRef}
                name="Age"
                required
                max={99}
                min={12}
              />
            )}

            <StyledDropdown
              ref={genderSelectorRef}
              value={genderValue}
              onChange={handleValue}
              visible
              label={genderLabel}
              name="gender"
              tooltip={genderTooltip}
              tooltipLeft
            >
              {genderList.map((gender) => {
                return (
                  <option key={gender} value={gender}>
                    {gender}
                  </option>
                );
              })}
            </StyledDropdown>

            <InputForm
              type="number"
              value={weightValue || null}
              label={bodyweightLabel}
              unitsLabel={bodyweightUnitLabel}
              onChange={handleValue}
              ref={weightSelectorRef}
              required
              name="bodyweight"
              min={1}
            />
          </PhysicalInputsContainer>

          <QuestionsContainer>
            {questionsArray.map((question, index) => {
              return (
                <StyledDropdown
                  onChange={handleValue}
                  visible={index <= countOfRenderedQuestions - 1}
                  label={question.label}
                  value={formData[question.id].label}
                  name={question.id}
                  tooltip={question.tooltip}
                  key={formData[question.id].label}
                >
                  {question.answers.map((item) => (
                    <option key={item.label} value={item.label}>
                      {item.label}
                    </option>
                  ))}
                </StyledDropdown>
              );
            })}
          </QuestionsContainer>

          {sendUserData && (
            <InputForm
              id="calculator_consent"
              type="checkbox"
              value={isConsentGiven}
              label={emailConsent}
              onChange={() => setIsConsentGiven(!isConsentGiven)}
            />
          )}
          <StyledSubmitButton
            type="submit"
            disabled={
              sendUserData &&
              submitButtonText === BUTTONSTATES.ready &&
              !isConsentGiven
            }
          >
            {submitButtonText}
          </StyledSubmitButton>
        </FormContainer>
      </Inner>
      {Object.keys(resultToShow).length !== 0 && (
        <InnerResults>
          <ResultsTitleWrapper>
            <Title>
              {prettyGrammage}
              {resultsTitleDailyAmount}
            </Title>
            <StyledSubtitleWrapper>
              <SubTitle>{explanationOfResults}</SubTitle>
              <Tooltip tooltip={explanationTooltip} left />
            </StyledSubtitleWrapper>
          </ResultsTitleWrapper>

          {resultToShow.proteinSplit &&
            (onMobile ? (
              <RelatedThumbnailsCarousel cards={resultToShow.proteinSplit} />
            ) : (
              <RelatedThumbnails
                cards={resultToShow.proteinSplit}
                isCalculator
              />
            ))}

          {resultToShow.occasionSplit && (
            <>
              <div style={{ display: 'flex', position: 'relative' }}>
                {occasionSplitTitle && <Title>{occasionSplitTitle}</Title>}
                {occasionSplitTooltip && (
                  <Tooltip tooltip={occasionSplitTooltip} left />
                )}
              </div>
              {onMobile ? (
                <RelatedThumbnailsCarousel
                  cards={resultToShow.occasionSplit}
                  tooltip={occasionSplitTooltip2}
                />
              ) : (
                <RelatedThumbnails
                  cards={resultToShow.occasionSplit}
                  isCalculator
                  gridOverride
                  tooltip={occasionSplitTooltip2}
                />
              )}
            </>
          )}

          {resultToShow.productFlavours &&
            (onMobile ? (
              <CalculatorProductPushCarousel
                title={productPushTitle}
                cards={resultToShow.productFlavours}
                CTALabel={productPushCta}
                isCalculator
              />
            ) : (
              <CalculatorProductPush
                title={productPushTitle}
                cards={resultToShow.productFlavours}
                CTALabel={productPushCta}
                isCalculator
              />
            ))}

          {!!recipesTitle && resultToShow.calculatorResultsCards && (
            <Title>{recipesTitle}</Title>
          )}
          {resultToShow.calculatorResultsCards &&
            (onMobile ? (
              <RelatedCardsCarousel
                cards={resultToShow.calculatorResultsCards}
              />
            ) : (
              <RelatedCardsAccordion
                cards={resultToShow.calculatorResultsCards}
                cardsShown={4}
                isCalculator
              />
            ))}
        </InnerResults>
      )}
    </Container>
  );
};

Calculator.propTypes = {
  sendUserData: PropTypes.bool.isRequired,
  nameLabel: PropTypes.string.isRequired,
  nameTooltip: PropTypes.string.isRequired,
  emailLabel: PropTypes.string.isRequired,
  emailTooltip: PropTypes.string.isRequired,
  phoneLabel: PropTypes.string.isRequired,
  phoneTooltip: PropTypes.string.isRequired,
  ageLabel: PropTypes.string,
  ageUnitLabel: PropTypes.string,
  ageTooltip: PropTypes.string,
  useBirthDate: PropTypes.bool,
  birthDateLabel: PropTypes.string.isRequired,
  birthDateTooltip: PropTypes.string.isRequired,
  bodyweightLabel: PropTypes.string,
  bodyweightUnitLabel: PropTypes.string,
  calculateButtonLabel: PropTypes.string,
  durationOfWorkoutLabel: PropTypes.string,
  genderLabel: PropTypes.string,
  genderList: PropTypes.array,
  genderTooltip: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  numberOfWorkoutsLabel: PropTypes.string,
  recalculateButtonLabel: PropTypes.string,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  titleTooltip: PropTypes.string,
  secondaryTitle: PropTypes.string.isRequired,
  trainingObjectivesLabel: PropTypes.string,
  trainingObjectivesTooltip: PropTypes.string,
  typeOfDietLabel: PropTypes.string,
  typeOfDietTooltip: PropTypes.string,
  typeOfWorkoutLabel: PropTypes.string,
  typeOfWorkoutTooltip: PropTypes.object,
  resultsTitleDailyAmount: PropTypes.string,
  occasionSplitTitle: PropTypes.string,
  occasionSplitTooltip: PropTypes.string,
  occasionSplitTooltip2: PropTypes.string,
  productPushTitle: PropTypes.string,
  recipesTitle: PropTypes.string,
  explanationOfResults: PropTypes.string,
  explanationTooltip: PropTypes.string,
  productPushCta: PropTypes.string,
  numberOfWorkoutsDropdown: PropTypes.shape({
    betweenFourAndSixLabel: PropTypes.string.isRequired,
    betweenOneAndTwoLabel: PropTypes.string.isRequired,
    betweenTwoAndThree: PropTypes.string.isRequired,
    lessThanFourLabel: PropTypes.string,
    everydayLabel: PropTypes.string.isRequired,
  }),
  durationOfWorkoutDropdown: PropTypes.shape({
    aboutOneHourLabel: PropTypes.string.isRequired,
    lessThanOneHourLabel: PropTypes.string.isRequired,
    moreThanOneHourLabel: PropTypes.string.isRequired,
  }),
  trainingObjectivesDropdown: PropTypes.shape({
    muscleDefinitionLabel: PropTypes.string.isRequired,
    gainMuscleMassLabel: PropTypes.string.isRequired,
    otherLabel: PropTypes.string.isRequired,
  }),
  typeOfWorkoutDropdown: PropTypes.shape({
    enduranceLabel: PropTypes.string.isRequired,
    strenghtLabel: PropTypes.string.isRequired,
  }),
  typeOfDietDropdown: PropTypes.shape({
    vegetarianLabel: PropTypes.string.isRequired,
    noSignificantDietaryRestrictionsLabel: PropTypes.string.isRequired,
    lactoseIntolerantLabel: PropTypes.string.isRequired,
  }),
  resultsTexts: PropTypes.array,
};

export default React.memo(Calculator);
