export const colors = {
  white: '#ffffff',
  black: '#000000',
  hoverGray: '#CCCCCC',
  pressedGray: '#999999',
  legalGray: '#272727',
  plantBased: '#948A7C',
  orangeCaramel: '#ED6D3E',
  richCoffee: '#C79775',
  chocolade: '#E8BB75',
  strawberry: '#CD2B1E',
  mango: '#EE9441',
  citrus: '#58953C',
  lightVanilla: '#FFE49E',
  blueberry: '#209BFF',
  vibrantStrawberry: '#E12D22',
  flavourChocolate: '#D8A65B',
  textShadowWhite:
    '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
  textShadowBlack:
    '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000',
};

export const trimHex = (color) => color.replace('#', '');

const toRGB = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 255,
        g: 255,
        b: 255,
      };
};

export const rgba = (hex, alpha) => {
  const c = toRGB(hex);
  return `rgba(${c.r}, ${c.g}, ${c.b}, ${alpha})`;
};
