import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ChevronDown from '@icons/chevron-down.svg';
import Tooltip from '../Tooltip/Tooltip';

const Container = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  ${({ visible }) => css`
    display: ${visible ? `flex` : `none`};
  `}
`;

const Outer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledLabel = styled.label`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    display: inline-flex;
    color: ${theme.colors.white};
    position: relative;
    margin-bottom: ${theme.spacing(2)};
  `}
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  z-index: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

const Select = styled.select`
  ${({ theme }) => css`
    ${theme.typography.bodyM};
    position: relative;
    left: 0;
    z-index: 1;
    padding-right: ${theme.spacing(3)};
    appearance: none;
    border: 0 none;
    background: transparent;
    color: ${theme.colors.white};
    > option {
      color: ${theme.colors.black};
    }
  `}
`;

const Dropdown = React.memo(
  React.forwardRef(
    (
      { tooltip, tooltipLeft, visible, className, label, children, ...rest },
      ref
    ) => {
      return (
        <Container visible={visible}>
          <div style={{ display: 'flex', position: 'relative' }}>
            {label && <StyledLabel>{label} </StyledLabel>}
            {tooltip && <Tooltip tooltip={tooltip} left={tooltipLeft} />}
          </div>
          <Outer className={className}>
            <Select ref={ref} {...rest}>
              {children}
            </Select>
            <StyledChevronDown />
          </Outer>
        </Container>
      );
    }
  )
);

Dropdown.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  visible: PropTypes.bool,
  tooltipLeft: PropTypes.bool,
};

export default Dropdown;
