import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { grid } from '@styles/grid';
import Link, { LINK_TYPES } from '@atoms/Link';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import { colors } from '@styles/theme';

const isExternalUrl = (url) => {
  return (
    url.startsWith('http://') ||
    url.startsWith('https://') ||
    url.startsWith('mailto')
  );
};

const isAnchor = (url) => {
  return url.startsWith('#');
};

const TextWrapper = styled.div`
  ${grid};
  text-align: ${({ align }) => align || null};

  p {
    margin-bottom: ${({ theme }) => theme.spacing(2)};
  }
`;

const Bold = styled.span`
  color: ${({ bodyColor }) => bodyColor};
  font-weight: bold;

  /* Superscript */
  .italics {
    font-weight: normal;
    font-style: normal;
    font-size: 0.7rem;
    vertical-align: super;
  }
`;

const Italics = styled.span`
  ${({ theme }) => css`
    font-style: italic;
    color: ${({ bodyColor }) => bodyColor};

    /* Legal Mention */
    .underline {
      font-style: normal;
      text-decoration: none;
      ${theme.typography.footnote};
      p,
      a {
        font-size: inherit;
        line-height: inherit;
      }
    }

    /* Superscript */
    .bold {
      font-weight: normal;
      font-style: normal;
      font-size: 0.7rem;
      vertical-align: super;
    }
  `}
`;

const Underline = styled.span`
  ${({ theme }) => css`
    color: ${({ bodyColor }) => bodyColor};
    text-decoration: underline;

    /* Legal Mention */
    .italics {
      text-decoration: none;
      font-style: normal;
      ${theme.typography.footnote};
      p,
      a {
        font-size: inherit;
        line-height: inherit;
      }
    }
  `}
`;

const StyledBody = styled.p`
  ${({ theme }) => theme.typography.bodyM}
  color: ${({ bodyColor }) => bodyColor};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => css`
    grid-column: 1 / -1;
    ${theme.mediaquery.md(css`
      grid-column: 8 / -8;
    `)}
  `}
`;

const StyledBodyIntro = styled.p`
  ${({ theme }) => css`
    grid-column: 1 / -1;
    ${theme.mediaquery.md(css`
      grid-column: 8 / -8;
    `)}
  `}

  > p {
    ${({ theme }) => theme.typography.bodyL};
    display: block;
  }
`;

const coloredBorder = css`
  ${({ theme }) => css`
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: calc(var(--outer-gap) * -1);
      width: 4px;
      height: 100%;
      /* To show the correct product color as the indicator product, you first need to make sure that any
      parent of this component defines the --product-color variable. */
      background: var(--product-color, ${theme.colors.white});
      ${theme.mediaquery.md(css`
        left: calc((var(--outer-gap) + var(--col) * 7) * -1);
      `)}
    }
  `}
`;

const Heading2 = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${({ bodyColor }) => bodyColor};
    position: relative;
    margin-bottom: ${theme.spacing(4)};

    grid-column: 1 / -1;
    ${coloredBorder};

    ${theme.mediaquery.md(css`
      grid-column: 8 / -8;
    `)}

    ${Bold} {
      font-weight: inherit;
    }
  `}
`;

const Heading3 = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${({ bodyColor }) => bodyColor};
    position: relative;
    margin-bottom: ${theme.spacing(2)};

    grid-column: 1 / -1;
    ${coloredBorder};

    ${theme.mediaquery.md(css`
      grid-column: 8 / -8;
    `)}

    ${Bold} {
      font-weight: inherit;
    }
  `}
`;

const StyledUl = styled.ul`
  list-style: disc;
  padding-left: ${({ theme }) => theme.spacing(2.5)};
  color: ${({ bodyColor }) => bodyColor};
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  /* tweak to make sure the list keeps the correct style when it's right or center aligned */
  display: inline-block;

  ${({ theme }) => css`
    grid-column: 1 / -1;
    ${theme.mediaquery.md(css`
      grid-column: 8 / -8;
    `)}
  `}
  p {
    margin: 0;
  }
`;

const LinkOuter = styled.span`
  color: ${({ bodyColor }) => bodyColor};
  a {
    text-decoration: underline;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;

    :hover {
      opacity: 0.5;
    }
  }
`;

// https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/
const TextModule = React.memo(
  ({
    doc,
    enableRenderer = false,
    renderNode = undefined,
    renderMark = undefined,
    renderText = undefined,
    bodyColor = colors.white,
    alignment,
    ...rest
  }) => {
    // important note, the editor has to choose "italic" in the rich text
    // to generate a legal mention component. There's no italic style as such in the rich text.
    //
    // The blockquote option refer to Intro Text component in the rich text editor.
    const docOptions = {
      renderMark: renderMark || {
        [MARKS.BOLD]: (text) => <Bold className="bold">{text}</Bold>,
        [MARKS.UNDERLINE]: (text) => (
          <Underline className="underline">{text}</Underline>
        ),
        [MARKS.ITALIC]: (text) => (
          <Italics bodyColor={bodyColor} className="italics">
            {text}
          </Italics>
        ),
      },
      renderNode: renderNode || {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <StyledBody bodyColor={bodyColor}>{children}</StyledBody>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading2 bodyColor={bodyColor}>{children}</Heading2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Heading3 bodyColor={bodyColor}>{children}</Heading3>
        ),
        /* Hyperlink hack */
        [BLOCKS.HEADING_6]: (node) => {
          return <div id={node.content[0].value} />;
        },
        [BLOCKS.UL_LIST]: (node, children) => (
          <StyledUl bodyColor={bodyColor}>{children}</StyledUl>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
          const url = node.data.uri;
          if (isAnchor(url)) {
            return <AnchorLink to={url}>{children}</AnchorLink>;
          }
          return (
            <LinkOuter bodyColor={bodyColor}>
              <Link
                title={children
                  .flatMap((v) => v)
                  .filter((v) => v)
                  .join()}
                url={node.data.uri.startsWith('/') ? url.substring(1) : url}
                target={isExternalUrl(url) && '_blank'}
                type={
                  isExternalUrl(url) ? LINK_TYPES.external : LINK_TYPES.internal
                }
              >
                {children}
              </Link>
            </LinkOuter>
          );
        },
        [BLOCKS.QUOTE]: (node, children) => (
          <StyledBodyIntro bodyColor={bodyColor}>{children}</StyledBodyIntro>
        ),
      },
      renderText:
        renderText ||
        ((text) => {
          return text.split('\n').reduce((children, textSegment, index) => {
            return [
              ...children,
              index > 0 && <br key={children.id} />,
              textSegment,
            ];
          }, []);
        }),
    };

    let options = {};

    if (!!enableRenderer || !!renderNode || !!renderMark || !!renderText) {
      options = docOptions;
    }
    return (
      <TextWrapper align={alignment || 'left'} {...rest}>
        {renderRichText(doc, options)}
      </TextWrapper>
    );
  }
);

TextModule.propTypes = {
  doc: PropTypes.object.isRequired,
  enableRenderer: PropTypes.bool,
  bodyColor: PropTypes.string,
  renderNode: PropTypes.object,
  renderMark: PropTypes.object,
  renderText: PropTypes.func,
  alignment: PropTypes.string,
};

export default TextModule;
