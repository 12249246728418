import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const HeroContext = React.createContext();

const HeroProvider = ({ children }) => {
  const [heroIsVisible, setHeroIsVisible] = useState(true);

  return (
    <HeroContext.Provider value={{ heroIsVisible, setHeroIsVisible }}>
      {children}
    </HeroContext.Provider>
  );
};

HeroProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default HeroProvider;
