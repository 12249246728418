import * as THREE from 'three';

import { EasingFunctions } from './Easing';

import { CIRCLE_STATES } from './States';

class BottomCircle extends THREE.Mesh {
  constructor() {
    super();

    this.geometry = new THREE.RingGeometry(2.95, 3, 64);
    this.material = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      side: THREE.DoubleSide,
      opacity: 0,
      transparent: true,
    });

    this.fadeSpeed = 0.12;
    this.rotationSpeed = 0.3;

    this.rotation.x = Math.PI / 2;

    this.geometry.setDrawRange(0, 0);
    this.maxDrawingRange = this.geometry.attributes.position.count * 3;
    this.unfoldingProgress = 0;
    this.unfoldingSpeed = {
      in: 0.07,
      out: 0.1,
    };
  }

  setState(state) {
    this.state = state;
  }

  update(deltaTime) {
    switch (this.state) {
      case CIRCLE_STATES.FADE_OUT: {
        this.material.opacity -= this.fadeSpeed * deltaTime;
        if (this.material.opacity <= 0) {
          this.material.opacity = 0;
        }

        const progress =
          EasingFunctions.easeInQuad(this.unfoldingProgress) *
          this.maxDrawingRange;
        this.geometry.setDrawRange(0, progress);
        this.unfoldingProgress -= this.unfoldingSpeed.out * deltaTime;

        if (this.unfoldingProgress <= 0) {
          this.setState(CIRCLE_STATES.DEFAULT);
          this.unfoldingProgress = 0;
        }

        break;
      }
      case CIRCLE_STATES.FADE_IN: {
        this.material.opacity += this.fadeSpeed * deltaTime;

        if (this.material.opacity >= 1) {
          this.material.opacity = 1;
        }

        const progress =
          EasingFunctions.easeOutQuad(this.unfoldingProgress) *
          this.maxDrawingRange;
        this.geometry.setDrawRange(0, progress);
        this.unfoldingProgress += this.unfoldingSpeed.in * deltaTime;

        if (this.unfoldingProgress >= 1) {
          this.setState(CIRCLE_STATES.DEFAULT);
        }
        break;
      }
      default:
        break;
    }
  }
}

export default BottomCircle;
