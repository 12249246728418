export default ({
  selectionListArticles = [],
  selectionListText = [],
  ...rest
}) => {
  const selectionList = Array.from(
    {
      length: Math.min(selectionListArticles.length, selectionListText.length),
    },
    (_, i) => ({
      text: selectionListText[i],
      slug: selectionListArticles[i].slug,
    })
  );
  return { ...rest, selectionList };
};
