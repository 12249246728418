import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ProgramCard from '@molecules/ProgramCard';
import Carousel from '@molecules/Carousel';
import { SwiperSlide } from 'swiper/react';

const Container = styled.section`
  ${({ theme }) => css`
    overflow: hidden;

    .swiper-container {
      padding: 0 var(--outer-gap);
    }

    ${theme.mediaquery.sm(css`
      .swiper-container {
        padding: 0 calc(var(--outer-gap) + (var(--col) * 3));
      }
    `)}

    .swiper-slide {
      ${theme.mediaquery.sm(
        css`
          width: auto;
          &:not(:last-child) {
            margin-right: calc(var(--inner-gap) + var(--col));
          }
        `
      )}
    }

    .swiper-slide-next,
    .swiper-slide-prev {
      ${theme.mediaquery.sm(
        css`
          opacity: 0.9;
        `
      )}
    }
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    padding-left: var(--outer-gap);
    margin-bottom: ${theme.spacing(1)};

    ${theme.mediaquery.sm(
      css`
        padding-left: calc(var(--outer-gap) + var(--col) * 2);
        margin-bottom: ${theme.spacing(9)};
      `
    )}

    ${theme.mediaquery.md(
      css`
        padding-left: calc(var(--outer-gap) + var(--col) * 3);
      `
    )}
  `}
`;

const ProgramsCarousel = ({ id, title, cards, className }) => {
  return (
    <Container className={className}>
      {!!title && <Title>{title}</Title>}
      <Carousel id={id} customSpacing centeredCards>
        {cards.map((card) => (
          <SwiperSlide key={card.id} className="swiper-slide">
            {/* isActive is a native prop from Swiper */}
            {({ isActive }) => (
              <ProgramCard {...card} isActive={isActive} isProgramsCarousel />
            )}
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  );
};

ProgramsCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(ProgramCard.propTypes)),
  className: PropTypes.string,
  isProgramsCarousel: PropTypes.bool,
};

export default ProgramsCarousel;
