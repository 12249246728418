import { adapter as linkAdapter } from '@atoms/Link';
import { adapter as retailerAdapter } from '@atoms/RetailerButton';

export default ({
  id,
  title,
  showSearchBox,
  searchBoxPlaceholder,
  noRetailersMessage,
  retailers,
  cta,
}) => ({
  id,
  title,
  showSearchBox,
  searchBoxPlaceholder,
  noRetailersMessage,
  retailers:
    retailers && retailers.map((retailer) => retailerAdapter(retailer)),
  cta: cta && linkAdapter(cta),
});
