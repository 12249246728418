import { adapter as imagePushAdapter } from '@molecules/ImagePush';

export default ({ id, title, imagePushes }) => {
  return {
    id,
    title,
    imagePushes:
      imagePushes &&
      imagePushes.map((imagePush) => imagePushAdapter(imagePush)),
  };
};
