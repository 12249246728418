import { IMG_TYPES } from '@atoms/Img';
import getMetaImageFields from './getMetaImageFields';

export default (metadata, brandName) => ({
  ...metadata,
  title: metadata?.title || brandName,
  image:
    metadata &&
    metadata.image &&
    getMetaImageFields(metadata.image, IMG_TYPES.fixed),
});
