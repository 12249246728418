const ALIGNMENT = Object.freeze({
  'Left - Left': {
    position: 'left',
    textAlign: 'left',
  },
  'Center - Left': {
    position: 'center',
    textAlign: 'left',
  },
  'Center - Center': {
    position: 'center',
    textAlign: 'center',
  },
});

export default ALIGNMENT;
