export default ({
  disclaimerTitle,
  disclaimerText,
  checkboxLabel,
  buttonLabel,
  agreementButtonLabel,
}) => {
  return {
    disclaimerTitle,
    disclaimerText,
    checkboxLabel,
    buttonLabel,
    agreementButtonLabel,
  };
};
