export default (path) => {
  if (window.tC?.container) {
    window.tC?.container?.reload({ events: { page: [{}, {}] } });
    setTimeout(function () {
      dataLayer.push({ event: 'PageLoaded' });
    }, 50);
  }
  if (typeof window.tC?.event?.virtualPageview === 'function') {
    window.tC.event.virtualPageview(this, {
      pageUri: path,
    });
  }
};
