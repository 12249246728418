import { adapter as cardAdapter } from '@molecules/Card';
import { HIGHLIGHTED_CARD } from './RelatedCards';

export default ({ id, title, subtitle, cards }) => ({
  id,
  highlightedCard: HIGHLIGHTED_CARD.first,
  title,
  subtitle,
  cards: cards && cards.map((card) => cardAdapter(card)),
});

export const muscleCard = (articles) => {
  return {
    cards: articles && articles.map((card) => cardAdapter(card)),
  };
};

export const moreByInfluencer = (
  { id, title, influencer, content },
  { id: pageId }
) => {
  let cards = [];
  if (content !== null && content.length > 0) {
    cards = content;
  } else if (
    influencer.content_page !== null &&
    influencer.content_page.length > 0
  ) {
    const latestPages = influencer.content_page
      .filter(
        (item, index, array) =>
          array.findIndex((v) => v.cardId === item.cardId) === index
      )
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      .filter(
        (card) =>
          card.cardId !== pageId &&
          card.author &&
          card.author.name === influencer.name
      );
    cards = latestPages.slice(0, 3);
  }

  return {
    id,
    title,
    cards: cards && cards.map((card) => cardAdapter(card)),
  };
};
