import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import InnerHTML from 'dangerously-set-html-content';

import { grid } from '@styles/grid';

const Container = styled.div`
  ${({ fullWidth, theme }) => css`
    ${grid}

    & > * {
      grid-column: 1 / -1;

      ${theme.mediaquery.sm(css`
        text-align: center;
        grid-column: ${fullWidth ? '1 / -1' : '7 / span 12'};
      `)}
    }

    table {
      border: 1px solid white;
      margin: 0 auto;
      width: 100%;

      th {
        font-weight: bold;
        padding: 10px;
      }

      tr {
        border: 1px solid white;
      }

      td {
        border: 1px solid white;
        padding: 10px;
      }
    }

    iframe {
      border: 0 !important;
      width: 100%;
    }
  `}
`;

const CodeEmbed = ({ embedCode, fullWidth }) => {
  return (
    <Container fullWidth={fullWidth}>
      <InnerHTML html={embedCode} />
    </Container>
  );
};

CodeEmbed.propTypes = {
  embedCode: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool.isRequired,
};

export default CodeEmbed;
