// TODO : refactor to not use array keys
/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const StyledTable = styled.table`
  width: 100%;
`;

const Row = styled.tr`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.white};
  `}
`;

const cellStyle = css`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    padding: ${theme.spacing(2)} 0;
    text-align: ${({ first }) => (first ? 'left' : 'right')};
  `}
`;

const Table = React.memo(
  React.forwardRef(({ headers, values, ...rest }, ref) => (
    <StyledTable ref={ref} {...rest}>
      <thead>
        <Row>
          {headers.map((header, key) => (
            <th css={cellStyle} first={key === 0} key={key}>
              {header}
            </th>
          ))}
        </Row>
      </thead>
      <tbody>
        {values.map((row, rowKey) => (
          <Row key={rowKey}>
            {row.map((cell, cellKey) => (
              <td css={cellStyle} first={cellKey === 0} key={cellKey}>
                {cell}
              </td>
            ))}
          </Row>
        ))}
      </tbody>
    </StyledTable>
  ))
);

Table.propTypes = {
  cellPadding: PropTypes.string,
  headers: PropTypes.arrayOf(PropTypes.node).isRequired,
  values: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
  fullWidth: PropTypes.bool,
};

export default Table;
