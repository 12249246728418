import { createGlobalStyle, css } from 'styled-components';
import reset from '@styles/reset';
import root from '@styles/root';

import '@styles/fonts/fonts.css';

const DOT_ANIMATION_DURATION = 333;

const GlobalStyles = createGlobalStyle`
  ${reset}
  ${root}

  html {
    font-size: ${({ theme }) => theme.baseFontSize}px;
    font-family: ${({ theme }) => theme.baseFontFamily};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    background: ${({ theme }) => theme.colors.black};
    color: ${({ theme }) => theme.colors.white};
  }

  .main {
    > * + * {
      margin-top: ${({ theme }) => theme.spacing(8)};

      ${({ theme }) =>
        theme.mediaquery.sm(css`
          margin-top: ${theme.spacing(10)};
        `)}
    }
  }

  /* Prevent scroll on body */
  .ReactModal__Body--open {
    overflow: hidden;
    width: 100%;
    height: 100%;
  }
  .ReactModal__Overlay {
    transition: opacity 0.3s;
    opacity: 0;
    
    &--after-open {
      opacity: 1;
      z-index: ${({ theme }) => theme.zIndex.fullScreenVideo};
    }
    &--before-close {
      opacity: 0;
    }
  }

  .carousel-dot {
    ${({ theme }) => css`
      display: block;
      width: ${theme.spacing(0.5)};
      height: ${theme.spacing(0.5)};
      border-radius: ${theme.spacing(0.5)};
      background-color: ${theme.colors.white};
      transition: width ${DOT_ANIMATION_DURATION}ms
          cubic-bezier(0.58, 0, 0.56, 1),
        background-color ${DOT_ANIMATION_DURATION}ms
          cubic-bezier(0.58, 0, 0.56, 1);

      &--active {
        width: ${theme.spacing(3)};
      }
    `}
  }

  .swiper-pagination {
    display: flex;

    .carousel-dot:not(:last-of-type) {
      margin-right: 6px;
    }    
  }
  
  .product-push-module:last-of-type {
   margin-bottom: 1px;
  }
`;

export default GlobalStyles;
