import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  sideTitle,
  socialMediaTitle,
  imageDesktop,
  imageMobile,
}) => ({
  sideTitle,
  socialMediaTitle,
  image: imageMobile &&
    imageDesktop && {
      small: getImageFields(imageMobile, IMG_TYPES.fluid),
      large: getImageFields(imageDesktop, IMG_TYPES.fluid),
    },
});
