export const MODEL_STATES = Object.freeze({
  DEFAULT: 0,
  FADE_OUT: 1,
  FADE_IN: 2,
  MOVE_IN: 3,
  MOVE_OUT: 4,
  ACTIVE: 5,
  HIDDEN: 6,
});

export const SCENE_STATES = Object.freeze({
  DEFAULT: 0,
  LEFT_ACTIVE: 1,
  RIGHT_ACTIVE: 2,
});

export const CIRCLE_STATES = Object.freeze({
  DEFAULT: 0,
  FADE_IN: 1,
  FADE_OUT: 2,
});

export const BODY_TYPES = Object.freeze({
  MASCULINE: 0,
  FEMININE: 1,
});
