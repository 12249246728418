import React, { useContext, useRef, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { navigate } from 'gatsby';

import { SettingsContext } from '@stores/Settings';

import Facebook from '@icons/facebook.svg';
import Pinterest from '@icons/pinterest.svg';
import Instagram from '@icons/instagram.svg';
import Twitter from '@icons/twitter.svg';
import Youtube from '@icons/youtube.svg';

import Link from '@atoms/Link';
import Img, { IMG_TYPES } from '@atoms/Img';
import Dropdown from '@atoms/Dropdown';

const FooterContainer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${({ theme }) => css`
    background: ${theme.colors.black};
    padding: ${theme.spacing(9)} ${theme.spacing(7)} ${theme.spacing(7)}
      ${theme.spacing(7)};
    ${theme.mediaquery.md(css`
      padding: ${theme.spacing(9)} ${theme.spacing(15)} ${theme.spacing(7)}
        ${theme.spacing(15)};
    `)}
  `}
`;

const Social = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(7)};
`;

const SocialItem = styled.a`
  ${({ theme }) => css`
    margin-left: ${theme.spacing(3)};

    &:first-of-type {
      margin-left: 0;
    }
  `}
`;

const FooterLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
    ${theme.mediaquery.md(css`
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: ${theme.spacing(6)};
    `)}
  `}
`;

const FooterLinkItem = styled(Link)`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    text-align: center;
    margin-bottom: ${theme.spacing(2)};
    ${theme.mediaquery.md(css`
      margin-left: ${theme.spacing(3)};
    `)}
  `}
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;

  ${({ theme }) => css`
    ${theme.mediaquery.md(css`
      flex-direction: row;
    `)}
  `}
`;

const StyledLabelXS = styled.p`
  ${({ theme }) => css`
    ${theme.typography.footnote};
  `}
`;

const RegionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(3)};
    ${theme.mediaquery.md(css`
      margin-bottom: 0;
    `)}
  `}
`;

const FlagContainer = styled.div`
  width: 16px;
  height: 16px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
`;

const icon = (name) =>
  ({
    facebook: Facebook,
    pinterest: Pinterest,
    instagram: Instagram,
    twitter: Twitter,
    youTube: Youtube,
  }[name]);

const Footer = () => {
  const { footer, localizedPageData, locale: currentLocale } = useContext(
    SettingsContext
  );

  const {
    countryFlag,
    footerLinks,
    footerCopyright,
    facebookLink,
    twitterLink,
    instaLink,
    pinterestLi,
    youtubeLink,
  } = footer;

  const socialLinks = [
    {
      name: 'facebook',
      url: facebookLink,
    },
    {
      name: 'twitter',
      url: twitterLink,
    },
    {
      name: 'instagram',
      url: instaLink,
    },
    {
      name: 'pinterest',
      url: pinterestLi,
    },
    {
      name: 'youTube',
      url: youtubeLink,
    },
  ].filter((item) => item.url);

  const languageSelectorRef = useRef(null);

  const changeLocale = useCallback(() => {
    const newLocale = languageSelectorRef.current.value;
    const localizedData = localizedPageData.find(
      (data) => data.locale === newLocale
    );
    if (localizedData && localizedData.path) {
      navigate(localizedData.path);
    }
  }, [localizedPageData]);

  return (
    <FooterContainer>
      {!!socialLinks && (
        <Social>
          {socialLinks.map((socialLink) => {
            const Icon = icon(socialLink.name);
            return (
              <SocialItem
                key={socialLink.name}
                target="_blank"
                href={socialLink.url}
                rel="noopener"
              >
                <Icon width="24px" height="24px" />
              </SocialItem>
            );
          })}
        </Social>
      )}
      {!!footerLinks && (
        <FooterLinks>
          {footerLinks.map((footerLink) => (
            <FooterLinkItem key={footerLink.id} {...footerLink} />
          ))}
        </FooterLinks>
      )}
      <BottomContainer>
        <div>
          <StyledLabelXS>
            {footerCopyright && footerCopyright.value}
          </StyledLabelXS>
        </div>
        <RegionContainer>
          <FlagContainer>
            {!!countryFlag && (
              <Img type={IMG_TYPES.fixed} small={countryFlag.fixed} />
            )}
          </FlagContainer>
          {localizedPageData.length > 1 ? (
            <Dropdown
              ref={languageSelectorRef}
              value={currentLocale}
              onChange={changeLocale}
              visible
            >
              {localizedPageData.map(({ locale, language }) => {
                return (
                  <option key={locale} value={locale}>
                    {language.long}
                  </option>
                );
              })}
            </Dropdown>
          ) : (
            <StyledLabelXS>{localizedPageData[0].language.long}</StyledLabelXS>
          )}
        </RegionContainer>
      </BottomContainer>
    </FooterContainer>
  );
};

export default Footer;
