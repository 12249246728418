import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import TextModule from '@atoms/TextModule';
import Link, { adapter as linkAdapter } from '@atoms/Link';
import { buttonStyle } from '@atoms/Button';
import { grid } from '@styles/grid';

const Wrapper = styled.div`
  ${grid};
  width: 100vw;
  padding: ${({ theme }) => theme.spacing(25)}
    ${({ theme }) => theme.spacing(4)} 0 ${({ theme }) => theme.spacing(4)};

  ${({ theme }) =>
    theme.mediaquery.md(css`
      padding: ${theme.spacing(25)};
      padding-bottom: 0;
    `)}
`;

const Content = styled.div`
  grid-column: 1 / -1;

  ${({ theme }) =>
    theme.mediaquery.md(css`
      grid-column: 1 / span 12;
    `)}
`;

const Tagline = styled.h1`
  ${({ theme }) => theme.typography.bodyM}
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Header = styled.p`
  ${({ theme }) => theme.typography.titleXL}
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const StyledTextModule = styled(TextModule)`
  display: block;
  padding: 0;
`;

const Cta = styled(Link)`
  ${({ theme }) => buttonStyle(theme, 'primary')}
  display: inline-block;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

const Error = ({ tagline, header, description, cta }) => {
  return (
    <Wrapper>
      <Content>
        {tagline && <Tagline>{tagline}</Tagline>}
        <Header as={tagline ? 'p' : 'h1'}>{header}</Header>
        <StyledTextModule doc={description.value} enableRenderer />
        {cta && <Cta {...linkAdapter(cta)} />}
      </Content>
    </Wrapper>
  );
};

Error.propTypes = {
  tagline: PropTypes.string,
  header: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  cta: PropTypes.object,
};

export default Error;
