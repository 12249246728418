import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Marquee from '@atoms/Marquee';
import CLPCarousel from '@molecules/CLPCarousel';

const StyledMarquee = styled(Marquee)`
  ${({ theme, index }) => css`
    padding-top: ${index === 0 ? theme.spacing(2) : theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      padding-top: ${index === 0 ? theme.spacing(4) : theme.spacing(10)};
    `)}
  `}
`;

const CLPCategory = ({ marquee, productCategories, ctaLabel, index }) => (
  <>
    <StyledMarquee index={index} {...marquee} lines={2} />
    {productCategories.map((carousel) => (
      <CLPCarousel key={carousel.id} {...carousel} ctaLabel={ctaLabel} />
    ))}
  </>
);

CLPCategory.propTypes = {
  marquee: PropTypes.shape({
    filled: PropTypes.string.isRequired,
    outlined: PropTypes.string.isRequired,
  }).isRequired,
  productCategories: PropTypes.arrayOf(PropTypes.shape(CLPCarousel.propTypes))
    .isRequired,
  ctaLabel: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

export default CLPCategory;
