import { adapter as retailerAdapter } from '@atoms/RetailerButton';
import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  id,
  title,
  retailers,
  image,
  imageMobile,
  highlightedProduct,
}) => ({
  id,
  title,
  retailers: retailers.map((retailer) => retailerAdapter(retailer)),
  background: (image || imageMobile) && {
    small: getImageFields(imageMobile, IMG_TYPES.fluid),
    large: getImageFields(image, IMG_TYPES.fluid),
  },
  product: highlightedProduct && {
    flavour: highlightedProduct.flavour,
    category:
      highlightedProduct.productCategory &&
      highlightedProduct.productCategory.name,
    protein: highlightedProduct.proteinGramage,
    image: getImageFields(highlightedProduct.productPicture, IMG_TYPES.fluid),
  },
});
