import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import { breakpoints } from '@utils';
import { buttonStyle } from '@atoms/Button';
import Link, { LINK_TYPES } from '@atoms/Link';

import { grid } from '@styles/grid';
import InfluencerTagline from '../InfluencerTagline/InfluencerTagline';

const Container = styled.section`
  position: relative;
  overflow: hidden;
`;

const List = styled.ul`
  ${grid};
  grid-row-gap: var(--inner-gap);
  max-width: calc(340px + var(--inner-gap) * 2);
  margin: 0 auto;
  padding: 0;
  align-items: center;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      margin-left: auto;
      margin-right: auto;
      max-width: none;
    `)}
  `}
`;

const Item = styled.li`
  grid-column: 1 / span 3;
  position: relative;

  &:first-of-type {
    z-index: 2;
  }

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: 5 / span 8;
    `)}

    ${theme.mediaquery.md(css`
      grid-column: span 4;

      &:first-of-type {
        grid-column: 4 / span 10;
      }
    `)}
  `}
`;

const ProductItem = styled.div`
  position: absolute;
  top: 50%;
  right: -20%;
  transform: rotate(12deg) translateY(-50%);
  pointer-events: none;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      right: 15%;
    `)}
  `}
`;

const ProductImageContainer = styled.div`
  width: calc((var(--outer-gap) * 2) + var(--col) * 3);
  max-width: 500px;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      max-width: initial;
      width: 35vw;
    `)}
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    display: -webkit-box;
    position: relative;
    margin-top: ${theme.spacing(1)};
    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(3)};
    `)}
  `}
`;

const Description = styled.p`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(1)};
    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(3)};
    `)}
  `}
`;

const StyledLink = styled.button`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin-top: ${theme.spacing(1)};
    display: inline-block;
    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(3)};
    `)}
  `}
`;

const PushContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      flex-direction: row;
    `)}
  `}

  ${({ breakLines, theme }) => css`
    padding: ${theme.spacing(4)} 0;
    margin: ${theme.spacing(2)} var(--outer-gap);
    position: relative;

    ::before,
    ::after {
      display: ${breakLines ? 'block' : 'none'};
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      left: 0;
      background-color: ${theme.colors.white};
    }

    ::before {
      top: 0;
    }

    ::after {
      bottom: 0;
    }

    ${theme.mediaquery.md(css`
      padding: ${theme.spacing(10)} 0;
      margin: 0px var(--outer-gap);
      ::before,
      ::after {
        width: calc(var(--outer-gap) + var(--col) * 17);
        left: calc(var(--col) * 3);
      }
    `)}
  `}
`;

const ProductPush = ({
  product,
  title,
  ctaText,
  copyText,
  breakLines,
  influencer,
}) => (
  <Container className="product-push-module">
    <PushContainer
      title={title}
      url={product.slug}
      type={LINK_TYPES.internal}
      isProduct
      breakLines={breakLines}
    >
      <List>
        <Item>
          {influencer && (
            <InfluencerTagline
              picture={influencer.picture}
              name={influencer.name}
              categories={influencer.categories}
            />
          )}
          <Title color={product.productColor}>{title}</Title>
          <Description>{copyText}</Description>
          <StyledLink>{ctaText}</StyledLink>
        </Item>
      </List>
    </PushContainer>
    <ProductItem>
      {product && (
        <ProductImageContainer>
          <Img
            type={IMG_TYPES.fluid}
            fits={IMG_FITS.none}
            small={{
              ...product.image,
              sizes: `(min-width: ${breakpoints.sm}px) 30vw ,75vw`,
            }}
            alt={title}
          />
        </ProductImageContainer>
      )}
    </ProductItem>
  </Container>
);

ProductPush.propTypes = {
  product: PropTypes.shape({
    image: PropTypes.object,
    slug: PropTypes.string.isRequired,
    productColor: PropTypes.string.isRequired,
  }),
  influencer: PropTypes.shape(InfluencerTagline.propTypes),
  title: PropTypes.string.isRequired,
  copyText: PropTypes.string,
  ctaText: PropTypes.string,
  breakLines: PropTypes.bool,
};

export default ProductPush;
