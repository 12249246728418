import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Outer = styled.div`
  height: ${({ theme }) => theme.spacing(1)};
`;

const Inner = styled.div.attrs(({ progress }) => ({
  style: {
    width: `${progress}%`,
  },
}))`
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  transition: width 0.1s linear;
`;

const ProgressBar = React.forwardRef(({ progress, ...rest }, ref) => (
  <Outer ref={ref} {...rest}>
    <Inner progress={Math.min(100, Math.max(0, progress * 100))} />
  </Outer>
));

ProgressBar.propTypes = {
  progress: PropTypes.number.isRequired, // 0.0 - 1.0
};

export default ProgressBar;
