import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  id,
  title,
  description,
  imageMobile,
  imageDesktop,
  imagePosition,
}) => ({
  id,
  title,
  description,
  image: imageMobile &&
    imageDesktop && {
      small: getImageFields(imageMobile, IMG_TYPES.fluid),
      large: getImageFields(imageDesktop, IMG_TYPES.fluid),
    },
  imagePosition:
    (imagePosition && (imagePosition === 'After text' ? 'after' : 'before')) ||
    'before',
});
