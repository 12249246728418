import React, { useContext, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Link, { LINK_TYPES } from '@atoms/Link';
import Img, { IMG_TYPES, IMG_FITS } from '@atoms/Img';

import { SettingsContext } from '@stores/Settings';
import BurgerIcon from '@icons/burger.svg';
import CartIcon from '@icons/shopping-cart.svg';
import CloseIcon from '@icons/closemedium.svg';
import lockScroll from 'react-lock-scroll';
import { useScrollDirection, DIRECTIONS } from '@hooks';
import { rgba } from '@utils';
import { HeroContext } from '@stores/Hero';

const MENU_ANIMATION_DURATION = 750;
const BEZIER = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)';
const BIG_LOGO_WIDTH = 500;

const MenuOverlay = styled.div`
  ${({ theme, isOpened }) => css`
    width: 100vw;
    height: 100vh;
    background: ${rgba(theme.colors.black, 0.4)};
    display: none;
    position: fixed;
    top: 0;
    left: 0%;
    transition: opacity ${MENU_ANIMATION_DURATION}ms ${BEZIER};
    opacity: ${isOpened ? 1 : 0};
    pointer-events: ${isOpened ? 'auto' : 'none'};
    z-index: ${theme.zIndex.nav};
    ${theme.mediaquery.md(css`
      display: block;
    `)};
  `}
`;

const MenuContainer = styled.div`
  ${({ theme, alignMenuLeft }) => css`
    display: flex;
    position: fixed;
    top: 0;
    left: ${alignMenuLeft ? '0' : 'unset'};
    right: ${alignMenuLeft ? 'unset' : '0'};

    background-color: black;
    height: 100vh;
    width: 100vw;
    transform: ${({ isOpened }) =>
      isOpened
        ? 'translateX(0)'
        : `translateX(${alignMenuLeft ? '-100%' : '100%'})`};
    transition: transform ${MENU_ANIMATION_DURATION}ms ${BEZIER};
    z-index: ${theme.zIndex.nav};
    overflow: auto;

    ${theme.mediaquery.md(css`
      width: 35vw;
      &:after {
        display: block;
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100vh;
        background: ${rgba(theme.colors.white, 0.1)};
      }
    `)}
  `}
`;

const MenuLinksContainer = styled.ul`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding-top: ${theme.spacing(12)};

    ${theme.mediaquery.md(css`
      padding-top: ${theme.spacing(20)};
    `)}
  `}
`;

const MenuItem = styled.li`
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const MenuItemLink = styled(Link)`
  ${({ theme }) => css`
    ${theme.typography.titleS}
    cursor: pointer;
    position: relative;
    text-decoration: none;
    color: ${theme.colors.white};
    padding: ${theme.spacing(2)} ${theme.spacing(4)};
    width: 100%;

    ${theme.mediaquery.md(css`
      padding: ${theme.spacing(3)} ${theme.spacing(12)};
    `)}

    &.active {
      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: ${theme.colors.white};
      }
    }
  `}
`;

const HeaderMenu = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40%;
  z-index: 10;
  transition: transform 0.5s ease-in-out 0.2s;
  transform: ${({ isVisible }) =>
    isVisible ? 'translateY(0)' : 'translateY(-100%)'};
  pointer-events: none;
`;

const TopShadowOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s ease-in-out;

  ${({ theme, isOpened }) => css`
    opacity: ${isOpened ? 0 : 1};
    background-image: linear-gradient(
      180deg,
      ${rgba(theme.colors.black, 0.5)} 0%,
      ${rgba(theme.colors.black, 0)} 100%
    );
  `}
`;

const LogoContainer = styled.div`
  ${({ theme, isOpened, alignMenuLeft }) => css`
    position: fixed;
    pointer-events: ${isOpened ? 'none' : 'auto'};
    top: ${theme.spacing(6)};
    left: ${theme.spacing(5)};

    ${alignMenuLeft &&
    css`
      transform: translateX(-50%);
      left: 50%;
    `}
    ${theme.mediaquery.md(css`
      transform: translateX(-50%);
      top: ${theme.spacing(13.5)};
      left: 50%;
    `)}
  `}
`;

const Logo = styled(Link)`
  display: inline-block;
  cursor: pointer;
  width: 100px;
  transition: opacity 0.3s ease-in-out;

  ${({ theme, isOpened }) => css`
    opacity: ${isOpened ? 0 : 1};
    ${theme.mediaquery.md(css`
      opacity: 1;
    `)}
  `}
`;

const BigLogo = styled.div`
  ${({ ratio }) => css`
    pointer-events: none;
    width: ${BIG_LOGO_WIDTH}px;
    opacity: 0.1;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(
        calc(-50% - (${BIG_LOGO_WIDTH / ratio / 2}px + ${BIG_LOGO_WIDTH / 2}px))
      )
      rotate(-90deg);
    transform-origin: right bottom;
  `}
`;

const iconStyles = css`
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
`;

const ToggleMenuContainer = styled.button`
  ${({ theme, alignMenuLeft }) => css`
    cursor: pointer;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    position: absolute;
    display: inline-block;
    top: ${theme.spacing(5)};
    ${alignMenuLeft
      ? css`
          left: ${theme.spacing(5)};
        `
      : css`
          right: ${theme.spacing(5)};
        `}
    pointer-events: all;

    ${theme.mediaquery.md(css`
      top: ${theme.spacing(12.5)};
      ${alignMenuLeft
        ? `left: ${theme.spacing(18)}`
        : `right: ${theme.spacing(18)}`};
    `)}

    .close-icon {
      opacity: ${({ isOpened }) => (isOpened ? 1 : 0)};
    }

    .burger-icon {
      opacity: ${({ isOpened }) => (isOpened ? 0 : 1)};
    }
  `}
`;

const iconStylesCart = css`
  transition: opacity 0.5s ease-in-out;
  width: 32px;
  height: 32px;
`;

const CartContainer = styled.a`
  ${({ theme, alignMenuLeft = false }) => css`
    cursor: pointer;
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    position: absolute;
    display: inline-block;
    top: ${theme.spacing(5)};
    ${alignMenuLeft
      ? css`
          right: ${theme.spacing(5)};
        `
      : css`
          right: ${theme.spacing(12)};
        `}
    pointer-events: all;

    ${theme.mediaquery.md(css`
      top: ${theme.spacing(12.5)};
      ${alignMenuLeft
        ? `right: ${theme.spacing(18)}`
        : `right: ${theme.spacing(25)}`};
    `)}
    .cart-icon {
      opacity: ${({ isOpened }) => (isOpened ? 0 : 1)};
    }
  `}
`;

const StyledCloseIcon = styled(CloseIcon)`
  ${iconStyles}
`;

const StyledBurgerIcon = styled(BurgerIcon)`
  ${iconStyles}
`;

const StyledCartIcon = styled(CartIcon)`
  ${iconStylesCart}
`;

const Navigation = () => {
  const { nav, brandName } = useContext(SettingsContext);
  const { heroIsVisible } = useContext(HeroContext);
  const { logo, navLinks, alignMenuLeft, shoppingCartUrl } = nav;
  const logoRatio = logo.aspectRatio;
  const [isOpened, setIsOpened] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [toggleScrollLock, setToggleScrollLock] = useState(false);
  lockScroll(toggleScrollLock);

  const scrollDir = useScrollDirection({
    initialDirection: DIRECTIONS.up,
    thresholdPixels: 10,
    scrollThrottle: 100,
  });

  const toggleMenu = useCallback(() => {
    setIsOpened(!isOpened);
    setToggleScrollLock(!toggleScrollLock);
  }, [isOpened, toggleScrollLock]);

  useEffect(() => {
    if (heroIsVisible) {
      setIsVisible(true);
    } else {
      setIsVisible(scrollDir === DIRECTIONS.up);
    }
  }, [scrollDir, heroIsVisible]);

  return (
    <>
      <HeaderMenu isVisible={isVisible}>
        <TopShadowOverlay isOpened={isOpened} />

        <LogoContainer isOpened={isOpened} alignMenuLeft={alignMenuLeft}>
          <Logo
            title={logo.title || brandName || 'YoPRO'}
            url="home"
            type={LINK_TYPES.internal}
            isOpened={isOpened}
          >
            <Img type={IMG_TYPES.fluid} fits={IMG_FITS.contain} small={logo} />
          </Logo>
        </LogoContainer>
        <ToggleMenuContainer
          onClick={toggleMenu}
          isOpened={isOpened}
          alignMenuLeft={alignMenuLeft}
        >
          <StyledCloseIcon className="close-icon" />
          <StyledBurgerIcon className="burger-icon" />
        </ToggleMenuContainer>
        {shoppingCartUrl && (
          <CartContainer
            isOpened={isOpened}
            alignMenuLeft={alignMenuLeft}
            target="_blank"
            href={shoppingCartUrl}
            rel="noopener"
          >
            <StyledCartIcon className="cart-icon" />
          </CartContainer>
        )}
      </HeaderMenu>
      <MenuOverlay isOpened={isOpened} onClick={toggleMenu} />

      <MenuContainer isOpened={isOpened} alignMenuLeft={alignMenuLeft}>
        <MenuLinksContainer>
          {navLinks.map((navLink) => (
            <MenuItem key={`${navLink.id}${navLink.url}`}>
              <MenuItemLink {...navLink} activeClassName="active">
                {navLink.label}
              </MenuItemLink>
            </MenuItem>
          ))}
        </MenuLinksContainer>
        <BigLogo ratio={logoRatio}>
          <Img type={IMG_TYPES.fluid} fits={IMG_FITS.contain} small={logo} />
        </BigLogo>
      </MenuContainer>
    </>
  );
};

Navigation.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape(Link.propTypes)),
  IsOpened: PropTypes.bool,
  alignMenuLeft: PropTypes.bool,
  shoppingCartUrl: PropTypes.string,
  setIsOpened: PropTypes.func,
  logo: PropTypes.object,
};

export default Navigation;
