import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from '@molecules/Modal';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import { getImageFields } from '@utils';

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    text-align: center;
    margin: ${theme.spacing(3)};
  `}
`;

const PushContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 100%;
  width: 100%;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      padding: ${theme.spacing(8)};
    `)}
  `}
`;

const StyledLink = styled.div`
  ${({ theme }) => css`
    margin: ${theme.spacing(3)} auto;
    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(3)};
    `)}
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  max-height: 90vh;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      max-width: initial;
      width: 80%;
    `)}
  `}
`;

const PromotionalOverlay = ({
  title,
  promotionalImageMobile,
  promotionalImageDesktop,
  promotionalLinkLabel,
  promotionalLink,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isOutdated, setIsOutdated] = useState(false);
  const promotionalImage = {
    small: getImageFields(promotionalImageMobile, IMG_TYPES.fluid),
    large: getImageFields(promotionalImageDesktop, IMG_TYPES.fluid),
  };

  const handleLocalStorage = () => {
    localStorage.setItem(`${title} displayed`, 'true');
  };

  useEffect(() => {
    if (
      !isOutdated &&
      !!title &&
      JSON.parse(localStorage.getItem(`${title} displayed`) === 'true')
    ) {
      setIsOutdated(true);
      setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isOutdated, title]);

  return (
    <>
      {!isOutdated && (
        <Modal
          onClose={() => {
            setIsVisible(false);
            handleLocalStorage();
          }}
          isOpen={isVisible}
        >
          <PushContainer
            href={promotionalLink}
            onClick={(e) => {
              e.preventDefault();
              setIsVisible(false);
              handleLocalStorage();
              window.open(promotionalLink, '_blank');
            }}
          >
            <Title>{title}</Title>
            <ImageContainer>
              <Img
                type={IMG_TYPES.fluid}
                fits={IMG_FITS.none}
                small={{
                  ...promotionalImage?.small,
                }}
                large={{ ...promotionalImage?.large }}
                alt={title}
              />
            </ImageContainer>
            <StyledLink>{promotionalLinkLabel}</StyledLink>
          </PushContainer>
        </Modal>
      )}
    </>
  );
};

PromotionalOverlay.propTypes = {
  title: PropTypes.string,
  promotionalImageMobile: PropTypes.object,
  promotionalImageDesktop: PropTypes.object,
  promotionalLinkLabel: PropTypes.string.isRequired,
  promotionalLink: PropTypes.any,
};

export default PromotionalOverlay;
