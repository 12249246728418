import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { grid } from '@styles/grid';

import ProductCTA from '@molecules/ProductCTA';

const Container = styled.section`
  ${({ theme }) => css`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${theme.spacing(15)};
  `};
`;

const CardsContainer = styled.ul`
  ${grid};
  grid-row-gap: var(--inner-gap);

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      --columns: 18;
      padding: 0
        ${({ isCalculator }) =>
          isCalculator ? 0 : 'calc(var(--outer-gap) + var(--col) * 3)'};
    `)}
`;

const CardsItem = styled.li`
  grid-column: 1 / -1;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: span 6;
    `)}
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;
    text-transform: uppercase;

    ${theme.mediaquery.lg(css`
      max-width: 35%;
    `)}
    ${theme.mediaquery.md(css`
      max-width: 45%;
      box-sizing: content-box;
    `)}

    ${theme.mediaquery.sm(css`
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const CalculatorProductPush = ({
  title,
  cards,
  CTALabel,
  isCalculator = false,
}) => {
  const containerRef = useRef(null);

  if (cards.length === 0) {
    return null;
  }
  return (
    <Container>
      {!!title && <Title>{title}</Title>}
      <CardsContainer ref={containerRef} isCalculator={isCalculator}>
        {cards.map((card, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <CardsItem key={`${idx}_${card.title}`}>
            <ProductCTA
              card={card}
              image={card.productPicture}
              CTALabel={CTALabel}
            />
          </CardsItem>
        ))}
      </CardsContainer>
    </Container>
  );
};

CalculatorProductPush.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(ProductCTA.propTypes)),
  CTALabel: PropTypes.string,
  isCalculator: PropTypes.bool,
};

export default CalculatorProductPush;
