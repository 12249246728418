export default ({ legalNotice, servingSize, nutritionalValues }) => {
  return {
    legal: legalNotice,
    servingSize,
    rows: nutritionalValues.map(({ name, per100, perServing, rda }) => ({
      name,
      per100,
      perServing,
      rda,
    })),
  };
};
