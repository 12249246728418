import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Carousel from '@molecules/Carousel';
import ProductCTA from '@molecules/ProductCTA';

import { SwiperSlide } from 'swiper/react';

const Container = styled.section`
  ${({ theme }) => css`
    overflow: hidden;
    display: block;
    flex-direction: column;
    align-items: center;
    margin-bottom: ${theme.spacing(15)};
  `};
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;
    text-transform: uppercase;

    ${theme.mediaquery.lg(css`
      max-width: 35%;
    `)}
    ${theme.mediaquery.md(css`
      max-width: 45%;
      box-sizing: content-box;
    `)}

    ${theme.mediaquery.sm(css`
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const CalculatorProductPushCarousel = ({ title, cards, CTALabel }) => {
  if (cards.length === 0) {
    return null;
  }
  return (
    <Container>
      {!!title && <Title>{title}</Title>}
      <Carousel>
        {cards.map((card, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={`${idx}_${card.title}`} className="swiper-slide">
            <ProductCTA
              card={card}
              image={card.productPicture}
              CTALabel={CTALabel}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  );
};

CalculatorProductPushCarousel.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(ProductCTA.propTypes)),
  CTALabel: PropTypes.string,
};

export default CalculatorProductPushCarousel;
