import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import Disclaimer from '@molecules/Disclaimer';
import CognitoForm from '@molecules/CognitoForm';
import { buttonStyle } from '@atoms/Button';
import styled, { css } from 'styled-components';
import HCPArticle from '@organisms/HCPArticle';
import { getSettings, getPageMeta } from '@utils';
import { HcpCardAdapter } from '../components/molecules/Card/adapter';
import trackEvent from './trackEvent';

const HCPButton = styled.button`
  ${({ theme }) => css`
    max-width: 25%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    ${buttonStyle(theme, 'primary')};
    position: fixed;
    bottom: ${theme.spacing(4)};
    right: ${theme.spacing(4)};
    ${theme.mediaquery.sm(css`
      max-width: initial;
    `)}
  `}
`;

const HCPMainPage = ({ data, pageContext, path }) => {
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulSettings;
  const pageSeo = getPageMeta(page.seo, settings.brandName);
  const cognitoForm = page.allHcpPageForm;
  const disclaimer = page.allHcpPageDisclaimer;

  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== 'undefined';

  const [disclaimerVisible, setDisclaimerVisible] = useState(true);
  const [isSavedInStorage, setIsSavedInStorage] = useState(
    isBrowser &&
      JSON.parse(
        localStorage.getItem(`${disclaimer.disclaimerTitle} displayed`) ===
          'true'
      )
  );

  const handleLocalStorage = () => {
    localStorage.setItem(`${disclaimer.disclaimerTitle} displayed`, 'true');
  };

  const cards = data.allContentfulHcpPage.edges.map(({ node }) => {
    return HcpCardAdapter(node);
  });

  const highlightedCards = page.allHcpPageHighlightedCards.map((card) =>
    HcpCardAdapter(card)
  );

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <CognitoForm
        formTitle={cognitoForm.formTitle}
        cognitoSrc={cognitoForm.cognitoSrc}
      />
      <HCPArticle cards={cards} highlightedCards={highlightedCards} />
      <Disclaimer
        disclaimerTitle={disclaimer?.disclaimerTitle}
        disclaimerText={disclaimer?.disclaimerText}
        checkboxLabel={disclaimer?.checkboxLabel}
        buttonLabel={disclaimer?.buttonLabel}
        disclaimerVisible={disclaimerVisible}
        setDisclaimerVisible={setDisclaimerVisible}
        isSavedInStorage={isSavedInStorage}
        setIsSavedInStorage={setIsSavedInStorage}
        handleLocalStorage={handleLocalStorage}
      />
      <HCPButton
        onClick={() => {
          setDisclaimerVisible(true);
          localStorage.setItem(
            `${disclaimer.disclaimerTitle} displayed`,
            'false'
          );
          setIsSavedInStorage(false);
        }}
      >
        {disclaimer.agreementButtonLabel}
      </HCPButton>
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getHCPMainPageData($nodeLocale: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    allContentfulHcpPage(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          ...CardHcp
        }
      }
    }
  }
`;

HCPMainPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default HCPMainPage;
