import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Accordion from '@atoms/Accordion';
import SectionTitle from '@atoms/SectionTitle';
import TextModule from '@atoms/TextModule';
import ChipSelector from '@molecules/ChipSelector';
import { grid } from '@styles/grid';

const QAListContainer = styled.section`
  ${grid}
`;

const Inner = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / -1;
    ${theme.mediaquery.md(css`
      grid-column: 4 / -4;
    `)}
  `}
`;

const Question = styled(Accordion)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(3)};
  `}
`;
const AccordionElement = styled(SectionTitle)`
  ${({ theme }) => css`
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: ${theme.spacing(3)};
    ${theme.mediaquery.md(css`
      padding-right: calc(var(--outer-gap) + var(--col) * 3);
    `)}
    &:before {
      width: 4px;
      left: calc(var(--outer-gap) * -1);
      ${theme.mediaquery.md(css`
        left: calc((var(--outer-gap) + var(--col) * 3) * -1);
        width: 6px;
      `)}
    }
  `}
`;
const Answer = styled(TextModule)`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    display: block;
    padding: 0;

    a {
      text-decoration: underline;
    }
  `}
`;

const ParagraphStyled = styled.div``;

const ChipSelectorStyled = styled(ChipSelector)`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing(10)};
  `}
`;

const QuestionAnswerList = ({ questions }) => {
  const [active, setActive] = useState();
  const [selectedFilters, setSelectedFilters] = useState([]);
  const filters =
    questions.length &&
    Object.values(
      questions.reduce((acc, item) => {
        if (!item.topics) {
          return acc;
        }
        return {
          ...acc,
          ...item.topics.reduce(
            (acc_, topic) => ({
              ...acc_,
              [topic.id]: { key: topic.id, label: topic.topic },
            }),
            {}
          ),
        };
      }, {})
    );

  const onChange = (newFilters) => {
    setSelectedFilters(newFilters);
  };
  return (
    <QAListContainer>
      <Inner>
        {filters && <ChipSelectorStyled items={filters} onChange={onChange} />}
        <div>
          {questions
            .filter(
              ({ topics }) =>
                selectedFilters.length < 1 ||
                topics.find((topic) => selectedFilters.includes(topic.id))
            )
            .map(({ id, question, answer }, idx) => (
              <Question
                key={id}
                hiddenLabel={<AccordionElement title={question} />}
                visibleLabel={<AccordionElement title={question} />}
                onChange={() => setActive(idx)}
                initial={idx === active}
              >
                <ParagraphStyled>
                  <Answer alignment="left" doc={answer} enableRenderer />
                </ParagraphStyled>
              </Question>
            ))}
        </div>
      </Inner>
    </QAListContainer>
  );
};

QuestionAnswerList.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      question: PropTypes.string,
      answer: PropTypes.object,
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          topic: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default QuestionAnswerList;
