import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { grid } from '@styles/grid';

import Thumbnail from '@molecules/Thumbnail';
import Tooltip from '@atoms/Tooltip';

const Container = styled.section`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: ${theme.spacing(15)};
  `}
`;

const CardsContainer = styled.ul`
  ${grid};
  grid-row-gap: var(--inner-gap);

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      --columns: ${({ isCalculator }) => (isCalculator ? '' : 18)};
      padding: 0
        ${({ isCalculator, containerPadding }) =>
          isCalculator
            ? containerPadding
            : 'calc(var(--outer-gap) + var(--col) * 3)'};
      --columns: ${({ containerColumns }) => containerColumns};
    `)}
`;

const CardsItem = styled.li`
  grid-column: 1 / -1;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: span 6;
    `)}
  `}
`;

const RelatedThumbnail = ({
  cards,
  isCalculator = false,
  gridOverride = false,
  tooltip,
}) => {
  const containerRef = useRef(null);
  const [containerColumns, setContainerColumns] = useState(24);
  const [containerPadding, setContainerPadding] = useState('0%');

  useEffect(() => {
    if (gridOverride) {
      if (cards.length < 4) {
        setContainerColumns(18);
        setContainerPadding('10%');
      }
      if (cards.length === 4) {
        setContainerColumns(24);
        setContainerPadding('5%');
      }
      if (cards.length > 4) {
        setContainerColumns(30);
        setContainerPadding('0%');
      }
    }
  }, [cards.length, gridOverride]);

  if (cards.length === 0) {
    return null;
  }

  function filteredCards() {
    // Occasion split uses gridOverride to show 3-5 elements, protein split gives 4-8, but should show only 4 or 8.
    if (!gridOverride && cards.length !== 8) {
      return cards.slice(0, 4);
    }
    return cards;
  }

  return (
    <Container>
      <CardsContainer
        ref={containerRef}
        isCalculator={isCalculator}
        containerColumns={containerColumns}
        containerPadding={containerPadding}
      >
        {filteredCards().map((card, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <CardsItem key={`${idx}_${card.title}`}>
            {cards.length === 5 && idx === 1 ? (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Thumbnail
                  title={card.title}
                  subtitle={card.subtitle}
                  image={card.thumbnail}
                />
                <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                  <Tooltip tooltip={tooltip} />
                </div>
              </div>
            ) : (
              <Thumbnail
                title={card.title}
                subtitle={card.subtitle}
                image={card.thumbnail}
              />
            )}
          </CardsItem>
        ))}
      </CardsContainer>
    </Container>
  );
};

RelatedThumbnail.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(Thumbnail.propTypes)).isRequired,
  isCalculator: PropTypes.bool,
  gridOverride: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default RelatedThumbnail;
