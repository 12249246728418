import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import SearchIcon from '@icons/search.svg';
import RetailersListContainer from '@molecules/RetailersList';
import { buttonStyle } from '@atoms/Button';
import Link from '@atoms/Link';

const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;

const PushContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 var(--outer-gap);
    width: 100%;

    ${theme.mediaquery.sm(css`
      width: 70vw;
    `)}
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    position: relative;
  `}
`;

const SearchBoxContainer = styled.div`
  position: relative;

  ${({ theme }) => css`
    margin-top: ${theme.spacing(4)};
  `};
`;

const SearchBox = styled.input`
  ${({ theme }) => css`
    width: 100%;
    background: transparent;
    border: 0;
    border-bottom: 2px solid ${theme.colors.white};
    color: ${theme.colors.white};
    height: 50px;
    box-shadow: none;
    appearance: none;
    text-indent: 15px;
    border-radius: 0;
    font-family: ${theme.typography.bodyM};

    :focus {
      outline: none;
      border: 1px solid ${theme.colors.white};
      border-radius: ${theme.spacing(2)};
  `};
`;

const SearchIconStyled = styled(SearchIcon)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  ${({ theme }) => css`
    fill: ${theme.colors.white};
  `};
`;

const RetailersListContainerStyled = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(5)};
    max-width: 100%;
    min-height: 150px;

    ul {
      max-width: 100%;
      flex: 0 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: ${theme.spacing(3)};
      padding: 0;

      li {
        flex: 0 0 auto;
        width: 100%;

        ${theme.mediaquery.sm(css`
          width: calc(50% - ${theme.spacing(1.5)});
        `)}

        ${theme.mediaquery.md(css`
          width: calc(20% - ${theme.spacing(3)});
        `)}
      }

      a {
        width: 100%;
        height: 150px;
      }

      .gatsby-image-wrapper {
        width: 85% !important;
        height: 150px !important;
        max-height: 100%;

        img {
          position: static !important;
          object-fit: contain !important;
        }
      }
    }
  `};
`;

const NoRetailersMessage = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    position: relative;
    text-align: center;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`;

const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

const StyledButton = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    display: inline-flex;
  `}
`;

const RetailersList = (props) => {
  const {
    id,
    title,
    showSearchBox,
    searchBoxPlaceholder,
    noRetailersMessage,
    retailers,
    cta,
  } = props;
  const retailerSearchBoxRef = useRef(null);
  const [retailerSearchWord, setRetailerSearchWord] = useState('');
  const [filteredRetailers, setFilteredRetailers] = useState(retailers);

  useEffect(() => {
    if (retailerSearchWord) {
      setFilteredRetailers(
        retailers.filter((retailer) =>
          retailer.name.toLowerCase().includes(retailerSearchWord.toLowerCase())
        )
      );
    } else {
      setFilteredRetailers(retailers);
    }
  }, [retailerSearchWord, retailers]);

  return (
    <Container>
      <PushContainer>
        {title && <Title>{title}</Title>}
        {showSearchBox && (
          <SearchBoxContainer>
            <SearchBox
              ref={retailerSearchBoxRef}
              type="text"
              placeholder={searchBoxPlaceholder}
              onChange={() => {
                setRetailerSearchWord(retailerSearchBoxRef.current.value);
              }}
            />
            <SearchIconStyled width="20px" height="20px" />
          </SearchBoxContainer>
        )}
        <RetailersListContainerStyled>
          {filteredRetailers && (
            <RetailersListContainer
              retailers={filteredRetailers}
              className="retailers_list"
              containerId={id}
            />
          )}
          {filteredRetailers.length === 0 && (
            <NoRetailersMessage>{noRetailersMessage}</NoRetailersMessage>
          )}
        </RetailersListContainerStyled>
        {cta && (
          <CtaContainer>
            <StyledButton>{cta.label}</StyledButton>
          </CtaContainer>
        )}
      </PushContainer>
    </Container>
  );
};

RetailersList.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  showSearchBox: PropTypes.bool.isRequired,
  searchBoxPlaceholder: PropTypes.string.isRequired,
  noRetailersMessage: PropTypes.string.isRequired,
  retailers: PropTypes.array,
  cta: PropTypes.object,
};

export default RetailersList;
