import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { getSlug, getPrefix } from '@utils';
import { SettingsContext } from '@stores/Settings';

export const LINK_TYPES = Object.freeze({
  internal: 'internal',
  external: 'external',
});

export const SETTINGS_INTERNAL_LINKS = Object.freeze([
  'http://clp',
  'http://articles',
  'http://hcp-articles',
]);

const Div = styled.div``;

const Link = React.memo(
  ({
    label,
    target = undefined,
    url,
    state,
    type,
    title,
    clickScript,
    className,
    activeClassName,
    isProduct,
    children = undefined,
  }) => {
    const {
      clpSlug,
      allArticlesPageSlug,
      allHcpPageSlug,
      localizedPageData,
      locale: currentLocale,
    } = useContext(SettingsContext);
    const localePrefix = getPrefix(localizedPageData, currentLocale);
    let navProps;
    if (type === LINK_TYPES.internal) {
      navProps = {
        to: getSlug(isProduct ? `${clpSlug}/${url}` : url, localePrefix),
        state: { state },
        as: GatsbyLink,
      };
    } else if (SETTINGS_INTERNAL_LINKS.includes(url)) {
      navProps = {
        to:
          // eslint-disable-next-line no-nested-ternary
          url === 'http://clp'
            ? getSlug(clpSlug, localePrefix)
            : url === 'http://hcp-articles'
            ? getSlug(allHcpPageSlug, localePrefix)
            : getSlug(allArticlesPageSlug, localePrefix),
        as: GatsbyLink,
      };
    } else {
      navProps = { href: url, as: 'a', rel: 'noopener noreferrer' };
    }

    const handleScript = (e) => {
      if (type === LINK_TYPES.internal) {
        e.preventDefault();
      }
      try {
        new Function(clickScript)();
      } catch (err) {
        console.log(err);
      }
    };

    const handleClick = (e) => {
      if (clickScript) {
        handleScript(e);
      }
    };

    return (
      <Div
        className={className}
        title={title}
        target={target || '_self'}
        {...navProps}
        activeClassName={activeClassName}
        onClick={handleClick}
      >
        {children || label}
      </Div>
    );
  }
);

export const linkPropTypes = {
  label: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  clickScript: PropTypes.string,
  isProduct: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Link.propTypes = {
  ...linkPropTypes,
  className: PropTypes.string,
};

export default Link;
