import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { grid } from '@styles/grid';
import { CardSquare } from '@molecules/Card';
import Accordion from '@atoms/Accordion';
import { buttonStyle } from '@atoms/Button';
import { useWindowSize } from '@hooks';

const CardsContainer = styled.ul`
  ${grid};
  grid-row-gap: var(--inner-gap);

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      --columns: ${({ isCalculator }) => (isCalculator ? '' : 18)};
      padding: 0
        ${({ isCalculator }) =>
          isCalculator ? 0 : 'calc(var(--outer-gap) + var(--col) * 3)'};
    `)}
`;

const CardsItem = styled.li`
  grid-column: 1 / -1;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: span 6;
    `)}
  `}
`;

const StyledAccordion = styled(Accordion)`
  display: flex;
  flex-direction: column;

  ${({ theme }) => css`
    > button {
      width: auto;
      align-self: center;
      margin-top: ${theme.spacing(4)};

      ${theme.mediaquery.sm(css`
        margin-top: ${theme.spacing(5)};
      `)}
    }
  `}
`;

const AccordionLabel = styled.p`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    display: inline-flex;
    text-align: center;
  `}
`;

const CardsAccordion = ({
  cards,
  hiddenLabel,
  visibleLabel,
  renderCard,
  cardsShown,
  maxHeight,
  isCalculator,
}) => {
  const containerRef = useRef(null);
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const [viewportWidth] = useWindowSize();

  const renderAccordion = cards.length > cardsShown;

  useEffect(() => {
    if (renderAccordion && containerRef !== null && containerRef.current) {
      const targetRow = containerRef.current.querySelector(
        `li:nth-of-type(${cardsShown})`
      );
      const height = targetRow.offsetHeight + targetRow.offsetTop;
      setCollapsedHeight(height);
    }
  }, [containerRef, viewportWidth, renderAccordion, cardsShown]);

  const Cards = () => (
    <CardsContainer ref={containerRef} isCalculator={isCalculator}>
      {cards.map((card) => (
        <CardsItem key={card.id}>{renderCard(card)}</CardsItem>
      ))}
    </CardsContainer>
  );

  return renderAccordion ? (
    <StyledAccordion
      hiddenLabel={<AccordionLabel>{hiddenLabel}</AccordionLabel>}
      visibleLabel={<AccordionLabel>{visibleLabel}</AccordionLabel>}
      labelOnTop={false}
      collapsedHeight={collapsedHeight}
      maxHeight={maxHeight}
    >
      <Cards />
    </StyledAccordion>
  ) : (
    <Cards />
  );
};

CardsAccordion.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(CardSquare.propTypes)).isRequired,
  hiddenLabel: PropTypes.string,
  visibleLabel: PropTypes.string,
  renderCard: PropTypes.func.isRequired,
  cardsShown: PropTypes.number.isRequired,
  maxHeight: PropTypes.string,
  isCalculator: PropTypes.bool,
};

export default CardsAccordion;
