import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useSwipeable } from 'react-swipeable';

import { rgba } from '@utils';
import { SettingsContext } from '@stores/Settings';
import Img, { IMG_TYPES } from '@atoms/Img';
import Link, { LINK_TYPES } from '@atoms/Link';
import { buttonStyle } from '@atoms/Button';
import Dots from '@atoms/Dots';
import NavigationButton, { NAVIGATION_TYPES } from '@atoms/NavigationButton';

const MAX_ZINDEX = 3;
const ITEM_SIZE = 350;

const Container = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    margin-top: ${theme.spacing(10)};
  `}
`;

const CarouselContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90%;
    pointer-events: none;
    background: ${({ theme }) => `linear-gradient(
      to right,
      ${theme.colors.black} 0%,
      ${theme.colors.black} 10%,
      ${rgba(theme.colors.black, 0)} 50%,
      ${theme.colors.black} 90%,
      ${theme.colors.black} 100%
    )`};
    z-index: 2;
  }
`;

const Carousel = styled.ul`
  position: relative;
  width: ${ITEM_SIZE}px;
  height: ${ITEM_SIZE}px;
`;

const CarouselItem = styled.li`
  ${({ indexFromActive }) => css`
    position: absolute;
    width: 100%;
    transition: transform 600ms cubic-bezier(0.3, 0.8, 0.5, 1),
      opacity 600ms cubic-bezier(0.3, 0.8, 0.5, 1);
    opacity: ${indexFromActive > -3 && indexFromActive < 3 ? 1 : 0};
    z-index: ${Math.max(0, MAX_ZINDEX - Math.abs(indexFromActive))};
    transform: translateX(calc(-50% * ${indexFromActive}))
      scale(${Math.max(0, 1 - Math.abs(indexFromActive) / 5)});
  `}
`;

const navigationButtonStyle = css`
  ${({ theme }) => css`
    position: absolute;
    top: ${ITEM_SIZE / 2}px;
    transform: translateY(-50%);

    z-index: 4;

    ${theme.mediaquery.sm(css`
      display: flex;
    `)}
  `}
`;

const PrevButton = styled(NavigationButton)`
  ${navigationButtonStyle};
  left: var(--outer-gap);
  ${({ theme }) =>
    theme.mediaquery.md(css`
      left: calc(var(--outer-gap) + var(--col) * 3);
    `)}
`;

const NextButton = styled(NavigationButton)`
  ${navigationButtonStyle};
  right: var(--outer-gap);
  ${({ theme }) =>
    theme.mediaquery.md(css`
      right: calc(var(--outer-gap) + var(--col) * 3);
    `)}
`;

const StyledDots = styled(Dots)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(5)};
    `)}
  `}
`;

const Infos = styled.div`
  ${({ theme, color }) => css`
    --product-color: ${color};
    padding: 0 var(--outer-gap);
    margin-top: ${theme.spacing(3)};
    text-align: center;

    ${theme.mediaquery.sm(css`
      width: calc(var(--col) * 8 - var(--inner-gap));
      margin: 0 auto;
      margin-top: ${theme.spacing(5)};
    `)}
  `}
`;

const infosTextStyle = css`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};

    span {
      color: var(--product-color);
      text-transform: initial;
    }
  `}
`;

const Protein = styled.p`
  ${infosTextStyle};
`;

const Title = styled.p`
  ${infosTextStyle};
  margin-top: ${({ theme }) => theme.spacing(4)};

  span {
    display: block;
    text-transform: uppercase;
  }
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    display: inline-flex;
    margin-top: ${theme.spacing(5)};
  `}
`;

const CLPCarousel = ({ category, products, ctaLabel }) => {
  const { translations } = useContext(SettingsContext);
  const sortedProducts = useMemo(
    () =>
      [...products].sort((productA, productB) => {
        // return -1 = productA is lower priority
        // return 1 = productB is lower priority
        if (productA.priority !== productB.priority) {
          return productA.priority > productB.priority ? 1 : -1;
        }
        return productA.flavour > productB.flavour ? 1 : -1;
      }),
    [products]
  );

  const [currentIndex, setCurrentIndex] = useState(
    Math.floor(sortedProducts.length / 2)
  );
  const [currentProduct, setCurrentProduct] = useState(
    sortedProducts[currentIndex]
  );

  const slide = (newIndex) => {
    if (newIndex >= 0 && newIndex <= sortedProducts.length - 1) {
      setCurrentIndex(newIndex);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      slide(currentIndex + 1);
    },
    onSwipedRight: () => {
      slide(currentIndex - 1);
    },
  });

  useEffect(() => {
    setCurrentProduct(sortedProducts[currentIndex]);
  }, [currentIndex, sortedProducts]);

  const handleInactiveProductClick = useCallback(
    (e, idx) => {
      if (idx !== currentIndex) {
        e.stopPropagation();
        setCurrentIndex(idx);
      }
    },
    [currentIndex]
  );

  return (
    <Container {...swipeHandlers}>
      <CarouselContainer>
        <Carousel>
          {sortedProducts.map(({ id, image }, index) => (
            <CarouselItem
              key={id}
              indexFromActive={currentIndex - index}
              onClick={(e) => handleInactiveProductClick(e, index)}
            >
              {(index === currentIndex && (
                <Link
                  url={`${category.slug}/${currentProduct.slug}`}
                  title={ctaLabel}
                  label={ctaLabel}
                  type={LINK_TYPES.internal}
                  isProduct
                >
                  <Img small={image} type={IMG_TYPES.fixed} />
                </Link>
              )) || <Img small={image} type={IMG_TYPES.fixed} />}
            </CarouselItem>
          ))}
        </Carousel>
        {currentIndex > 0 && (
          <PrevButton onClick={() => slide(currentIndex - 1)} />
        )}
        {currentIndex < sortedProducts.length - 1 && (
          <NextButton
            direction={NAVIGATION_TYPES.right}
            onClick={() => slide(currentIndex + 1)}
          />
        )}
        <StyledDots
          items={sortedProducts.map(({ id, color, flavour }) => ({
            label: flavour,
            id,
            color,
            active: currentProduct.id === id,
          }))}
          onSelect={(_, key) => {
            setCurrentIndex(key);
          }}
        />
      </CarouselContainer>
      <Infos color={currentProduct.color}>
        <Protein>
          <span>{currentProduct.proteinGramage}</span>{' '}
          {translations.proteinGramageLabel}
        </Protein>
        <Title>
          <span>{currentProduct.flavour}</span>
          {category.name}
        </Title>
        <StyledLink
          url={`${category.slug}/${currentProduct.slug}`}
          title={ctaLabel}
          label={ctaLabel}
          type={LINK_TYPES.internal}
          isProduct
        />
      </Infos>
    </Container>
  );
};

CLPCarousel.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      flavour: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      proteinGramage: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
      priority: PropTypes.number.isRequired,
    })
  ).isRequired,
  ctaLabel: PropTypes.string.isRequired,
};

export default React.memo(CLPCarousel);
