import { IMG_TYPES } from '@atoms/Img';
import placeholder from '@atoms/Img/Placeholder';
import { CARD_TYPES } from '@molecules/Card';

const mock = [
  {
    background: placeholder(2000, 2000, IMG_TYPES.fluid),
    type: CARD_TYPES.video,
    title: 'Ut ea ad esse nostrud pariatur.',
    influencer: 'influencer name',
    id: 'first',
    categoryType: 'Nutrition',
    slug: '#',
  },
  {
    background: placeholder(2000, 2000, IMG_TYPES.fluid),
    type: CARD_TYPES.video,
    title: 'Ut ea ad esse nostrud pariatur.',
    id: 'second',
    categoryType: 'Workout',
    slug: '#',
  },
  {
    background: placeholder(2000, 2000, IMG_TYPES.fluid),
    type: CARD_TYPES.editorial,
    title: 'Ut ea ad esse nostrud pariatur.',
    influencer: 'influencer name',
    id: 'third',
    categoryType: 'Wellness',
    slug: '#',
  },
  {
    background: placeholder(2000, 2000, IMG_TYPES.fluid),
    type: CARD_TYPES.influencer,
    title: 'Ut ea ad esse nostrud pariatur.',
    id: 'fourth',
    categoryType: 'Nutrition',
    slug: '#',
  },
  {
    background: placeholder(2000, 2000, IMG_TYPES.fluid),
    type: CARD_TYPES.recipe,
    title: 'Ut ea ad esse nostrud pariatur.',
    id: 'fifth',
    categoryType: 'Workout',
    slug: '#',
  },
];

export default mock;
