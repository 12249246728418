import Food from '@icons/cardfood.svg';
import Play from '@icons/cardplay.svg';
import Lightning from '@icons/cardlightning.svg';
import Read from '@icons/read.svg';

export const CARD_TYPES = Object.freeze({
  video: 'video',
  influencer: 'influencer',
  recipe: 'recipe',
  editorial: 'editorial',
});

export const cardIcons = Object.freeze({
  [CARD_TYPES.video]: Play,
  [CARD_TYPES.influencer]: Lightning,
  [CARD_TYPES.recipe]: Food,
  [CARD_TYPES.editorial]: Read,
});
