import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import Article from '@organisms/Article';
import { adapter as cardAdapter } from '@molecules/Card';
import { getSettings, getPageMeta } from '@utils';
import trackEvent from './trackEvent';

const ArticlePage = ({ data, pageContext, path }) => {
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulSettings;
  const pageSeo = getPageMeta(page.allArticlesPageSeo, settings.brandName);

  const cards = data.allContentfulContentPage.edges.map(({ node }) =>
    cardAdapter(node)
  );

  const highlightedCards = data.allContentfulSettings.nodes[0].allArticlesPageHighlightedCards.map(
    (card) => cardAdapter(card)
  );

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <Article cards={cards} highlightedCards={highlightedCards} />
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getArticlePageData($nodeLocale: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    allContentfulContentPage(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          ...Card
        }
      }
    }

    allContentfulSettings(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        allArticlesPageHighlightedCards {
          ...Card
        }
        allArticlesPageTitle
        allArticlesPageSlug
        allArticlesPageSeo {
          ...Metadata
        }
      }
    }
  }
`;

ArticlePage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default ArticlePage;
