import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({ productCategories, storeLink }) => ({
  productCategories: productCategories.map(({ id, slug, name, products }) => ({
    id,
    category: {
      name,
      slug,
    },
    products: products.map(
      ({
        productId,
        flavour,
        color,
        productPicture,
        productSlug,
        priority,
      }) => ({
        id: productId,
        flavour,
        color,
        priority: priority || 0,
        slug: productSlug,
        image: getImageFields(productPicture, IMG_TYPES.fixed),
      })
    ),
  })),
  storeLink,
});
