import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { adapter as influencerAdapter } from '@molecules/InfluencerTagline';

export default ({
  id,
  title,
  copyText,
  ctaText,
  productFlavour,
  influencer,
  breakLines,
}) => ({
  id,
  title,
  copyText,
  influencer: influencerAdapter(influencer),
  breakLines,
  ctaText,
  product: productFlavour && {
    image: getImageFields(productFlavour.productPicture, IMG_TYPES.fluid),
    slug: `${productFlavour.productCategory.slug}/${productFlavour.slug}`,
    productColor: productFlavour.productColor,
  },
});
