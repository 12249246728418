import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CardPortrait } from '@molecules/Card';
import Carousel from '@molecules/Carousel';
import SectionTitle from '@atoms/SectionTitle';
import { SwiperSlide } from 'swiper/react';
import { breakpoints } from '@utils';

const SectionSubTitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    width: 100%;
    padding-left: var(--outer-gap);
    position: relative;
    margin-bottom: ${theme.spacing(4)};

    ${theme.mediaquery.sm(
      css`
        padding-left: calc(var(--outer-gap) + var(--col) * 2);
      `
    )}

    ${theme.mediaquery.md(
      css`
        padding-left: calc(var(--outer-gap) + var(--col) * 3);
        max-width: 30%;
        box-sizing: content-box;
      `
    )}
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    overflow: hidden;

    .swiper-title {
      text-align: left;
      padding-left: var(--outer-gap);
      ${({ biggerCards, biggerCardsTitle }) =>
        biggerCards || biggerCardsTitle
          ? theme.typography.titleXL
          : theme.typography.titleM};

      ::before {
        display: none;
      }

      ${theme.mediaquery.sm(
        css`
          padding-left: calc(var(--outer-gap) + var(--col) * 2);
          ${({ biggerCards }) =>
            biggerCards ? theme.typography.titleXL : theme.typography.titleL};
        `
      )}

      ${theme.mediaquery.md(
        css`
          padding-left: calc(var(--outer-gap) + var(--col) * 3);
          max-width: 50%;
          box-sizing: content-box;
        `
      )}
    }

    .swiper-slide {
      ${({ biggerCards }) =>
        biggerCards
          ? css`
              width: calc(var(--col) * 4 - var(--inner-gap));

              ${theme.mediaquery.sm(
                css`
                  width: calc(var(--col) * 14 - var(--inner-gap));
                  &:not(:last-child) {
                    margin-right: calc(var(--inner-gap) + var(--col));
                  }
                `
              )}

              ${theme.mediaquery.md(
                css`
                  width: calc(var(--col) * 8 - var(--inner-gap));
                `
              )}
            `
          : css`
              width: calc(var(--col) * 3 - var(--inner-gap));

              ${theme.mediaquery.sm(
                css`
                  width: calc(var(--col) * 9 - var(--inner-gap));
                `
              )}

              ${theme.mediaquery.md(
                css`
                  width: calc(var(--col) * 6 - var(--inner-gap));
                `
              )}
            `}
    }
  `}
`;

const RelatedCardsCarousel = ({
  id,
  title,
  subtitle,
  cards,
  biggerCards = false,
  biggerCardsTitle = false,
  className,
}) => {
  const imgSizes = biggerCards
    ? `(min-width: ${breakpoints.sm}px) 49vw, (min-width: ${breakpoints.md}px) 27vw, 69vw`
    : `(min-width: ${breakpoints.sm}px) 29vw, (min-width: ${breakpoints.md}px) 19vw, 47vw`;

  return (
    <Container
      className={className}
      biggerCards={biggerCards}
      biggerCardsTitle={biggerCardsTitle}
    >
      {!!title && <SectionTitle title={title} className="swiper-title" />}
      {!!subtitle && <SectionSubTitle>{subtitle}</SectionSubTitle>}
      <Carousel id={id} customSpacing={biggerCards}>
        {cards.map((card) => (
          <SwiperSlide key={card.id} className="swiper-slide">
            <CardPortrait
              {...{
                ...card,
                background: {
                  ...card.background,
                  sizes: imgSizes,
                },
              }}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  );
};

RelatedCardsCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(CardPortrait.propTypes)),
  biggerCards: PropTypes.bool,
  biggerCardsTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default RelatedCardsCarousel;
