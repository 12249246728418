import React from 'react';
import useScript from 'react-script-hook';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { meetingDoctorsInitScript } from './meetingDoctorsScripts';

const Scripts = ({ useProduction }) => {
  const market = process.env.GATSBY_MARKET;

  const data = useStaticQuery(graphql`
    query {
      allContentfulSettings {
        nodes {
          prod: scriptsProduction {
            scripts: scriptsProduction
          }
          stg: scriptsProduction {
            scripts: scriptsProduction
          }
          node_locale
          metadata {
            tags {
              contentful_id
            }
          }
        }
      }
    }
  `);
  const marketScripts = data.allContentfulSettings.nodes.find(
    (m) => m.metadata.tags[0].contentful_id === market
  );

  const { prod, stg } = marketScripts;

  const scripts = useProduction ? prod && prod.scripts : stg && stg.scripts;

  const scriptsArray =
    scripts &&
    scripts
      .replace(/https:/, '')
      .replace(/http:/, '')
      .split('\n');

  const meetingDocsScript = scriptsArray.filter((e) =>
    e.toLowerCase().includes('meetingdoctors')
  )[0];

  if (meetingDocsScript) {
    scriptsArray.pop(scriptsArray.indexOf(meetingDocsScript));
  }

  useScript({
    src: meetingDocsScript,
    checkForExisting: true,
    onload: () => (meetingDocsScript ? meetingDoctorsInitScript() : null),
  });

  return (
    <>
      {scriptsArray && scriptsArray.length && (
        <Helmet>
          {scriptsArray.map((script) => (
            <script key={script} type="text/javascript" src={script} defer />
          ))}
        </Helmet>
      )}
      {meetingDocsScript ? (
        <>
          <link
            rel="stylesheet"
            href="https://use.fontawesome.com/releases/v5.8.2/css/all.css"
            integrity="sha384-oS3vJWv+0UjzBfQzYUhtDYW+Pj2yciDJxpsK1OYPAYjqT085Qq/1cq5FLXAZQ7Ay"
            crossOrigin="anonymous"
          />
          <link
            href="https://sdk.meetingdoctors.com/static/css/main.css"
            rel="stylesheet"
            defer
          />
          <link
            href="https://sdk.meetingdoctors.com/brands/danone/theme.css"
            rel="stylesheet"
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

Scripts.propTypes = {
  useProduction: PropTypes.bool,
};

Scripts.defaultProps = {
  useProduction: false,
};

export default Scripts;
