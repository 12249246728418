import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ChevronLeft from '@icons/chevron-left.svg';
import ChevronRight from '@icons/chevron-right.svg';

export const NAVIGATION_TYPES = Object.freeze({
  left: 'left',
  right: 'right',
});

const buttonIcons = Object.freeze({
  [NAVIGATION_TYPES.left]: ChevronLeft,
  [NAVIGATION_TYPES.right]: ChevronRight,
});

const Outer = styled.button`
  ${({ theme, squaredCorner }) => css`
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.white};
    border-radius: ${theme.spacing(2)};
    border-top-left-radius: ${squaredCorner ? 0 : theme.spacing(2)};
    cursor: pointer;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: ${theme.colors.hoverGray};
    }

    &:active {
      background-color: ${theme.colors.pressedGray};
    }

    path {
      fill: ${theme.colors.black};
    }
  `}
`;

const Button = React.memo(({ direction, squaredCorner, ...rest }) => {
  const Icon = buttonIcons[direction];
  return (
    <Outer squaredCorner={squaredCorner} {...rest}>
      <Icon />
    </Outer>
  );
});

Button.propTypes = {
  direction: PropTypes.oneOf(Object.values(NAVIGATION_TYPES)),
  squaredCorner: PropTypes.bool,
};

Button.defaultProps = {
  direction: NAVIGATION_TYPES.left,
  squaredCorner: false,
};

export default Button;
