import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { adapter as VideoAdapter } from '@molecules/VideoPush';
import { adapter as linkAdapter } from '@atoms/Link';
import { adapter as influencerAdapter } from '@molecules/InfluencerTagline';
import { VIDEO_MODE } from '@molecules/Hero';

export default ({
  headline,
  headlinePositionAndAlignment,
  tagline,
  imageMobile,
  imageDesktop,
  video,
  videoMode,
  videoCtaLabel,
  layout,
  cta,
  influencer,
}) => ({
  layout,
  headline,
  tagline,
  alignment: headlinePositionAndAlignment,
  image: imageMobile &&
    imageDesktop && {
      small: getImageFields(imageMobile, IMG_TYPES.fluid),
      large: getImageFields(imageDesktop, IMG_TYPES.fluid),
    },
  imageMobileRatio: imageMobile?.fluid?.aspectRatio,
  imageDesktopRatio: imageDesktop?.fluid?.aspectRatio,
  video: video && VideoAdapter(video),
  videoMode: !videoCtaLabel ? VIDEO_MODE.bg : videoMode,
  videoCtaLabel,
  cta: cta && linkAdapter(cta),
  influencer: influencer && influencerAdapter(influencer),
});
