import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { buttonStyle } from '@atoms/Button';

const Outer = styled.div`
  ${({ active, theme, lightTheme }) => css`
    ${active
      ? buttonStyle(theme, lightTheme ? 'secondary' : 'primary', lightTheme)
      : buttonStyle(theme, 'secondary')};
    border-radius: ${theme.spacing(3)};
    padding: ${theme.spacing(1.3)} ${theme.spacing(3)};
  `}
`;

const Chip = React.memo(({ text, active, lightTheme = false, ...rest }) => (
  <Outer {...rest} active={active} lightTheme={lightTheme}>
    {text}
  </Outer>
));

Chip.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.node.isRequired,
  lightTheme: PropTypes.bool,
};

export default Chip;
