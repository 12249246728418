import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import VideoPush from './VideoPush';

const LANDSCAPE = (9 / 16) * 100;
const PORTRAIT = (16 / 9) * 100;
const HEIGHTMAX = '50vmin';

const Container = styled.div`
  padding: 0 var(--outer-gap);

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + var(--col));

      width: 100%;
      height: ${({ isPortrait }) =>
        isPortrait.desktopSrc ? HEIGHTMAX : 'auto'};
    `)}
  `}
`;

const StyledVideoPush = styled(VideoPush)`
  position: relative;
  margin: 0 auto;

  padding-top: ${({ isPortrait }) =>
    isPortrait.mobileSrc ? `${PORTRAIT}%` : `${LANDSCAPE}%`};

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      ${({ isPortrait }) =>
        isPortrait.desktopSrc
          ? css`
              width: calc(${HEIGHTMAX} * 9 / 16);
              height: 100%;
              padding-top: 0;
            `
          : css`
              width: 100%;
              height: 0;
              padding-top: ${LANDSCAPE}%;
            `};
    `)};
  `}
`;

const PageComponent = (props) => {
  const {
    cloudinaryVideo: { width: desktopSrcWidth, height: desktopSrcHeight },
    mobileCloudinaryVideo: { width: mobileSrcWidth, height: mobileSrcHeight },
  } = props;

  const isPortrait = useMemo(
    () => ({
      desktopSrc: desktopSrcWidth < desktopSrcHeight,
      mobileSrc: mobileSrcWidth < mobileSrcHeight,
    }),
    [desktopSrcWidth, desktopSrcHeight, mobileSrcWidth, mobileSrcHeight]
  );

  return (
    <Container isPortrait={isPortrait}>
      <StyledVideoPush isPortrait={isPortrait} {...props} />
    </Container>
  );
};

PageComponent.propTypes = VideoPush.propTypes;

export default PageComponent;
