import React, { useState, useContext, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { SettingsContext } from '@stores/Settings';
import { useWindowSize } from '@hooks';
import Dropdown from '@atoms/Dropdown';
import Table from '@atoms/Table';
import Accordion from '@atoms/Accordion';
import Plus from '@icons/plus.svg';
import Minus from '@icons/minus.svg';

const NutritionalTableContainer = styled.div`
  ${({ theme }) => css`
    padding: 0 var(--outer-gap);
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      padding: 0;
      margin-left: auto;
      margin-right: auto;
      width: calc(var(--col) * 8 - var(--inner-gap));
    `)}

    ${theme.mediaquery.md(css`
      width: calc(var(--col) * 6 - var(--inner-gap));
    `)}
  `}
`;

const Title = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    color: ${theme.colors.white};
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      transform: translateY(-2px);
      margin-left: ${theme.spacing(1)};

      path {
        /* To show the correct product color as the indicator product, you first need to make sure that any
      parent of this component defines the --product-color variable. */
        fill: var(--product-color, ${theme.colors.white});
      }
    }

    ${theme.mediaquery.sm(css`
      justify-content: center;
    `)}
  `}
`;

const Legal = styled.p`
  ${({ theme }) => css`
    ${theme.typography.footnote};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(2)};
  `}
`;

const MainTitle = styled(Title)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const NutritionalTable = ({
  rows,
  legal,
  servingSize,
  className,
  labelOnTop,
  visibleRows = 0,
  ...rest
}) => {
  const tableRef = useRef(null);
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const [viewportWidth] = useWindowSize();
  const { translations } = useContext(SettingsContext);

  const per100Table = useMemo(
    () =>
      rows
        .map(({ name, per100 }) => ({
          name,
          value: per100,
        }))
        .filter((v) => v.value),
    [rows]
  );
  const perServingTable = useMemo(
    () =>
      rows
        .map(({ name, perServing }) => ({
          name,
          value: perServing,
        }))
        .filter((v) => v.value),
    [rows]
  );
  const rdaTable = useMemo(
    () =>
      rows
        .map(({ name, rda }) => ({
          name,
          value: rda,
        }))
        .filter((v) => v.value),
    [rows]
  );

  const dropdownOptions = [
    [...perServingTable],
    [...rdaTable],
    [...perServingTable],
  ].filter((ar) => ar.length);

  const [column, setColumn] = useState(
    (per100Table.length && 'per100Table') ||
      (perServingTable.length && 'perServingTable') ||
      (rdaTable.length && 'rdaTable')
  );

  const cleanValues = useMemo(() => {
    return { per100Table, perServingTable, rdaTable };
  }, [per100Table, perServingTable, rdaTable]);

  const updateColumn = (e) => {
    setColumn(e.target.value);
  };

  useEffect(() => {
    if (visibleRows > 0 && tableRef !== null && tableRef.current) {
      const targetRow = tableRef.current.querySelector(
        `tbody tr:nth-of-type(${visibleRows})`
      );
      const height = targetRow.offsetHeight + targetRow.offsetTop;
      setCollapsedHeight(height);
    }
  }, [tableRef, visibleRows, viewportWidth]);

  return (
    <NutritionalTableContainer className={className}>
      {!labelOnTop && (
        <MainTitle>{translations.nutritionalFactsLabel}</MainTitle>
      )}
      <Accordion
        hiddenLabel={
          <Title>
            {translations.expandNutritionalFactsLabel}
            <Plus />
          </Title>
        }
        visibleLabel={
          <Title>
            {translations.collapseNutritionalFactsLabel}
            <Minus />
          </Title>
        }
        labelOnTop={labelOnTop}
        collapsedHeight={collapsedHeight}
      >
        <Table
          ref={tableRef}
          {...rest}
          headers={[
            translations.nutritionalInformationsLabel,
            dropdownOptions.length > 1 ? (
              <Dropdown
                onChange={updateColumn}
                visible={dropdownOptions.length > 1}
              >
                {per100Table.length && (
                  <option value="per100Table">
                    {translations.per100gLabel}
                  </option>
                )}
                {perServingTable.length && (
                  <option value="perServingTable">
                    {`${translations.perServingLabel} (${servingSize})`}
                  </option>
                )}
                {rdaTable.length && (
                  <option value="rdaTable">{translations.rdaLabel}</option>
                )}
              </Dropdown>
            ) : (
              <>
                <span>
                  {(per100Table.length !== 0 && translations.per100gLabel) || 
                  (perServingTable.length !== 0 && translations.perServingLabel) || 
                  (rdaTable.length !== 0 && translations.rdaLabel)}
                </span>
              </> 
            ),
          ]}
          values={(cleanValues[column] || []).map((row) => [
            row.name,
            row.value,
          ])}
        />
        <Legal>{legal}</Legal>
      </Accordion>
    </NutritionalTableContainer>
  );
};

NutritionalTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      per100: PropTypes.string,
      perServing: PropTypes.string,
      rda: PropTypes.string,
    })
  ).isRequired,
  legal: PropTypes.string.isRequired,
  servingSize: PropTypes.string.isRequired,
  className: PropTypes.string,
  labelOnTop: PropTypes.bool,
  visibleRows: PropTypes.number,
};

export default NutritionalTable;
