import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Outer = styled.div`
  display: flex;
`;
const ClickableDot = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 6px;

  &:last-of-type {
    margin-right: 0;
  }
`;
const Dot = styled.div`
  ${({ theme, color }) => css`
    text-indent: -10000px;

    &.carousel-dot--active {
      background-color: ${color || theme.colors.white};
    }
  `}
`;

const Dots = React.memo(({ className, items, onSelect }) => (
  <Outer className={className}>
    {items.map((item, key) => (
      <ClickableDot
        key={item.id}
        onClick={(e) => {
          e.preventDefault();
          onSelect(item, key);
        }}
        href={item.slug}
      >
        <Dot
          className={`carousel-dot ${
            (item || {}).active ? 'carousel-dot--active' : ''
          }`}
          color={(item || {}).color}
        >
          {item.label}
        </Dot>
      </ClickableDot>
    ))}
  </Outer>
));

Dots.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
      active: PropTypes.bool.isRequired,
    })
  ),
  onSelect: PropTypes.func,
};

export default Dots;
