import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from '@styles/GlobalStyles';
import theme from '@styles/theme';

import SettingsProvider, { SettingsShape } from './Settings';
import HeroProvider from './Hero';

const GlobalProvider = ({ children, settings }) => (
  <SettingsProvider value={settings}>
    <ThemeProvider theme={theme}>
      <HeroProvider>
        <GlobalStyles />
        {children}
      </HeroProvider>
    </ThemeProvider>
  </SettingsProvider>
);

GlobalProvider.propTypes = {
  settings: PropTypes.shape(SettingsShape).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default GlobalProvider;
