import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import PromotionalOverlay from '@organisms/PromotionalOverlay';
import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import { getSettings, getPageMeta } from '@utils';
import CLPCategory, {
  adapter as clpCategoryAdapter,
} from '@organisms/CLPCategory';
import trackEvent from './trackEvent';

const Wrapper = styled.div`
  padding-top: 100px;
`;

const CLPPage = ({ data, pageContext, path }) => {
  // Grab settings from the data, which is the result from the gql query below
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const pageSeo = getPageMeta(
    data.contentfulSettings.clpSeo,
    settings.brandName
  );
  const promotionalOverlayProps = settings?.promotionalOverlay;
  const categories = data.contentfulSettings.clpCategories;

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageContext.domain, pageSeo.title, path]);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <Wrapper>
        {categories.map((category, index) => (
          <CLPCategory
            index={index}
            key={category.id}
            {...clpCategoryAdapter(category)}
          />
        ))}
      </Wrapper>
      {!!promotionalOverlayProps && (
        <PromotionalOverlay {...promotionalOverlayProps} />
      )}
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getCLPPageData($nodeLocale: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }
  }
`;

CLPPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default CLPPage;
