import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default (influencer) => {
  return influencer
    ? {
        picture: getImageFields(
          influencer.taglinePicture || influencer.profilePictureDesktop,
          IMG_TYPES.fluid
        ),
        categories:
          (influencer.categories &&
            influencer.categories.map(({ name }) => name).join(' & ')) ||
          '',
        name: influencer.shortName || influencer.name,
      }
    : null;
};
