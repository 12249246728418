import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Img, { IMG_TYPES, IMG_FITS } from '@atoms/Img';
import { rgba } from '@utils';

const TribeCardContainer = styled.button`
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  display: grid;
  position: relative;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme, inactive }) =>
      rgba(inactive ? theme.colors.white : theme.colors.black, 0.4)};
    z-index: ${({ inactive }) => (inactive ? '2' : 'initial')};
  }
`;

const ImgContainer = styled.div`
  position: relative;
  height: 0;
  padding-top: calc(840 / 632 * 100%);
  grid-row: 1;
  grid-column: 1;
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
`;

const Name = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    color: ${theme.colors.white};
    grid-row: 1;
    grid-column: 1;
    z-index: 1;
    align-self: end;
    padding: ${theme.spacing(2)} ${theme.spacing(1)};
  `}
`;

const TribeCard = ({ name, image, inactive = false, onClick }) => (
  <TribeCardContainer onClick={onClick} inactive={inactive}>
    <ImgContainer>
      <StyledImg
        small={image}
        type={IMG_TYPES.fluid}
        fit={IMG_FITS.cover}
        alt={name}
      />
    </ImgContainer>
    <Name>{name}</Name>
  </TribeCardContainer>
);

TribeCard.propTypes = {
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  inactive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default TribeCard;
