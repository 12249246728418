import React, { useContext, useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SettingsContext } from '@stores/Settings';
import { grid } from '@styles/grid';
import { breakpoints } from '@utils';
import { useWindowSize } from '@hooks';
import { CardHCP } from '@molecules/Card';

const MENU_ANIMATION_DURATION = 750;
const BEZIER = 'cubic-bezier(0.785, 0.135, 0.15, 0.86)';

const regularSizes = `(min-width: ${breakpoints.xs}px) 158px, (min-width: ${breakpoints.sm}px) 21vw, (min-width: ${breakpoints.md}px) 16vw, 87vw`;
const highlightedSizes = `(min-width: ${breakpoints.xs}px) 340px, (min-width: ${breakpoints.sm}px) 44vw, (min-width: ${breakpoints.md}px) 34vw, 87vw`;

const TopContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(20)} ${theme.spacing(5)} 0 0;
  `}
`;

const TitleWrapper = styled.div`
  ${grid};
`;

const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    color: ${theme.colors.white};
    grid-column: 1 / -1;

    ${theme.mediaquery.sm(css`
      text-align: center;
      grid-column: 7 / span 12;
    `)}
  `}
`;

const GridContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const Grid = styled.ul`
  ${({ theme }) => css`
    ${grid};
    grid-row-gap: var(--inner-gap);
    max-width: calc(340px + var(--inner-gap) * 2);
    margin: var(--inner-gap) auto;
    transition: opacity ${MENU_ANIMATION_DURATION}ms ${BEZIER};

    ${theme.mediaquery.sm(css`
      --columns: 8;
      padding: 0 calc(var(--outer-gap) + var(--col) * 6);
      max-width: none;
    `)}

    ${theme.mediaquery.md(css`
      --columns: 12;
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
    `)}
  `}
`;

const Item = styled.li`
  grid-column: 1 / -1;

  ${({ theme, highlighted }) => css`
    ${theme.mediaquery.xs(css`
      grid-column: ${!highlighted && 'span 2'};
    `)}

    ${theme.mediaquery.sm(css`
      grid-column: ${!highlighted && 'span 4'};
    `)}

  ${theme.mediaquery.md(css`
      grid-column: ${highlighted ? 'span 6' : 'span 3'};
      grid-row: ${highlighted ? 'span 2' : 'initial'};
    `)}
  `}
`;

// Hardcoded positions of Highlighted cards in the grid (UX design)
const HIGHLIGHTED_CARD_POSITION_DESKTOP = [1, 8, 12, 16, 23];
const HIGHLIGHTED_CARD_POSITION_MOBILE = [3, 8, 13, 18, 23];

const Article = ({ cards, highlightedCards }) => {
  const windowSizes = useWindowSize();
  const [highlightedPositions, setHighlightedPositions] = useState(
    HIGHLIGHTED_CARD_POSITION_MOBILE
  );
  // updates on first render. We need to check the window on client
  useEffect(() => {
    setHighlightedPositions(
      windowSizes[0] <= breakpoints.sm
        ? HIGHLIGHTED_CARD_POSITION_MOBILE
        : HIGHLIGHTED_CARD_POSITION_DESKTOP
    );
  }, [windowSizes]);

  const { allHcpPageTitle } = useContext(SettingsContext);

  // Determine which cards are the highlighted ones in the array of cards
  const highlightedIds = highlightedCards && highlightedCards.map((c) => c.id);
  const cleanCards =
    cards && cards.filter((card) => !highlightedIds.includes(card?.id));

  const shuffledCards = useMemo(() => {
    highlightedCards.map((item, index) => {
      const position = highlightedPositions[index] - 1;

      return cleanCards.splice(position, 0, item);
    });
    return cleanCards;
  }, [cleanCards, highlightedCards, highlightedPositions]);

  // divides the cards in chunks to display in rows
  const visibleCards = useMemo(
    () => [...shuffledCards].filter((card) => card),
    [shuffledCards]
  );

  return (
    <>
      <TopContainer>
        <TitleWrapper>
          <Title>{allHcpPageTitle}</Title>
        </TitleWrapper>
      </TopContainer>
      <GridContainer>
        <Grid>
          {visibleCards.length &&
            visibleCards.map((card, index) => {
              const isHighlighted = highlightedPositions.includes(index + 1);
              return (
                <Item key={card.id} highlighted={isHighlighted}>
                  <CardHCP
                    {...{
                      ...card,
                      background: {
                        ...card.background,
                        sizes: isHighlighted ? highlightedSizes : regularSizes,
                      },
                    }}
                    highlighted={isHighlighted}
                  />
                </Item>
              );
            })}
        </Grid>
      </GridContainer>
    </>
  );
};

Article.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(CardHCP.propTypes)),
  highlightedCards: PropTypes.arrayOf(PropTypes.shape(CardHCP.propTypes)),
};

export default Article;
