import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import Close from '@icons/close.svg';
import { rgba, colors } from '@utils';

if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#___gatsby');
}
ReactModal.defaultStyles.overlay.background = rgba(colors.black, 0.75);
ReactModal.defaultStyles.content.background = colors.black;
ReactModal.defaultStyles.content.border = 'none';
ReactModal.defaultStyles.content.display = 'flex';
ReactModal.defaultStyles.content.justifyContent = 'center';

const CloseButton = styled.button`
  ${({ theme, isDisclaimer }) => css`
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    background: ${theme.colors.white};
    border-radius: 50%;
    display: ${isDisclaimer ? 'none' : 'flex'};
    justify-content: center;
    align-items: center;
    position: absolute;
    cursor: pointer;
    top: ${theme.spacing(3)};
    right: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      top: ${theme.spacing(5)};
      right: ${theme.spacing(5)};
    `)};

    path {
      fill: ${theme.colors.black};
    }
  `}
`;

const Modal = ({ isOpen, onClose, children, isDisclaimer = false }) => {
  const scrollY = useRef(0);

  useEffect(() => {
    if (isOpen) {
      scrollY.current = window.scrollY;
      document.body.style.position = 'fixed';
      document.body.style.top = `-${scrollY.current}px`;
    }
  }, [isOpen]);

  const onAfterClose = () => {
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, scrollY.current);
  };

  return (
    <ReactModal
      closeTimeoutMS={300}
      onRequestClose={onClose}
      isOpen={isOpen}
      onAfterClose={onAfterClose}
    >
      {children}
      <CloseButton onClick={onClose} isDisclaimer={isDisclaimer}>
        <Close
          css={css`
            width: 24px;
            height: 24px;
          `}
        />
      </CloseButton>
    </ReactModal>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isDisclaimer: PropTypes.bool,
};

export default Modal;
