// !!! Current limitations
// You can't have different aspect ratios on different sources
// Issue is opened : https://github.com/gatsbyjs/gatsby/issues/15189
// Workaround : give it a "cover" prop so it takes the size of its container
// and define your own mediaquery at the container level

// it's an abastraction of Gatsby-Image, have a look at the documentation
// https://www.gatsbyjs.org/packages/gatsby-image/#fragments
// Props ---
// small/medium/large : a ContentfulImage object ({ width, height, url })
// type : defaults to fluid, can be fixed
// fit : defaults to none, can be contain or cover (use a parent to restrict size)
//
// Usage :
// fixed : <Img type={IMG_TYPES.fixed} small={Object} />
// fluid : <Img small={Object} />
// fluid : <Img small={Object} medium={Object} />
// fluid : <Img small={Object} medium={Object} large={Object} />
// cover : <Img fit={IMG_FITS.cover} small={Object} medium={Object} large={Object} />
import React from 'react';
import PropTypes from 'prop-types';
import GatsbyImg from 'gatsby-image';

import { breakpoints } from '@utils';

export const IMG_TYPES = Object.freeze({
  fixed: 'fixed',
  fluid: 'fluid',
});

export const IMG_FITS = Object.freeze({
  none: 'none',
  contain: 'contain',
  cover: 'cover',
});

const fitMap = {
  none: {
    style: {},
    imgStyle: {},
  },
  contain: {
    style: { width: '100%', height: '100%' },
    imgStyle: { objectFit: 'contain', maxWidth: '100%', maxHeight: '100%' },
  },
  cover: {
    style: { width: '100%', height: '100%' },
    imgStyle: {},
  },
};

const mediaString = (size) => `(min-width: ${size}px)`;

const Img = ({
  small,
  medium,
  large,
  type = IMG_TYPES.fluid,
  fit = IMG_FITS.none,
  style,
  imgStyle,
  alt,
  ...rest
}) => {
  const sources = [
    small,
    { ...medium, media: mediaString(breakpoints.sm) },
    { ...large, media: mediaString(medium ? breakpoints.md : breakpoints.sm) },
  ].filter((source) => !!source.src);

  return (
    <GatsbyImg
      {...rest}
      style={{
        ...fitMap[fit].style,
        ...style,
      }}
      imgStyle={{
        ...fitMap[fit].imgStyle,
        ...imgStyle,
      }}
      {...{ [type]: sources }}
      alt={
        alt ||
        (small && small.description) ||
        (large && large.description) ||
        ''
      }
    />
  );
};

Img.propTypes = {
  small: PropTypes.object.isRequired,
  medium: PropTypes.object,
  large: PropTypes.object,
  fit: PropTypes.oneOf(Object.values(IMG_FITS)),
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  type: PropTypes.string,
  alt: PropTypes.string,
};

export default Img;
