import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Link from '@atoms/Link';
import Img from '@atoms/Img';
import TextModule from '@atoms/TextModule';
import Carousel from '@molecules/Carousel';
import twitter from '@icons/twitter.svg';
import facebook from '@icons/facebook.svg';
import instagram from '@icons/instagram.svg';
import website from '@icons/link.svg';
import { SwiperSlide } from 'swiper/react';
import { breakpoints } from '@utils';
import { useWindowSize } from '@hooks';

function InfluencerProfile({ id, influencers }) {
  const hasMultiple = influencers.length > 1;
  const isCarousel = influencers.length > 3;

  const [slidesPerGroup, setSlidesPerGroup] = useState(1);

  const [viewportWidth] = useWindowSize();

  useEffect(() => {
    if (viewportWidth >= breakpoints.lg) {
      setSlidesPerGroup(3);
    } else {
      setSlidesPerGroup(1);
    }
  }, [viewportWidth]);

  return (
    <>
      {isCarousel ? (
        <Container id={id} isCarousel={isCarousel}>
          <Carousel
            id={id}
            isCarousel={isCarousel}
            slidesGrouped={slidesPerGroup}
          >
            {influencers.map(({ name, picture, social, shortBio }) => (
              <SwiperSlide key={name} className="swiper-slide">
                <InfluencerContainer
                  hasMultiple={hasMultiple}
                  isCarousel={isCarousel}
                >
                  <ImageContainer hasMultiple={hasMultiple}>
                    <StyledImg small={picture} />
                    <IconContainer hasMultiple={hasMultiple}>
                      {social.slice(0, 3).map((s) => (
                        <SocialIcon {...s} />
                      ))}
                    </IconContainer>
                  </ImageContainer>
                  <TextContainer hasMultiple={hasMultiple}>
                    <Name>{name}</Name>
                    <StyledTextModule doc={shortBio} />
                  </TextContainer>
                </InfluencerContainer>
              </SwiperSlide>
            ))}
          </Carousel>
        </Container>
      ) : (
        <Container>
          {influencers.map(({ name, picture, social, shortBio }) => (
            <InfluencerContainer hasMultiple={hasMultiple}>
              <ImageContainer hasMultiple={hasMultiple}>
                <StyledImg small={picture} />
                <IconContainer hasMultiple={hasMultiple}>
                  {social.slice(0, 3).map((s) => (
                    <SocialIcon {...s} />
                  ))}
                </IconContainer>
              </ImageContainer>
              <TextContainer hasMultiple={hasMultiple}>
                <Name>{name}</Name>
                <StyledTextModule doc={shortBio} />
              </TextContainer>
            </InfluencerContainer>
          ))}
        </Container>
      )}
    </>
  );
}

InfluencerProfile.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  influencers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      picture: PropTypes.object.isRequired,
      social: PropTypes.array.isRequired,
      shortBio: PropTypes.object.isRequired,
    }).isRequired
  ).isRequired,
};

export default InfluencerProfile;

const Container = styled.div`
  margin: 0 var(--outer-gap);

  ${({ theme, isCarousel }) =>
    !isCarousel &&
    theme.mediaquery.sm(css`
      display: flex;
      margin: 0 calc(var(--outer-gap) + var(--col) * 5);
      margin-top: ${theme.spacing(4)};
    `)}

  ${({ isCarousel }) =>
    isCarousel &&
    css`
      overflow: ${isCarousel ? 'hidden' : 'unset'};
      margin: 0;
    `}

  ${({ theme, id }) =>
    theme.mediaquery.md(css`
      .swiper-container {
        padding: 0 var(--inner-gap) 0 calc(var(--outer-gap) + var(--col) * 3);
      }

      .swiper-slide {
        width: calc(var(--col) * 6 - var(--inner-gap) / 2);
      }

      .swiper-button-next-${id}, .swiper-button-prev-${id} {
        top: 30%;
      }

      .swiper-button-next-${id} {
        right: 0;
      }

      .swiper-button-prev-${id} {
        left: 0;
      }
    `)}
`;

const InfluencerContainer = styled.div`
  ${({ isCarousel }) =>
    !isCarousel &&
    css`
      display: flex;
      position: relative;
      flex: 1 1 50%;
      flex-direction: column;

      & + & {
        margin-left: ${({ theme }) => theme.spacing(2)};
      }
    `}

  ${({ theme, hasMultiple, isCarousel }) =>
    theme.mediaquery.sm(css`
      flex-direction: ${hasMultiple && !isCarousel ? 'column' : 'row'};
    `)}
`;

const TextContainer = styled.div`
  align-self: center;
  ${({ theme, hasMultiple }) => css`
    margin-top: ${theme.spacing(2)};

    ${!hasMultiple &&
    theme.mediaquery.sm(css`
      margin-left: ${theme.spacing(2)};
      margin-top: 0;
    `)}
  `}
`;

const Name = styled.h3`
  ${({ theme }) => theme.typography.titleM}
`;

const ImageContainer = styled.div`
  position: relative;

  ${({ hasMultiple, theme }) =>
    !hasMultiple &&
    css`
      flex: 1 0 50%;
      ${theme.mediaquery.sm(css`
        padding-right: ${theme.spacing(2)};
      `)}
    `}
`;

const StyledImg = styled(Img)`
  & > div {
    padding-bottom: 100% !important;
  }
`;

const StyledTextModule = styled(TextModule)`
  display: block;
  padding: 0;
  margin-top: ${({ theme }) => theme.spacing(2)};

  p {
    line-height: ${({ theme }) => theme.spacing(3)};
  }
`;

const IconContainer = styled.div`
  position: absolute;

  ${({ hasMultiple, theme }) => css`
    left: ${theme.spacing(2)};
    bottom: ${theme.spacing(2)};

    ${!hasMultiple &&
    css`
      ${theme.mediaquery.sm(css`
        right: 0;
        top: 30%;
        left: auto;
        bottom: auto;
      `)}
    `}
  `}
`;

function SocialIcon({ icon, link }) {
  const Icon = {
    facebook,
    twitter,
    instagram,
    website,
  }[icon];
  return (
    <SocialLink url={link} target="_blank">
      <Icon />
    </SocialLink>
  );
}

SocialIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

const SocialLink = styled(Link)`
  width: 32px;
  display: block;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 25%;
  line-height: 0;

  & + & {
    margin-top: ${({ theme }) => theme.spacing(1)};
  }

  path {
    fill: ${({ theme }) => theme.colors.black};
  }
`;
