import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_TYPES, IMG_FITS } from '@atoms/Img';
import { rgba } from '@utils';
import Link, { LINK_TYPES } from '@atoms/Link';
import PlusOneButton from '@atoms/PlusOneButton';

const Container = styled(Link)`
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  padding-left: var(--col-width);
  box-sizing: border-box;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      padding-left: calc(var(--col-width) + var(--inner-gap));
    `)}
  `}
`;

const fullLayer = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const ImgContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    height: 0;
    padding-top: calc(232 / 176 * 100%);

    ${theme.mediaquery.sm(css`
      padding-top: calc(366 / 274 * 100%);
    `)}
  `}

  &:after {
    content: '';
    ${fullLayer};
    background: ${({ theme }) => rgba(theme.colors.black, 0.4)};
  }
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  top: 0;
  left: 0;
`;

const Content = styled.div`
  ${fullLayer};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const CategoryType = styled.p`
  ${({ theme }) => css`
    position: absolute;
    min-width: 200px;
    text-align: right;
    top: 0;
    right: calc(
      100% + ${theme.typography.typesets.titleXS.sm.fontSize} *
        ${theme.typography.typesets.titleXS.sm.lineHeight} + 6px
    );
    transform: rotate(-90deg);
    transform-origin: right top;
    ${theme.typography.titleXS};

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleS};
      right: calc(
        100% + ${theme.typography.typesets.titleS.sm.fontSize} *
          ${theme.typography.typesets.titleS.sm.lineHeight} + 4px
      );
    `)}
  `}
`;

const TitleContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: ${({ theme }) => theme.spacing(4)};
`;

const Title = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    color: ${theme.colors.white};

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleM};
    `)}
  `}
`;

const CardPortrait = ({ background, categoryType, title, slug }) => {
  return (
    <>
      <Container title={title} url={slug} type={LINK_TYPES.internal}>
        <ImgContainer>
          <StyledImg
            small={background}
            type={IMG_TYPES.fluid}
            fit={IMG_FITS.cover}
          />
          <CategoryType>{categoryType}</CategoryType>
        </ImgContainer>
        <Content>
          <TitleContainer>
            <Title>{title}</Title>
          </TitleContainer>
        </Content>
      </Container>
      <PlusOneButton favoriteId={slug} slug={slug} />
    </>
  );
};

CardPortrait.propTypes = {
  background: PropTypes.object.isRequired,
  categoryType: PropTypes.string,
  title: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

export default React.memo(CardPortrait);
