import { useEffect, useState } from 'react';
import { debounce } from '@utils';

const useWindowSize = () => {
  const [size, setSize] = useState(
    typeof window !== 'undefined'
      ? [window.innerWidth, window.innerHeight]
      : [0, 0]
  );
  useEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    };

    const debouncedOnResize = debounce(updateSize, 250);
    window.addEventListener('resize', debouncedOnResize);
    updateSize();
    return () => window.removeEventListener('resize', debouncedOnResize);
  }, []);

  return size;
};
export default useWindowSize;
