import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import PLusOneDefault from '@icons/plus-one-default.svg';
import PLusOnePressed from '@icons/plus-one-pressed.svg';
import Bubble from '@atoms/Bubble';
import { SettingsContext } from '@stores/Settings';

const FavoriteIconStyles = css`
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  pointer-events: initial;
`;

const StyledPLusOneDefault = styled(PLusOneDefault)`
  ${FavoriteIconStyles}
  opacity: ${({ isPressed }) => (isPressed ? 0 : 1)};
`;

const StyledPLusOnePressed = styled(PLusOnePressed)`
  ${FavoriteIconStyles}
  opacity: ${({ isPressed }) => (isPressed ? 1 : 0)};
`;

const Outer = styled.div`
  ${({ theme, isHeroWithVideo }) => css`
    display: block;
    position: ${isHeroWithVideo ? 'absolute' : 'relative'};
    height: ${theme.spacing(5)};
    width: 100%;
    pointer-events: none;
    margin-top: auto;
  `}
`;

const ButtonWrapper = styled.button`
  ${({ position, theme }) => css`
    position: absolute;
    top: 50%;
    right: ${position === 'left' ? 'unset' : 0};
    left: ${position === 'left' ? 0 : 'unset'};
    transform: translateY(-50%);
    cursor: pointer;
    height: 100%;
    width: 44px;
    margin: ${theme.spacing(2.5)} 0;

    &:hover {
      opacity: ${({ isPressed }) => (isPressed ? 1 : 0.8)};
    }
  `}
`;

const StyledBubble = styled(Bubble)`
  ${({ theme, position }) => css`
    position: absolute;
    bottom: ${theme.spacing(4)};
    right: ${position === 'left' ? 'unset' : `-${theme.spacing(1)}`};
    left: ${position === 'left' ? `-${theme.spacing(1)}` : 'unset'};
    max-width: ${theme.spacing(30)};
    transform: none;
    padding: ${theme.spacing(2)}

    > div:after {
      left: 16px;
      transform: none;
    }
  `}
`;

const PlusOneButton = React.memo(
  ({ isHeroWithVideo, favoriteId, slug, position, ...rest }) => {
    const [isPressed, setIsPressed] = useState(false);
    const [bubbleOpened, setBubbleOpened] = useState(false);
    const { translations } = useContext(SettingsContext);

    useEffect(() => {
      if (
        !!favoriteId &&
        JSON.parse(localStorage.getItem(`favorite-item-${favoriteId}`)) ===
          favoriteId
      ) {
        setIsPressed(true);
      }
    }, [favoriteId, isPressed]);

    const toggleFavorite = useCallback(
      (e) => {
        e.preventDefault();
        e.stopPropagation();
        setIsPressed(!isPressed);

        if (!isPressed) {
          localStorage.setItem(
            `favorite-item-${favoriteId}`,
            JSON.stringify(favoriteId)
          );
          if (
            localStorage.getItem(`favorite-item-${favoriteId}`) ===
            JSON.stringify(favoriteId)
          ) {
            const clickedFavourite = {
              event: 'Added Favourite Article',
              articleName: slug,
              action: 'plus',
            };

            dataLayer.push(clickedFavourite);
          }
        } else {
          const unClickedFavourite = {
            event: 'Removed Favourite Article',
            articleName: slug,
            action: 'minus',
          };

          dataLayer.push(unClickedFavourite);
          localStorage.removeItem(`favorite-item-${favoriteId}`);
        }
      },
      [favoriteId, isPressed, slug]
    );

    return (
      <Outer {...rest} isHeroWithVideo={isHeroWithVideo}>
        <ButtonWrapper
          isPressed={isPressed}
          position={position}
          onMouseEnter={() => setBubbleOpened(true)}
          onMouseLeave={() => setBubbleOpened(false)}
        >
          <StyledPLusOneDefault
            isPressed={isPressed}
            onClick={toggleFavorite}
          />
          <StyledPLusOnePressed
            isPressed={isPressed}
            onClick={toggleFavorite}
          />
        </ButtonWrapper>
        <StyledBubble
          onClose={() => setBubbleOpened(false)}
          isOpen={bubbleOpened}
          isPlusOneTooltip
          position={position}
        >
          {translations.tooltipFavourite}
        </StyledBubble>
      </Outer>
    );
  }
);

PlusOneButton.propTypes = {
  position: PropTypes.string,
  favoriteId: PropTypes.string,
  slug: PropTypes.string,
  isHeroWithVideo: PropTypes.bool,
};

export default PlusOneButton;
