import React, { useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Close from '@icons/close.svg';
import { rgba } from '@utils';

const Outer = styled.div`
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  pointer-events: ${({ isOpen }) => (isOpen ? 'all' : 'none')};
  transition: opacity 0.3s cubic-bezier(0.58, 0, 0.56, 1);
  position: relative;
  z-index: 2;
`;

const Inner = styled.div`
  ${({ theme, isPlusOneTooltip }) => css`
    padding: ${theme.spacing(3)};
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    border-radius: ${theme.spacing(2)};
    position: relative;
    text-align: center;
    box-shadow: 0 4px 10px ${rgba(theme.colors.black, 0.5)};

    ${isPlusOneTooltip
      ? css`
          color: ${theme.colors.white};
          ${theme.typography.bodyS};
          background-color: ${theme.colors.black};
          border: 1px solid ${theme.colors.legalGray};
          border-radius: ${theme.spacing(1)};
        `
      : css`
          &:after {
            content: '';
            position: absolute;
            border: 8px solid;
            border-color: white transparent transparent;
            top: 99%;
            left: 50%;
            transform: translateX(-50%);
          }
        `}
  `}
`;

const CloseButton = styled.button`
  border: 0;
  background: none;
  cursor: pointer;
  position: absolute;
  width: 8px;
  height: 8px;
  ${({ theme }) => css`
    top: ${theme.spacing(2)};
    right: ${theme.spacing(2)};
  `}
`;

const Bubble = ({
  isOpen = false,
  onClose,
  children,
  isPlusOneTooltip,
  ...props
}) => {
  const el = useRef(null);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClickOutside = useCallback(
    (e) => {
      if (el.current.contains(e.target)) {
        return;
      }
      handleClose();
    },
    [handleClose]
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, handleClickOutside]);

  return (
    <Outer isOpen={isOpen} ref={el} {...props}>
      <Inner isOpen={isOpen} isPlusOneTooltip={isPlusOneTooltip}>
        {onClose && (
          <CloseButton onClick={handleClose}>
            <Close
              css={css`
                path {
                  fill: ${({ theme }) => theme.colors.black};
                }
              `}
            />
          </CloseButton>
        )}
        {children}
      </Inner>
    </Outer>
  );
};

Bubble.propTypes = {
  isOpen: PropTypes.bool,
  isPlusOneTooltip: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default Bubble;
