import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS } from '@atoms/Img';
import TextModule from '@atoms/TextModule';
import SectionTitle from '@atoms/SectionTitle';

const Container = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const StyledImg = styled(Img)`
  ${({ theme }) => css`
    margin: ${theme.spacing(2)} 0;

    ${theme.mediaquery.sm(css`
      margin: ${theme.spacing(5)} 0;
    `)}
  `}
`;

const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + var(--col) * 7);
    `)}
  `}
`;

const StyledTextModule = styled(TextModule)`
  ${({ theme }) => css`
    padding: 0 var(--outer-gap);
    display: block;

    ${theme.mediaquery.sm(css`
      text-align: center;
      padding: 0 calc(var(--outer-gap) + var(--col) * 7);
    `)}
  `}
`;

const RecipeStep = ({ title, description, image, imagePosition }) => (
  <Container>
    {image && imagePosition === 'before' && (
      <StyledImg
        small={image.small}
        medium={image.large}
        fits={IMG_FITS.cover}
      />
    )}
    <StyledSectionTitle title={title} />
    <StyledTextModule doc={description} enableRenderer />
    {image && imagePosition === 'after' && (
      <StyledImg
        small={image.small}
        medium={image.large}
        fits={IMG_FITS.cover}
      />
    )}
  </Container>
);

RecipeStep.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  image: PropTypes.object,
  imagePosition: PropTypes.string.isRequired,
};

export default RecipeStep;
