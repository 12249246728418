import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import Hero, { adapter as heroAdapter } from '@organisms/Hero';
import PromotionalOverlay from '@organisms/PromotionalOverlay';
import { getSettings, getPageMeta, getCommonJSON } from '@utils';
import PageComponents from './PageComponents';
import trackEvent from './trackEvent';

const GenericPage = ({ data, pageContext, path }) => {
  // Grab settings from the data, which is the result from the gql query below
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulGenericPage;
  const pageSeo = getPageMeta(page.seo, settings.brandName);

  const heroProps = heroAdapter(page.hero);
  const promotionalOverlayProps = settings?.promotionalOverlay;

  const jsonld = [
    ...getCommonJSON(page, settings, heroProps, settings.brandName),
  ];

  const isFunnelFirst =
    page.pageComponents[0]?.__typename === 'ContentfulProgramFunnel';

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, path, pageContext.domain]);

  const extraProps = {
    contentPages: data.allContentfulContentPage.nodes,
  };

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} jsonld={jsonld} />
      <Hero
        {...heroProps}
        isFunnelFirst={isFunnelFirst}
        path={page.slug}
        pageId={pageContext.id}
      />
      <PageComponents modules={page.pageComponents} {...extraProps} />
      {!!promotionalOverlayProps && (
        <PromotionalOverlay {...promotionalOverlayProps} />
      )}
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getGenericPageData($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    allContentfulContentPage(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        ...Card
      }
    }
    contentfulGenericPage(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      seo {
        ...Metadata
      }
      slug
      hero {
        ...Hero
      }
      pageComponents {
        ...GenericPageComponents
      }
    }
  }
`;

GenericPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default GenericPage;
