import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS } from '@atoms/Img';
import { grid } from '@styles/grid';
import Play from '@icons/play.svg';
import VideoPush from '@molecules/VideoPush';
import Modal from '@molecules/Modal';
import { rgba } from '@utils';
import Link from '@atoms/Link';
import { buttonStyle, BUTTON_TYPES } from '@atoms/Button';
import InfluencerTagline, {
  adapter as influencerAdapter,
} from '@molecules/InfluencerTagline';
import PlusOneButton from '@atoms/PlusOneButton';

const ALIGNMENT = Object.freeze({
  'Left - Left': {
    position: 'left',
    textAlign: 'left',
  },
  'Center - Left': {
    position: 'center',
    textAlign: 'left',
  },
  'Center - Center': {
    position: 'center',
    textAlign: 'center',
  },
});

const Wrapper = styled.section`
  position: relative;
`;

const Button = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    margin-top: ${theme.spacing(5)};
    display: inline-block;
  `}
`;

const ImageContainer = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.black};
    padding-top: 90vh;
    height: 0;

    &:after {
      content: '';
      display: block;
      background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
      transform: rotate(-180deg);
      width: 100%;
      height: 60%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  `}
`;

const StyledImg = styled(Img)`
  picture {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  ${({ theme, hasImage }) => css`
    ${grid};
    padding-top: ${hasImage ? theme.spacing(6) : theme.spacing(20)};
    padding-bottom: ${theme.spacing(4)};
    position: absolute;
    bottom: 0;

    ${theme.mediaquery.sm(css`
      padding-top: ${hasImage ? theme.spacing(8) : theme.spacing(32)};
      padding-bottom: ${theme.spacing(6)};
    `)}
  `}
`;

const Content = styled.div`
  ${({ theme, position, textAlign }) => css`
    grid-column: 1 / -1;

    ${theme.mediaquery.sm(css`
      grid-column: ${position === 'center' ? 7 : 2} / span 12;
      text-align: ${textAlign};
    `)}
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.titleXL};
    color: ${theme.colors.white};
  `}
`;

const PlayButton = styled.button`
  ${({ theme }) => css`
    width: ${theme.spacing(8)};
    height: ${theme.spacing(8)};
    border-radius: 50%;
    background: ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: ${theme.spacing(3)};
    right: ${theme.spacing(3)};
    cursor: pointer;
    box-shadow: 0 4px 10px ${rgba(theme.colors.black, 0.5)};
    z-index: 1;

    svg {
      margin: 0 auto;
    }

    path {
      fill: ${theme.colors.black};
    }

    ${theme.mediaquery.sm(css`
      bottom: ${theme.spacing(8)};
      right: ${theme.spacing(5)};
    `)}
  `}
`;

const HeroBlurred = ({
  headline,
  image,
  alignment,
  video,
  influencer,
  cta,
  path,
}) => {
  const [videoVisible, setVideoVisible] = useState(false);

  const hasPlusOneButton = path !== 'home' && path !== '/' && path !== '';

  const { position, textAlign } = ALIGNMENT[alignment];

  return (
    <Wrapper>
      {!!image && (
        <ImageContainer>
          <StyledImg
            small={image.small}
            medium={image.large}
            fits={IMG_FITS.cover}
          />
          {!!video && (
            <PlayButton
              title="Play Video"
              onClick={() => {
                setVideoVisible(true);
              }}
            >
              <Play />
            </PlayButton>
          )}
        </ImageContainer>
      )}
      <ContentContainer hasImage={!!image}>
        <Content position={position} textAlign={textAlign}>
          {!!influencer && (
            <InfluencerTagline {...influencerAdapter(influencer)} />
          )}
          <Title>{headline}</Title>
          {cta && <Button {...cta} />}
          {hasPlusOneButton && (
            <PlusOneButton position="left" favoriteId={path} slug={path} />
          )}
        </Content>
      </ContentContainer>
      {!!video && (
        <Modal onClose={() => setVideoVisible(false)} isOpen={videoVisible}>
          <VideoPush squareCorners {...video} disableFullscreen forceSound />
        </Modal>
      )}
    </Wrapper>
  );
};

HeroBlurred.propTypes = {
  layout: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  image: PropTypes.object,
  alignment: PropTypes.string,
  video: PropTypes.shape(VideoPush.propTypes),
  influencer: PropTypes.object,
  cta: PropTypes.any,
  imageMobileRatio: PropTypes.number,
  imageDesktopRatio: PropTypes.number,
};

export default HeroBlurred;
