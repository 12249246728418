import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import anime from 'animejs';

import { offsetRelative } from '@utils';
import NavigationButton, { NAVIGATION_TYPES } from '@atoms/NavigationButton';
import RelatedCards, { HIGHLIGHTED_CARD } from '@organisms/RelatedCards';
import { usePrevious } from '@hooks';
import trackEvent from './trackEvent';

const ITEM_WIDTH = 68;

const Container = styled.div`
  display: grid;
`;

const ListContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  overflow: hidden;
  position: sticky;
  top: 25vh;
  height: 50vh;
`;

const List = styled.ul`
  height: 100%;
  display: grid;
  align-items: center;
  grid-auto-columns: 1fr;
  padding-left: calc((100vw - ${ITEM_WIDTH}vw) / 2);
`;

const ListItem = styled.li`
  ${({ theme }) => css`
    user-select: none;
    grid-row: 1;
    width: ${ITEM_WIDTH}vw;
    text-align: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    span {
      ${theme.typography.titleXXL};
      font-size: 9.4vw !important;
      color: ${theme.colors.white};
      width: 100%;

      &:last-of-type {
        -webkit-text-fill-color: transparent;
        -webkit-text-stroke: 1px ${theme.colors.white};
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
      }
    }

    div {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: black;
      transform: ${({ active }) => (active ? 'scaleX(0)' : 'scaleX(1)')};
    }
  `}
`;

const Content = styled.div`
  grid-column: 1;
  grid-row: 1;
  padding-top: 52vh;
  opacity: 0;
  pointer-events: none;
  transform: translateY(100px);

  &.explorer-content-enter {
    opacity: 0;
    pointer-events: none;
    transform: translateY(100px);
    transition: opacity 500ms cubic-bezier(0.3, 0.8, 0.5, 1),
      transform 500ms cubic-bezier(0.3, 0.8, 0.5, 1);

    &-active,
    &-done {
      opacity: 1;
      transform: translateY(0);
      pointer-events: initial;
    }
  }

  &.explorer-content-exit {
    opacity: 1;
    pointer-events: initial;
    transition: opacity 500ms cubic-bezier(0.3, 0.8, 0.5, 1),
      transform 500ms cubic-bezier(0.3, 0.8, 0.5, 1);

    &-active,
    &-done {
      opacity: 0;
      transform: translateY(100px);
      pointer-events: none;
    }
  }

  ul {
    grid-auto-flow: initial;
  }
`;

const ControlsContainer = styled.div`
  grid-column: 1;
  grid-row: 1;
  overflow: hidden;
  position: sticky;
  top: 0;
  height: 100vh;
  pointer-events: none;
  opacity: 0;

  &.explorer-content-enter {
    opacity: 0;
    transition: opacity 500ms;

    &-active,
    &-done {
      opacity: 1;
    }
  }

  &.explorer-content-exit {
    opacity: 1;
    transition: opacity 500ms;

    &-active,
    &-done {
      opacity: 0;
    }
  }
`;

const navigationButtonStyle = css`
  position: absolute;
  top: 50%;
  pointer-events: initial;
`;

const PrevButton = styled(NavigationButton)`
  ${({ theme }) => css`
    ${navigationButtonStyle};
    left: var(--outer-gap);
    transform: translate3d(-25%, -50%, 0);

    ${theme.mediaquery.sm(css`
      left: calc(var(--outer-gap) + var(--col) * 3 - var(--inner-gap));
    `)}

    ${theme.mediaquery.md(css`
      left: calc(var(--outer-gap) + var(--col) + var(--inner-gap));
    `)}
  `}
`;

const NextButton = styled(NavigationButton)`
  ${({ theme }) => css`
    ${navigationButtonStyle};
    right: var(--outer-gap);
    transform: translate3d(25%, -50%, 0);

    ${theme.mediaquery.sm(css`
      right: calc(var(--outer-gap) + var(--col) * 3 - var(--inner-gap));
    `)}

    ${theme.mediaquery.md(css`
      right: calc(var(--outer-gap) + var(--col) + var(--inner-gap));
    `)}
  `}
`;

const Explorer = ({ categories }) => {
  const containerRef = useRef(null);
  const listRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const previousIndex = usePrevious(currentIndex);
  const [cardsVisible, setCardsVisible] = useState(false);
  const [intersectionRef, inView] = useInView({
    triggerOnce: true,
  });
  const [inViewInitial, setInViewInitial] = useState(false);

  const slideTimeline = (next) => {
    const { top } = offsetRelative(containerRef.current);
    const scrollElement =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement;
    setCardsVisible(false);
    const tl = anime.timeline({
      easing: 'cubicBezier(0.88, 0, 0.1, 1)',
      duration: 600,
    });
    tl.add({
      targets: listRef.current,
      scale: 0.5,
    })
      .add({
        targets: listRef.current,
        translateX: `${-(currentIndex + next) * ITEM_WIDTH}vw`,
        complete: () => {
          setCurrentIndex(currentIndex + next);
        },
      })
      .add(
        {
          targets: '.text-mask',
          transformOrigin: (el, i) => {
            if (i === currentIndex) {
              return next < 0 ? ['100% 100%', '100% 100%'] : ['0% 0%', '0% 0%'];
            }

            return next > 0 ? ['100% 100%', '100% 100%'] : ['0% 0%', '0% 0%'];
          },
          scaleX: (el, i) => {
            if (i === currentIndex) {
              return [0, 1];
            }

            if (i === currentIndex + next) {
              return 0;
            }

            return 1;
          },
        },
        '-=600'
      )
      .add({
        targets: scrollElement,
        scrollTop: top,
        duration: 1,
      })
      .add({
        targets: listRef.current,
        scale: 1,
        update: (anim) => {
          if (anim.progress > 50) {
            setCardsVisible(true);
          }
        },
      });
  };

  const slide = (next) => {
    const { top } = offsetRelative(containerRef.current);
    const shouldScrollUp =
      window.pageYOffset < top ||
      window.pageYOffset + window.innerHeight >
        top + containerRef.current.offsetHeight;

    if (shouldScrollUp) {
      const scrollElement =
        window.document.scrollingElement ||
        window.document.body ||
        window.document.documentElement;

      anime({
        targets: scrollElement,
        scrollTop: top,
        duration: 750,
        easing: 'easeInOutCubic',
        complete: () => {
          slideTimeline(next);
        },
      });
    } else {
      slideTimeline(next);
    }
  };

  useEffect(() => {
    let timeout;
    if (!inViewInitial) {
      anime({
        targets: listRef.current,
        scale: 1, // forces scale declaration before translate to avoid mispositioning
        translateX: `${-currentIndex * ITEM_WIDTH}vw`,
        duration: 0,
      });
    }
    if (inView && !inViewInitial) {
      timeout = setTimeout(() => {
        setCardsVisible(true);
        setInViewInitial(true);
      }, 2000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [inView, inViewInitial, currentIndex]);

  useEffect(() => {
    if (inViewInitial && previousIndex !== currentIndex) {
      const { name } = categories[currentIndex];
      trackEvent(name);
    }
  }, [currentIndex, previousIndex, inViewInitial, categories]);

  return (
    <Container ref={containerRef}>
      <ListContainer ref={intersectionRef}>
        <List ref={listRef}>
          {categories.map(({ name }, index) => (
            <ListItem
              key={`explorer_item_${name}`}
              active={currentIndex === index}
            >
              <span>{name}</span>
              <div className="text-mask" />
              <span aria-hidden="true">{name}</span>
            </ListItem>
          ))}
        </List>
      </ListContainer>
      <CSSTransition
        in={cardsVisible}
        timeout={500}
        classNames="explorer-content"
      >
        <Content>
          <RelatedCards
            highlightedCard={HIGHLIGHTED_CARD.first}
            cards={categories[currentIndex].cards}
          />
        </Content>
      </CSSTransition>
      <CSSTransition
        in={cardsVisible}
        timeout={500}
        classNames="explorer-content"
      >
        <ControlsContainer>
          {currentIndex !== 0 && (
            <PrevButton
              onClick={() => {
                slide(-1);
              }}
            />
          )}
          {currentIndex !== categories.length - 1 && (
            <NextButton
              direction={NAVIGATION_TYPES.right}
              onClick={() => {
                slide(+1);
              }}
            />
          )}
        </ControlsContainer>
      </CSSTransition>
    </Container>
  );
};

Explorer.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      cards: PropTypes.instanceOf(Array),
    })
  ),
};

export default React.memo(Explorer);
