import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    width: 100%;
    padding: 0 var(--inner-gap);
    position: relative;
    margin-bottom: ${theme.spacing(4)};

    ${theme.mediaquery.sm(css`
      text-align: center;
      margin-bottom: ${theme.spacing(5)};
      padding: 0 calc(var(--outer-gap) + var(--col) * 4);
    `)}

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 4px;
      height: 100%;
      /* To show the correct product color as the indicator product, you first need to make sure that any
      parent of this component defines the --product-color variable. */
      background: var(--product-color, ${theme.colors.white});
    }
  `}
`;

const SectionTitle = ({ title, className }) => (
  <Title className={className}>{title}</Title>
);

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default SectionTitle;
