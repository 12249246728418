import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import { buttonStyle } from '@atoms/Button';
import Link, { LINK_TYPES } from '@atoms/Link';

import { grid } from '@styles/grid';

const Container = styled.section`
  position: relative;
  overflow: hidden;
`;

const PushContainer = styled.div`
  ${({ theme }) => css`
    ${grid};
    position: relative;
    grid-row-gap: var(--inner-gap);
    margin: 0 auto;
    padding: 0 var(--outer-gap);
    align-items: center;

    ${theme.mediaquery.sm(css`
      margin: calc(35vw / 3) auto;
    `)}
  `}
`;

const TextsContainer = styled.div`
  grid-column: 1 / span 4;
  position: relative;
  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: 5 / span 8;
    `)}

    ${theme.mediaquery.md(css`
      grid-column: 4 / span 10;
    `)}
  `}
`;

const ImageContainer = styled.div`
  grid-column: 1 / span 4;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: unset;
      position: absolute;
      right: calc(var(--col) * 3);
      top: 50%;
      transform: translateY(-50%);
      width: 35vw;
    `)}
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    display: -webkit-box;
    position: relative;
    ${theme.mediaquery.md(css`
      margin-bottom: 0;
      margin-top: ${theme.spacing(3)};
    `)}
    ${theme.mediaquery.lg(css`
      width: 80%;
    `)}
  `}
`;

const TitleTwo = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    display: -webkit-box;
    position: relative;
    margin-top: 0;
    ${theme.mediaquery.lg(css`
      width: 80%;
    `)}
  `}
`;

const Subtitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(2)};
    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(3)};
      width: 80%;
    `)}
  `}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin-top: ${theme.spacing(2)};
    display: inline-block;
    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(3)};
    `)}
  `}
`;

const CalculatorPush = ({
  title,
  titleTwo,
  ctaText,
  subtitle,
  calculatorPageSlug,
  image,
}) => (
  <Container>
    <PushContainer>
      {image && (
        <ImageContainer>
          <Img
            type={IMG_TYPES.fluid}
            fits={IMG_FITS.none}
            small={image.small}
            medium={image.large}
            alt={title}
          />
        </ImageContainer>
      )}
      <TextsContainer>
        <Title>{title}</Title>
        <TitleTwo>{titleTwo}</TitleTwo>
        <Subtitle>{subtitle}</Subtitle>
        <StyledLink url={calculatorPageSlug} type={LINK_TYPES.internal}>
          {ctaText}
        </StyledLink>
      </TextsContainer>
    </PushContainer>
  </Container>
);

CalculatorPush.propTypes = {
  calculatorPageSlug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleTwo: PropTypes.string,
  subtitle: PropTypes.string,
  ctaText: PropTypes.string,
  image: PropTypes.object,
};

export default CalculatorPush;
