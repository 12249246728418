import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y, Navigation } from 'swiper';

import TribeCard from '@molecules/TribeCard';
import { RelatedCardsCarousel } from '@organisms/RelatedCards';
import ProgramsCarousel from '@organisms/ProgramsCarousel';
import NavigationButton, { NAVIGATION_TYPES } from '@atoms/NavigationButton';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([A11y, Navigation]);

const classNames = Object.freeze({
  prev: `swiper-button-prev-funnel-tribe`,
  next: `swiper-button-next-funnel-tribe`,
});

const ProgramFunnelContainer = styled.section``;

const TribesContainer = styled.div`
  ${({ theme, showTribesIn2RowsOnMobile }) => css`
    background: ${theme.colors.white};
    padding: ${theme.spacing(15)} 0;

    .swiper-container {
      padding: 0 var(--outer-gap);
      margin-top: ${theme.spacing(3)};
    }

    .swiper-slide {
      width: calc(var(--col) * 2 - var(--inner-gap) * 2);
      min-width: 134px;
      max-width: 160px;
      margin-right: var(--inner-gap);
      margin-bottom: ${showTribesIn2RowsOnMobile ? 'var(--inner-gap)' : '0'};
    }

    ${theme.mediaquery.sm(css`
      padding: ${theme.spacing(25)} 0;

      .swiper-container {
        padding: 0 calc(var(--outer-gap) + (var(--col) * 3));
      }

      .swiper-slide {
        width: calc(var(--col) * 5 - var(--inner-gap));
        min-width: auto;
        max-width: none;
        margin-bottom: 0;
      }
    `)}

    ${theme.mediaquery.md(css`
      .swiper-container {
        margin-top: ${theme.spacing(5)};
      }

      .swiper-slide {
        width: calc(var(--col) * 4 - var(--inner-gap));
      }
    `)}

    ${theme.mediaquery.lg(css`
      .swiper-slide {
        width: calc(var(--col) * 3 - var(--inner-gap));
      }
    `)}
  `}
`;

const Controls = styled.div`
  ${({ theme }) => css`
    .carousel-button-prev,
    .carousel-button-next {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2;
      outline: none;
      ${theme.mediaquery.sm(
        css`
          display: flex;
        `
      )}
    }

    .carousel-button-next {
      right: 0;
    }

    .carousel-button-prev {
      left: 0;
    }

    ${theme.mediaquery.sm(css`
      .carousel-button-next {
        right: var(--outer-gap);
      }

      .carousel-button-prev {
        left: var(--outer-gap);
      }
    `)}

    .swiper-button-disabled {
      display: none;
      pointer-events: none;
    }
  `}
`;

const StyledNavigationButton = styled(NavigationButton)`
  ${({ theme }) => css`
    background-color: ${theme.colors.black};

    path {
      fill: ${theme.colors.white};
    }
  `}
`;

const TribesTitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.black};
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + (var(--col) * 3));
    `)}
  `}
`;
const TribesSubTitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.bodyL};
    color: ${theme.colors.black};
    padding: 0 var(--outer-gap);
    margin-top: ${theme.spacing(2)};
    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + (var(--col) * 3));
    `)}
  `}
`;

const StyledProgramsCarousel = styled(ProgramsCarousel)`
  ${({ theme }) => css`
    margin-top: -${theme.spacing(10)};

    ${theme.mediaquery.sm(css`
      margin-top: -${theme.spacing(15)};
    `)}
  `}
`;

const Carousel = styled.li`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(10)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(15)};
    `)}
  `}
`;

const ProgramFunnel = ({
  tribesTitle,
  tribesSubtitle,
  showTribesIn2RowsOnMobile,
  tribes,
  programsCarousel,
  carousels,
  allCards,
}) => {
  const [initialized, setInitialized] = useState(false);
  const [currentProgramsCarousel, setCurrentProgramsCarousel] = useState(
    programsCarousel
  );

  const [currentFilter, setCurrentFilter] = useState(null);

  const getNewProgramsCarouel = useCallback(
    (filter) => {
      return {
        ...programsCarousel,
        cards: programsCarousel.cards
          .filter((item) => item.tribes.includes(filter))
          .map((item) => {
            const tribeIndex = item.tribes.findIndex(
              (tribe) => tribe === filter
            );
            return {
              ...item,
              image: item.supportingImages[tribeIndex],
            };
          }),
      };
    },
    [programsCarousel]
  );

  useEffect(() => {
    if (initialized || initialized === 'from-params') {
      if (currentFilter === null) {
        setCurrentProgramsCarousel(programsCarousel);
      } else {
        const newProgramsCarousel = getNewProgramsCarouel(currentFilter);
        setCurrentProgramsCarousel(newProgramsCarousel);
      }
    }
  }, [currentFilter, initialized, programsCarousel, getNewProgramsCarouel]);

  useEffect(() => {
    if (initialized === true) {
      const tribe = currentFilter !== null ? `t=${currentFilter}` : null;
      const urlParams = `?${tribe || ''}`;
      navigate(urlParams, {
        replace: true,
        state: { keepScrollPosition: true },
      });
    }
  }, [initialized, currentFilter]);

  const parseParams = useCallback(
    (urlParams) => {
      const params = urlParams.replace('?', '');
      params.split('&').forEach((filter) => {
        const [filterType, filterValue] = filter.split('=');
        switch (filterType) {
          case 't':
            if (!initialized) {
              setInitialized('from-params');
            }
            setCurrentFilter(filterValue);
            break;
          default:
            break;
        }
      });
    },
    [initialized]
  );

  useEffect(() => {
    const urlParams = window.location.search;
    if (urlParams && urlParams !== '') {
      parseParams(urlParams);
    }
  }, [parseParams]);

  return (
    <ProgramFunnelContainer>
      <TribesContainer showTribesIn2RowsOnMobile>
        <TribesTitle>{tribesTitle}</TribesTitle>
        {tribesSubtitle && <TribesSubTitle>{tribesSubtitle}</TribesSubTitle>}
        <Swiper
          slidesPerView="auto"
          slidesPerColumn={showTribesIn2RowsOnMobile ? 2 : 1}
          slidesPerColumnFill="row"
          breakpoints={{
            768: {
              slidesPerColumn: 1,
            },
          }}
          navigation={{
            nextEl: `.${classNames.next}`,
            prevEl: `.${classNames.prev}`,
          }}
          freeMode
          grabCursor
          allowSlideNext
          allowSlidePrev
          allowTouchMove
        >
          <Controls>
            <StyledNavigationButton
              className={`${classNames.prev} carousel-button-prev`}
              direction={NAVIGATION_TYPES.left}
            />
            <StyledNavigationButton
              className={`${classNames.next} carousel-button-next`}
              direction={NAVIGATION_TYPES.right}
            />
          </Controls>
          {tribes.map(({ id, urlValue, ...rest }) => (
            <SwiperSlide key={id}>
              <TribeCard
                {...rest}
                onClick={() => {
                  if (initialized !== true) {
                    setInitialized(true);
                  }
                  setCurrentFilter(
                    urlValue !== currentFilter ? urlValue : null
                  );
                }}
                inactive={currentFilter !== null && currentFilter !== urlValue}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </TribesContainer>
      <StyledProgramsCarousel {...currentProgramsCarousel} />
      <ul>
        {carousels.map(({ type, cards, ...rest }) => (
          <Carousel key={`carousel-${type}`}>
            <RelatedCardsCarousel
              {...rest}
              cards={
                currentFilter !== null
                  ? allCards.filter(
                      (item) =>
                        item.tribes.length > 0 &&
                        item.tribes.includes(currentFilter) &&
                        item.categoryType === type
                    )
                  : cards
              }
            />
          </Carousel>
        ))}
      </ul>
    </ProgramFunnelContainer>
  );
};

ProgramFunnel.propTypes = {
  tribesTitle: PropTypes.string.isRequired,
  tribesSubtitle: PropTypes.string,
  showTribesIn2RowsOnMobile: PropTypes.bool.isRequired,
  tribes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      urlValue: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.object.isRequired,
    })
  ).isRequired,
  programsCarousel: PropTypes.shape(ProgramsCarousel.propTypes).isRequired,
  carousels: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      ...RelatedCardsCarousel.propTypes,
    })
  ).isRequired,
  allCards: PropTypes.instanceOf(Array).isRequired,
};

export default ProgramFunnel;
