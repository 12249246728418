import { useContext } from 'react';
import { SettingsContext } from '@stores/Settings';
import { colors, spacing, mediaquery, typography, baseFontSize } from '@utils';
import zIndex from '../utils/zindex';

const Theme = () => {
  const { market } = useContext(SettingsContext);
  const altFontMarkets = ['GR'];
  const useAltFont = altFontMarkets.includes(market);
  const baseFontFamily = useAltFont
    ? `'AstyCF Std', Helvetica, Arial, sans-serif`
    : `'Futura Std', Helvetica, Arial, sans-serif`;

  return {
    colors,
    spacing,
    mediaquery,
    typography: typography(market),
    baseFontSize,
    baseFontFamily,
    zIndex,
  };
};

const theme = Theme;

export { colors };
export default theme;
