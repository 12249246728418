import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import ProgramCard from '@molecules/ProgramCard';

const ProgramPushContainer = styled.section`
  ${({ theme }) => css`
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      padding-left: calc(var(--outer-gap) + var(--col) * 4);
    `)}

    ${theme.mediaquery.md(css`
      padding-left: calc(var(--outer-gap) + var(--col) * 3);
    `)}

    ${theme.mediaquery.lg(css`
      padding-left: calc(var(--outer-gap) + var(--col) * 5);
    `)}
  `}
`;

const ProgramPush = ({ className, ...rest }) => (
  <ProgramPushContainer className={className}>
    <ProgramCard {...rest} />
  </ProgramPushContainer>
);

ProgramPush.propTypes = {
  className: PropTypes.string,
  ...ProgramCard.propTypes,
};

export default ProgramPush;
