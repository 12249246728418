import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Chip from '@atoms/Chip';

const Inner = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;

    ${theme.mediaquery.sm(css`
      justify-content: center;
    `)}
  `}
`;

const ChipStyled = styled(Chip)`
  cursor: pointer;
  user-select: none;
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};

  &:last-child {
    margin-right: 0;
  }
`;

const ChipSelector = ({
  items,
  onChange = (v) => v,
  selectedChips,
  lightTheme = false,
  ...rest
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (selectedChips !== undefined) {
      setSelected(selectedChips);
    }
  }, [selectedChips]);

  const toggleItem = (key, toggle) => {
    let nextSelected;
    if (toggle) {
      nextSelected = [...selected, key];
    } else {
      nextSelected = selected.filter((thisKey) => thisKey !== key);
    }

    onChange(nextSelected, key);
    setSelected(nextSelected);
  };

  return (
    <div {...rest}>
      <Inner>
        {Object.values(items).map(({ key, label }) => {
          const active = !!selected.find((thisKey) => thisKey === key);
          return (
            <ChipStyled
              key={key}
              text={label}
              active={active}
              onClick={() => toggleItem(key, !active)}
              lightTheme={lightTheme}
            />
          );
        })}
      </Inner>
    </div>
  );
};

ChipSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  selectedChips: PropTypes.array,
  lightTheme: PropTypes.bool,
};

export default ChipSelector;
