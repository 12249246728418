import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import { getSettings, getPageMeta } from '@utils';

import Product, { adapter as productAdapter } from '@organisms/Product';
import PromotionalOverlay from '@organisms/PromotionalOverlay';
import trackEvent from './trackEvent';

const ProductPage = ({ data, pageContext, path }) => {
  // Grab settings from the data, which is the result from the gql query below
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulProductFlavour;
  const pageSeo = getPageMeta(page.seo, settings.brandName);
  const promotionalOverlayProps = settings?.promotionalOverlay;

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <Product {...productAdapter(page)} />
      {!!promotionalOverlayProps && (
        <PromotionalOverlay {...promotionalOverlayProps} />
      )}
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getProductPageData($nodeLocale: String!, $id: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    contentfulProductFlavour(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      ...ProductPageComponents
    }
  }
`;

ProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default ProductPage;
