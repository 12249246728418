import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS } from '@atoms/Img';
import { fullbleedgrid } from '@styles/grid';
import VideoPush from '@molecules/VideoPush';
import Modal from '@molecules/Modal';
import Link from '@atoms/Link';
import { buttonStyle, BUTTON_TYPES } from '@atoms/Button';
import InfluencerTagline, {
  adapter as influencerAdapter,
} from '@molecules/InfluencerTagline';
import PlusOneButton from '@atoms/PlusOneButton';

export const VIDEO_MODE = Object.freeze({
  bg: 'background video',
  cta: 'modal with cta',
});

const Wrapper = styled.section`
  ${({ theme, isFunnelFirst }) => css`
    ${fullbleedgrid};
    margin-top: ${theme.spacing(15)};
    align-items: center;
    margin-bottom: ${theme.spacing(isFunnelFirst ? -11 : 8)};

    ${theme.mediaquery.sm(css`
      margin-top: 0;
      margin-bottom: ${theme.spacing(isFunnelFirst ? -15 : 10)};
    `)};
  `}
`;

const Button = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    display: inline-block;
  `}
`;

const ImageContainer = styled.div`
  ${({ theme }) => css`
    grid-column: 2 / -2;
    grid-row: 1;
    position: relative;
    z-index: 1;

    ${theme.mediaquery.sm(css`
      grid-column: 7 / -7;
    `)}
  `}
`;

const BackgroundVideo = styled(VideoPush)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  video {
    object-fit: cover;
  }
`;

const StyledImg = styled(Img)`
  ${({ theme }) => css`
    height: 0;
    padding-top: calc(574 / 336 * 100%);

    ${theme.mediaquery.sm(css`
      padding-top: calc(1060 / 796 * 100%);
    `)}
  `}
`;

const ContentContainer = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / -1;
    grid-row: 1;
    z-index: 2;

    ${theme.mediaquery.sm(css`
      grid-column: 2 / span 14;
    `)}
  `}
`;

const StyledTagline = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleXS};
    margin-bottom: ${theme.spacing(1)};

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleS};
      margin-bottom: ${theme.spacing(2)};
    `)}
  `}
`;

const Title = styled.h1`
  ${({ theme }) => css`
    ${theme.typography.titleXXL};
    color: ${theme.colors.white};
  `}
`;

const StyledInfluencerTagline = styled(InfluencerTagline)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(1)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(2)};
    `)}
  `}
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${({ theme }) => theme.spacing(5)};
`;

const VideoCta = styled.button`
  ${({ theme }) => css`
    ${buttonStyle(theme, BUTTON_TYPES.primary)};
    margin-right: ${theme.spacing(2)};
  `}
`;

const HeroPursuit = ({
  headline,
  tagline,
  image,
  video,
  videoMode = VIDEO_MODE.bg,
  videoCtaLabel,
  influencer,
  cta,
  isFunnelFirst,
  path,
  pageId,
}) => {
  const [videoVisible, setVideoVisible] = useState(false);
  const hasPlusOneButton = path !== 'home' && path !== '/' && path !== '';

  return (
    <Wrapper isFunnelFirst={isFunnelFirst}>
      <ImageContainer>
        <StyledImg
          small={{ ...image.small, sizes: '90vw' }}
          medium={{ ...image.large, sizes: '55vw' }}
          fits={IMG_FITS.cover}
        />

        {!!video && videoMode === VIDEO_MODE.bg && (
          <BackgroundVideo
            squareCorners
            {...video}
            disableFullscreen
            controls={false}
            threshold={0}
            autopause={false}
          />
        )}
        {hasPlusOneButton && (
          <PlusOneButton
            isHeroWithVideo={!!video && videoMode === VIDEO_MODE.bg}
            favoriteId={pageId}
          />
        )}
      </ImageContainer>
      <ContentContainer>
        <Title>{headline}</Title>
        {!!tagline && <StyledTagline>{tagline}</StyledTagline>}
        {!!influencer && (
          <StyledInfluencerTagline {...influencerAdapter(influencer)} />
        )}
        <ButtonsContainer>
          {!!video && videoMode === VIDEO_MODE.cta && (
            <VideoCta
              onClick={() => {
                setVideoVisible(true);
              }}
            >
              {videoCtaLabel}
            </VideoCta>
          )}
          {cta && <Button {...cta} />}
        </ButtonsContainer>
      </ContentContainer>
      {!!video && videoMode === VIDEO_MODE.cta && (
        <Modal onClose={() => setVideoVisible(false)} isOpen={videoVisible}>
          <VideoPush squareCorners {...video} disableFullscreen forceSound />
        </Modal>
      )}
    </Wrapper>
  );
};

HeroPursuit.propTypes = {
  tagline: PropTypes.string,
  headline: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  image: PropTypes.object,
  video: PropTypes.shape(VideoPush.propTypes),
  videoMode: PropTypes.string,
  videoCtaLabel: PropTypes.string,
  influencer: PropTypes.object,
  cta: PropTypes.any,
  isFunnelFirst: PropTypes.bool,
};

export default HeroPursuit;
