import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img from '@atoms/Img';

const Container = styled.div`
  display: block;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: var(--inner-gap);
  align-items: center;

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      display: block;
    `)}
`;

const ImgContainer = styled.div`
  position: relative;
`;

const StyledImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`;

const TitleContainer = styled.div`
  grid-column: span 1;
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }) =>
    theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(1)};
    `)}
`;
const Subtitle = styled.p`
  ${({ theme }) => css`
    ${theme.typography.footnote};
    color: ${theme.colors.white};
  `}
`;

const Title = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    ${theme.mediaquery.sm(css`
      font-size: 1rem;
    `)}
    ${theme.mediaquery.md(css`
      font-size: 1.375rem;
    `)}
  `}
`;
const Thumbnail = ({ title, subtitle, image }) => {
  return (
    <Container>
      <ImgContainer>
        <StyledImg small={image.fluid} key={image.fluid.src} alt={title} />
      </ImgContainer>
      <TitleContainer>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
      </TitleContainer>
    </Container>
  );
};

Thumbnail.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.object,
};

export default Thumbnail;
