import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  id,
  title,
  titleTwo,
  subtitle,
  ctaText,
  imageDesktop,
  imageMobile,
  calculatorPageSlug,
}) => ({
  id,
  title,
  titleTwo,
  subtitle,
  ctaText,
  image: imageMobile &&
    imageDesktop && {
      small: getImageFields(imageMobile, IMG_TYPES.fluid),
      large: getImageFields(imageDesktop, IMG_TYPES.fluid),
    },
  calculatorPageSlug: calculatorPageSlug.slug,
});
