import { css } from 'styled-components';

import { mediaquery } from './breakpoints';

export const baseFontSize = 16;

const rem = (px, units = true) => {
  const r = px / baseFontSize;
  return units ? `${r}rem` : r;
};

export const lineHeight = (fontSizeInPixes, lineHeightInPixels) => {
  return lineHeightInPixels / fontSizeInPixes;
};

export const screenReaderOnly = () => css`
  position: absolute !important; /* Outside the DOM flow */
  height: 1px;
  width: 1px; /* Nearly collapsed */
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE 7+ only support clip without commas */
  clip: rect(1px, 1px, 1px, 1px); /* All other browsers */
`;

const fontWeights = Object.freeze({
  normal: 400,
  light: 300,
  bold: 700,
});

let baseFontFamily = '';

export const typesets = {
  titleXXL: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(160),
      lineHeight: lineHeight(160, 172),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(80),
      lineHeight: lineHeight(80, 87),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
  },
  titleXL: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(64),
      lineHeight: lineHeight(64, 70),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(40),
      lineHeight: lineHeight(40, 44),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
  },
  titleL: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(48),
      lineHeight: lineHeight(48, 52),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(32),
      lineHeight: lineHeight(32, 35),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
  },
  titleM: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(32),
      lineHeight: lineHeight(32, 36),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(24),
      lineHeight: lineHeight(24, 26),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
  },
  titleS: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(16),
      lineHeight: lineHeight(16, 18),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(16),
      lineHeight: lineHeight(16, 18),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
  },
  titleXS: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(12),
      lineHeight: lineHeight(12, 12),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04em',
      textTransform: 'uppercase',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(12),
      lineHeight: lineHeight(12, 12),
      fontWeight: fontWeights.bold,
      letterSpacing: '-0.04px',
      textTransform: 'uppercase',
    },
  },
  bodyXL: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(30),
      lineHeight: lineHeight(40, 42),
      fontWeight: fontWeights.normal,
      textTransform: 'none',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(22),
      lineHeight: lineHeight(20, 28),
      fontWeight: fontWeights.normal,
      textTransform: 'none',
    },
  },
  bodyL: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(20),
      lineHeight: lineHeight(20, 28),
      fontWeight: fontWeights.normal,
      textTransform: 'none',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(20),
      lineHeight: lineHeight(20, 28),
      fontWeight: fontWeights.normal,
      textTransform: 'none',
    },
  },
  bodyM: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(16),
      lineHeight: lineHeight(16, 24),
      fontWeight: fontWeights.normal,
      textTransform: 'none',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(16),
      lineHeight: lineHeight(16, 24),
      fontWeight: fontWeights.normal,
      textTransform: 'none',
    },
  },
  bodyS: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(16),
      lineHeight: lineHeight(16, 20),
      fontWeight: fontWeights.light,
      textTransform: 'none',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(16),
      lineHeight: lineHeight(16, 20),
      fontWeight: fontWeights.light,
      textTransform: 'none',
    },
  },
  footnote: {
    md: {
      fontFamily: baseFontFamily,
      fontSize: rem(12),
      lineHeight: lineHeight(12, 20),
      fontWeight: fontWeights.normal,
      letterSpacing: '-0.04px',
      textTransform: 'none',
    },
    sm: {
      fontFamily: baseFontFamily,
      fontSize: rem(12),
      lineHeight: lineHeight(12, 20),
      fontWeight: fontWeights.normal,
      letterSpacing: '-0.04px',
    },
    textTransform: 'none',
  },
};

const buildTypography = (key, market) => {
  const altFontMarkets = ['GR'];
  const useAltFont = altFontMarkets.includes(market);
  baseFontFamily = useAltFont
    ? `'AstyCF Std', Helvetica, Arial, sans-serif`
    : `'Futura Std', Helvetica, Arial, sans-serif`;

  return css`
    font-family: ${baseFontFamily};
    font-size: ${typesets[key].sm.fontSize};
    line-height: ${typesets[key].sm.lineHeight};
    font-weight: ${typesets[key].sm.fontWeight};
    letter-spacing: ${typesets[key].sm.letterSpacing};
    text-transform: ${typesets[key].sm.textTransform};

    ${typesets[key].md &&
    mediaquery.md(css`
      font-size: ${typesets[key].md.fontSize};
      line-height: ${typesets[key].md.lineHeight};
      font-weight: ${typesets[key].md.fontWeight};
      letter-spacing: ${typesets[key].md.letterSpacing};
      text-transform: ${typesets[key].md.textTransform};
    `)}
  `;
};

export const typography = (market) => {
  return Object.keys(typesets).reduce(
    (acc, key) => ({
      ...acc,
      [key]: buildTypography(key, market),
    }),
    { screenReaderOnly, fontWeights, typesets }
  );
};
