export default (settings, labels, pageContext) => ({
  ...settings,
  labels: labels.nodes,
  locale: pageContext.locale,
  domain: pageContext.domain,
  gtagID: pageContext.marketData.gtagID,
  market: pageContext.marketData.market,
  brandName: pageContext.marketData.brandName,
  localizedPageData: pageContext.localizedPageData,
  id: pageContext.id,
});
