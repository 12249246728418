import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({ id, items }) => ({
  id,
  items: items.map(({ id: itemId, title, description, picture, video }) => ({
    id: itemId,
    title,
    subtitle: description,
    picture: getImageFields(picture, IMG_TYPES.fluid),
    video: video && {
      cloudinaryVideo: {
        ...video,
      },
    },
  })),
});
