import React, { useState, useRef, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { rgba, throttle, breakpoints } from '@utils';
import { useWindowSize } from '@hooks';
import Img, { IMG_FITS } from '@atoms/Img';
import Play from '@icons/play.svg';
import Pause from '@icons/pause.svg';
import SoundOn from '@icons/sound-on.svg';
import SoundOff from '@icons/sound-off.svg';
import Expand from '@icons/expand.svg';
import Close from '@icons/close.svg';
import ProgressBar from '@atoms/ProgressBar';
import { useInView } from 'react-intersection-observer';

const triggerTrack = (event) => {
  dataLayer.push({
    event: 'video',
    videoType: 'HTML5 Video',
    videoTitle: `page: ${document.title}`,
    ...event,
  });
};

const VideoContainer = styled.div`
  width: ${({ isFullScreen }) => (isFullScreen ? '100vw' : '100%')};
  height: ${({ isFullScreen }) => (isFullScreen ? '100vh' : '100%')};
  position: ${({ isFullScreen }) => (isFullScreen ? 'fixed' : 'absolute')};
  z-index: ${({ isFullScreen, theme }) =>
    isFullScreen ? theme.zIndex.fullScreenVideo : null};
  top: 0;
  left: 0;
  background: #272727;
  border-radius: ${({ theme, isFullScreen, squareCorners }) =>
    isFullScreen || squareCorners ? '0' : theme.spacing(5)};
  overflow: hidden;
`;

const Poster = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ hasPlayed }) => (hasPlayed ? '1' : '0')};
  border-radius: ${({ theme, isFullScreen, squareCorners }) =>
    isFullScreen || squareCorners
      ? '0'
      : theme.spacing(5)}; /* border-radius fix for safari */
`;

const MediaButton = styled.button`
  ${({ theme }) => css`
    width: ${theme.spacing(5)};
    height: ${theme.spacing(5)};
    background: ${theme.colors.white};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: ${({ isPlaying }) => (isPlaying ? '0' : '1')};
    position: absolute;
    cursor: pointer;
    transition: opacity 1s cubic-bezier(0.47, 0, 0.37, 1) 0.3s;

    svg {
      margin: 0 auto;
    }

    path {
      fill: ${theme.colors.black};
    }
  `}

  ${VideoContainer}:hover & {
    opacity: 1;
  }
`;

const PlayButton = styled(MediaButton)`
  ${({ theme }) => css`
    bottom: ${theme.spacing(3)};
    left: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      bottom: ${theme.spacing(5)};
      left: ${theme.spacing(5)};
    `)}
  `}
`;

const FullScreenButton = styled(MediaButton)`
  ${({ theme }) => css`
    bottom: ${theme.spacing(3)};
    right: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      bottom: ${theme.spacing(5)};
      right: ${theme.spacing(5)};
    `)}
  `}
`;

const CloseFullScreenButton = styled(MediaButton)`
  ${({ theme }) => css`
    top: ${theme.spacing(3)};
    right: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      top: ${theme.spacing(5)};
      right: ${theme.spacing(5)};
    `)}
  `}
`;

const MuteButton = styled(MediaButton)`
  ${({ theme }) => css`
    bottom: ${theme.spacing(11)};
    left: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      bottom: ${theme.spacing(13)};
      left: ${theme.spacing(5)};
    `)}
  `}
`;

const VideoProgressBar = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  display: ${({ hasPlayed }) => (hasPlayed ? 'block' : 'none')};
`;

const VideoTime = styled.p`
  ${({ theme }) => css`
    ${theme.typography.footnote};
    position: absolute;
    padding: 5px;
    bottom: 16px;
    background: ${theme.colors.white};
    color: ${theme.colors.black};
    border-radius: 3px;
    opacity: ${({ position }) => (position ? '1' : '0')};
    transition: opacity 300ms cubic-bezier(0.47, 0, 0.37, 1);
    z-index: 2;
    left: ${({ position }) => position || '-100'}px;
    transform: translateX(-50%);
    box-shadow: 0 4px 10px ${rgba(theme.colors.black, 0.5)};
    display: inline-block;
  `}
`;

const VideoPush = ({
  cloudinaryVideo,
  mobileCloudinaryVideo,
  videoCover,
  squareCorners = false,
  disableFullscreen = false,
  autoplay = false,
  controls = true,
  threshold = 0.8,
  autopause = true,
  forceSound = false,
  ...rest
}) => {
  const [autoplayTriggered, setAutoplayedTriggered] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timestamp, setTimestamp] = useState([]);
  const [isMuted, setIsMuted] = useState(forceSound ? false : autoplay);
  const [isFullScreen, setFullScreen] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [showLandscapeVideo, setShowLandscapeVideo] = useState(false);
  const [protocol, setProtocol] = useState('secure_url');
  const [isDraggingProgrssBar, setIsDraggingProgressBar] = useState(false);
  const videoPlayer = useRef(null);
  const progressBar = useRef(null);
  const scrollY = useRef(0);
  const { ref, inView } = useInView({ threshold });
  const mobileVideo = mobileCloudinaryVideo || cloudinaryVideo;

  const [viewportWidth] = useWindowSize();

  /**
   * Full screen
   */

  const goFullScreen = () => {
    setFullScreen(true);
    scrollY.current = window.scrollY;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${scrollY.current}px`;
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, scrollY.current);
  };

  /**
   * Video controlling
   */

  const playVideo = useCallback(() => {
    videoPlayer.current.play();
    setIsPlaying(true);
    if (!hasPlayed) {
      setHasPlayed(true);
    }
    triggerTrack({ videoAction: 'Played video' });
  }, [hasPlayed]);

  const pauseVideo = useCallback(() => {
    videoPlayer.current.pause();
    setIsPlaying(false);
    if (videoPlayer.current.currentTime > 0) {
      triggerTrack({
        videoAction: 'Paused video',
        videoTimer: videoPlayer.current.currentTime,
      });
    }
  }, []);

  const toggleMuteVideo = useCallback(() => {
    setIsMuted(!isMuted);
  }, [isMuted]);

  const togglePlayVideo = () => {
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const endVideo = useCallback(() => {
    setVideoProgress(0);
    setIsPlaying(false);
    setHasPlayed(false);
    setTimestamp([]);
    if (isFullScreen) {
      closeFullScreen();
    }
  }, [isFullScreen]);

  /**
   * Hover/current position & time of video
   */

  const formatTime = (time) => {
    const totalSeconds = time.toFixed();
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
    const seconds = totalSeconds - hours * 3600 - minutes * 60;
    // eslint-disable-next-line no-nested-ternary
    const uiHours = hours ? (hours < 10 ? `0${hours}:` : `${hours}:`) : '';
    // eslint-disable-next-line no-nested-ternary
    const uiMinutes = minutes
      ? minutes < 10
        ? `0${minutes}:`
        : `${minutes}:`
      : '00:';
    const uiSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${uiHours}${uiMinutes}${uiSeconds}`;
  };

  const getPercentagePlayed = useCallback(
    () =>
      hasPlayed
        ? videoPlayer.current.currentTime / videoPlayer.current.duration
        : 0,
    [hasPlayed]
  );

  const handleVideoTimeUpdate = useCallback(() => {
    const p = getPercentagePlayed();

    if (p > 0) {
      triggerTrack({ videoAction: `${p}%` });
    }
  }, [getPercentagePlayed]);

  const handleVideoEnded = useCallback(() => {
    triggerTrack({ videoAction: '100%' });
  }, []);

  const getCurrentPosition = useCallback(() => {
    const rect = videoPlayer.current.getBoundingClientRect();
    const percentagePlayed = getPercentagePlayed();
    return rect.width * percentagePlayed;
  }, [getPercentagePlayed]);

  const getHoverTime = useCallback((hoverPosition) => {
    const rect = progressBar.current.getBoundingClientRect();
    const percentage = hoverPosition / rect.width;
    return videoPlayer.current.duration * percentage;
  }, []);

  /**
   * Timestamp
   */

  const removeTimestamp = () => {
    setTimestamp([]);
  };

  const updateTimestamp = useCallback(
    (time, position) => {
      if (hasPlayed) {
        const formattedTime = formatTime(time);
        setTimestamp([formattedTime, position]);
      }
    },
    [hasPlayed]
  );

  /**
   * Video position and progress bar
   */

  // Updates the current play time of the video to one selected in the progress bar
  const changeVideoProgress = useCallback(
    (time, position) => {
      videoPlayer.current.currentTime = time;
      const percentagePlayed = getPercentagePlayed();
      updateTimestamp(time, position);
      setVideoProgress(percentagePlayed);
    },
    [getPercentagePlayed, updateTimestamp]
  );

  // Updates the progress bar length
  const updateVideoProgressPosition = useCallback(() => {
    const progress = getPercentagePlayed();
    setVideoProgress(progress);
  }, [getPercentagePlayed]);

  /**
   * Progress bar events
   */
  const progressDragStart = useCallback(
    (e) => {
      setIsDraggingProgressBar(true);
      const time = getHoverTime(e.layerX);
      const position = e.layerX;
      changeVideoProgress(time, position);
    },
    [changeVideoProgress, getHoverTime]
  );

  const progressDrag = useCallback(
    (e) => {
      const time = getHoverTime(e.layerX);
      const position = e.layerX;
      if (isDraggingProgrssBar) {
        changeVideoProgress(time, position);
      } else {
        updateTimestamp(time, position);
      }
    },
    [changeVideoProgress, getHoverTime, isDraggingProgrssBar, updateTimestamp]
  );

  const progressDragEnd = useCallback(() => {
    if (isDraggingProgrssBar) {
      setIsDraggingProgressBar(false);
    }
    removeTimestamp();
  }, [isDraggingProgrssBar]);

  useEffect(() => {
    if (!isPlaying && !isDraggingProgrssBar) {
      const time = videoPlayer.current.currentTime;
      const position = getCurrentPosition();
      updateTimestamp(time, position);
    } else {
      removeTimestamp();
    }
  }, [isPlaying, isDraggingProgrssBar, getCurrentPosition, updateTimestamp]);

  useEffect(() => {
    if (window.location.protocol === 'http:') {
      setProtocol('url');
    }

    const videoPlayerCurrent = videoPlayer.current;
    const progressBarCurrent = progressBar.current;

    const throttledProgressBarMove = (e) => throttle(progressDrag(e), 100);

    videoPlayerCurrent.addEventListener(
      'timeupdate',
      updateVideoProgressPosition
    );
    videoPlayerCurrent.addEventListener('ended', endVideo);

    if (controls) {
      progressBarCurrent.addEventListener('click', updateVideoProgressPosition);
      progressBarCurrent.addEventListener('mousedown', progressDragStart);
      progressBarCurrent.addEventListener(
        'mousemove',
        throttledProgressBarMove
      );
      progressBarCurrent.addEventListener('mouseout', progressDragEnd);
    }

    return () => {
      videoPlayerCurrent.removeEventListener(
        'timeupdate',
        updateVideoProgressPosition
      );
      videoPlayerCurrent.removeEventListener('ended', endVideo);

      if (controls) {
        progressBarCurrent.removeEventListener(
          'click',
          updateVideoProgressPosition
        );
        progressBarCurrent.removeEventListener('mousedown', progressDragStart);
        progressBarCurrent.removeEventListener(
          'mousemove',
          throttledProgressBarMove
        );
        progressBarCurrent.removeEventListener('mouseout', progressDragEnd);
      }
    };
  }, [
    controls,
    endVideo,
    progressDrag,
    progressDragEnd,
    progressDragStart,
    updateVideoProgressPosition,
  ]);

  useEffect(() => {
    if (viewportWidth >= breakpoints.sm) {
      setShowLandscapeVideo(true);
    } else {
      setShowLandscapeVideo(false);
    }
  }, [viewportWidth]);

  useEffect(() => {
    if (!hasPlayed && !isPlaying) {
      videoPlayer.current.load();
    }
  }, [hasPlayed, isPlaying, showLandscapeVideo]);

  useEffect(() => {
    return () => {
      document.body.style.position = '';
    };
  }, []);

  useEffect(() => {
    if (inView && autoplay && !autoplayTriggered) {
      playVideo();
      setAutoplayedTriggered(true);
    }
  }, [inView, autoplayTriggered, autoplay, playVideo, toggleMuteVideo]);

  useEffect(() => {
    if (!inView && autopause) {
      pauseVideo();
    }
  }, [inView, autopause, pauseVideo]);

  return (
    <div {...rest} ref={ref}>
      <VideoContainer
        isFullScreen={isFullScreen}
        squareCorners={squareCorners}
        isPlaying={isPlaying}
      >
        {!hasPlayed && (
          <Poster
            fit={IMG_FITS.cover}
            small={videoCover.small}
            medium={videoCover.large}
          />
        )}
        <Video
          ref={videoPlayer}
          muted={isMuted}
          poster="data:image/gif,AAAA"
          onClick={controls ? togglePlayVideo : undefined}
          onEnded={handleVideoEnded}
          onTimeUpdate={handleVideoTimeUpdate}
          playsInline
          hasPlayed={hasPlayed}
          isFullScreen={isFullScreen}
          squareCorners={squareCorners}
          autoplay={inView && autoplay && !autoplayTriggered}
          src={
            showLandscapeVideo
              ? cloudinaryVideo[protocol]
              : mobileVideo[protocol]
          }
          type={
            showLandscapeVideo
              ? `video/${cloudinaryVideo.format}`
              : `video/${mobileVideo.format}`
          }
        >
          {`${"Sorry, your browser doesn't support embedded videos."}`}
        </Video>
        {controls && (
          <>
            <VideoProgressBar
              progress={videoProgress}
              className="progress-bar"
              ref={progressBar}
              hasPlayed={hasPlayed}
            />
            <PlayButton onClick={togglePlayVideo} title="Play">
              {isPlaying ? <Pause /> : <Play />}
            </PlayButton>
          </>
        )}
        {controls && hasPlayed && (
          <MuteButton
            onClick={toggleMuteVideo}
            title={isMuted ? 'Unmite' : 'Mute'}
          >
            {isMuted ? <SoundOff /> : <SoundOn />}
          </MuteButton>
        )}
        {(isFullScreen && (
          <CloseFullScreenButton onClick={closeFullScreen} title="Close">
            <Close
              css={css`
                width: 24px;
                height: 24px;
              `}
            />
          </CloseFullScreenButton>
        )) ||
          (controls && hasPlayed && !disableFullscreen && (
            <FullScreenButton onClick={goFullScreen} title="Full screen">
              <Expand />
            </FullScreenButton>
          ))}
      </VideoContainer>
      <VideoTime position={timestamp[1]}>{timestamp[0]}</VideoTime>
    </div>
  );
};

VideoPush.propTypes = {
  cloudinaryVideo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    secure_url: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
  }).isRequired,
  mobileCloudinaryVideo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    secure_url: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
  }),
  videoCover: PropTypes.shape({
    small: PropTypes.object.isRequired,
    large: PropTypes.object.isRequired,
  }),
  squareCorners: PropTypes.bool,
  disableFullscreen: PropTypes.bool,
  autoplay: PropTypes.bool,
  controls: PropTypes.bool,
  threshold: PropTypes.number,
  autopause: PropTypes.bool,
  forceSound: PropTypes.bool,
};

export default VideoPush;
