import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buttonStyle } from '@atoms/Button';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import { rgba } from '@utils';
import Link from '@atoms/Link';
import { grid } from '@styles/grid';

const Wrapper = styled.div`
  position: relative;
  display: block;

  ${({ fullBleed }) =>
    !fullBleed &&
    css`
      ${grid};
    `}
`;

const StyledImg = styled(Img)`
  width: 100%;
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;

  ${({ fullBleed, theme }) =>
    !fullBleed &&
    css`
      grid-column: 1 / -1;
      ${theme.mediaquery.md(css`
        grid-column: 5 / -5;
      `)}
    `}
`;

const ImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ theme }) => css`
    background-image: linear-gradient(
      to top,
      ${rgba(theme.colors.black, 0)},
      ${theme.colors.black}
    );
  `}
`;

const ContentContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  bottom: 10%;
  top: 0;
  flex-direction: column;
  justify-content: flex-end;
  width: 100vw;
`;

const TextContainer = styled.div`
  display: grid;
  place-items: center;
`;

const Title = styled.p`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    padding: 0 var(--outer-gap);
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    ${theme.mediaquery.sm(css`
      max-width: 500px;
    `)}
  `}
`;

const button = css`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin-top: ${theme.spacing(8)};
  `}
`;

const StyledLink = styled(Link)`
  ${button};
`;

const Button = styled.p`
  ${button};
`;

const ImagePush = ({
  pictures,
  cta,
  textOverlay,
  isCategoryPush = false,
  fullWidth = true,
  showGradient = true,
  ...rest
}) => {
  const containerLinkProps = isCategoryPush && { ...cta };

  return (
    <Wrapper
      as={isCategoryPush && Link}
      {...containerLinkProps}
      fullBleed={fullWidth}
      {...rest}
    >
      <Inner fullBleed={fullWidth}>
        <StyledImg
          type={IMG_TYPES.fluid}
          fit={IMG_FITS.cover}
          small={pictures.mobile}
          medium={pictures.desktop}
        />
        {showGradient && <ImageOverlay />}
        {(textOverlay || cta) && (
          <ContentContainer>
            <TextContainer>
              {textOverlay && <Title>{textOverlay}</Title>}
              {cta &&
                (isCategoryPush ? (
                  <Button>{cta.label}</Button>
                ) : (
                  <StyledLink {...cta} />
                ))}
            </TextContainer>
          </ContentContainer>
        )}
      </Inner>
    </Wrapper>
  );
};

ImagePush.propTypes = {
  pictures: PropTypes.object.isRequired,
  cta: PropTypes.instanceOf(Object),
  url: PropTypes.string,
  textOverlay: PropTypes.string,
  isCategoryPush: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showGradient: PropTypes.bool,
};

export default ImagePush;
