import { LINK_TYPES } from './Link';

const buildInternalLink = ({ slug, productCategory }) =>
  productCategory ? `${productCategory.slug}/${slug}` : slug;

export default ({
  id,
  label,
  target,
  title,
  internalLink,
  externalLink,
  onClickScript,
}) => ({
  id,
  label,
  target,
  title,
  url: (internalLink && buildInternalLink(internalLink)) || externalLink,
  type: internalLink ? LINK_TYPES.internal : LINK_TYPES.external,
  clickScript: onClickScript && onClickScript.value,
  isProduct: internalLink && internalLink.productCategory,
});
