import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, A11y, Pagination]);

const SwiperContainer = styled.div`
  ${({ color, theme }) => css`
    height: 100%;
    width: 100%;
    .swiper-container {
      padding: 0;
      width: 100%;

      .carousel-dot--active {
        background-color: ${color || theme.colors.white};
      }
    }
  `}
`;

const PaginationContainer = styled.ul`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(1)};
    display: flex;
    justify-content: center;
    li:not(:last-of-type) {
      margin-right: 6px;
    }

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(2)};
    `)}
  `}
`;

const MiniCarousel = ({ id, children }) => {
  const [color, setColor] = useState('#fff');
  const classNames = {
    prev: `swiper-button-prev-${id}`,
    next: `swiper-button-next-${id}`,
  };

  return (
    <SwiperContainer color={color}>
      <Swiper
        slidesPerView="auto"
        navigation={{
          nextEl: `.${classNames.next}`,
          prevEl: `.${classNames.prev}`,
        }}
        allowSlideNext
        allowSlidePrev
        allowTouchMove
        onInit={({ realIndex }) => setColor(children[realIndex]?.props?.color)}
        onRealIndexChange={({ realIndex }) =>
          setColor(children[realIndex]?.props?.color)
        }
        pagination={{
          el: '.mini-carousel-pagination',
          bulletClass: 'carousel-dot',
          bulletActiveClass: 'carousel-dot--active',
          bulletElement: 'li',
        }}
      >
        {children}
        <PaginationContainer className="mini-carousel-pagination" />
      </Swiper>
    </SwiperContainer>
  );
};

MiniCarousel.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MiniCarousel;
