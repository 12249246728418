import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Card from '@molecules/Card';
import TextModule from '@atoms/TextModule';
import { grid } from '@styles/grid';
import { breakpoints } from '@utils';

const Container = styled.section``;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    padding: 0 var(--outer-gap);
    text-align: center;

    ${theme.mediaquery.sm(css`
      max-width: 500px;
      margin: 0 auto;
    `)}

    ${theme.mediaquery.md(css`
      ${theme.typography.titleM};
    `)}
  `}
`;

const Description = styled(TextModule)`
  ${({ theme }) => css`
    padding: 0 var(--outer-gap);
    margin-top: ${theme.spacing(4)};
    display: block;

    ${theme.mediaquery.sm(css`
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    `)}
  `}
`;

const List = styled.ul`
  ${grid};
  grid-row-gap: var(--inner-gap);
  max-width: calc(340px + var(--inner-gap) * 2);
  margin: 0 auto;
  margin-top: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      margin-left: auto;
      margin-right: auto;
      max-width: none;
    `)}
  `}
`;

const Item = styled.li`
  grid-column: 1 / -1;

  ${({ theme, single }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: 7 / span 12;
    `)}

    ${theme.mediaquery.md(css`
      grid-column: span 9;

      &:first-of-type {
        grid-column: ${single ? 9 : 4} / span 9;
      }
    `)}
  `}
`;

const EditorialPush = ({ title, description, cards }) => (
  <Container>
    <Title>{title}</Title>
    {!!description && (
      <Description enableRenderer doc={description} alignment="center" />
    )}
    <List>
      {cards.map((card) => (
        <Item key={card.id} single={cards.length === 1}>
          <Card
            {...{
              ...card,
              background: {
                ...card.background,
                sizes: `(min-width: ${breakpoints.xs}px) 340px, (min-width: ${breakpoints.sm}px) 44vw, (min-width: ${breakpoints.md}px) 34vw, 87vw`,
              },
            }}
            highlighted
          />
        </Item>
      ))}
    </List>
  </Container>
);

EditorialPush.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)).isRequired,
};

export default EditorialPush;
