import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useInView } from 'react-intersection-observer';

import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';

export const SIZE = 380; // in pixels, width and height
const SPEED = 7; // seconds per image
const REPEAT = 4; // times to repeat the sequence (think wide screens)

const Outer = styled.div`
  position: relative;
  height: ${SIZE}px;
  overflow: hidden;
`;
const scroll = keyframes`
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    /* go to the second-to-last repetition */
    transform: translate3d(-${((REPEAT - 1) / REPEAT) * 100}%, 0, 0);
  }
`;

const Inner = styled.div`
  animation: ${scroll} ${({ items }) => SPEED * REPEAT * (items - 1)}s linear
    infinite ${({ animating }) => (animating ? 'running' : 'paused')};
  position: absolute;
  z-index: 0;
  display: flex;
  height: 100%;
`;

const Item = styled.div`
  width: ${SIZE}px;
  margin: 0 10px;

  picture {
    width: 100%;
    height: 100%;
  }
`;

const PictureCarousel = ({ pictures, ...rest }) => {
  const [ref, inView] = useInView();
  return (
    <Outer {...rest} ref={ref}>
      <Inner animating={inView} items={pictures.length}>
        {Array.from(new Array(REPEAT)).map((_, key) =>
          pictures.map((picture) => (
            // eslint-disable-next-line react/no-array-index-key
            <Item key={`${key}_${picture.id}`}>
              <Img
                type={IMG_TYPES.fixed}
                fit={IMG_FITS.cover}
                small={picture}
              />
            </Item>
          ))
        )}
      </Inner>
    </Outer>
  );
};

PictureCarousel.propTypes = {
  pictures: PropTypes.arrayOf(PropTypes.object),
};

export default React.memo(PictureCarousel);
