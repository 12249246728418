import { IMG_TYPES } from '@atoms/Img';
import placeholder from '@atoms/Img/Placeholder';

const mock = {
  slug: 'the-upgrade',
  image: placeholder(600, 880, IMG_TYPES.fluid),
  influencers: [
    {
      name: 'Name',
      categories: 'Nutrition & Wellness',
      picture: placeholder(40, 40, IMG_TYPES.fixed),
    },
    {
      name: 'Fe',
      categories: 'Workout',
      picture: placeholder(40, 40, IMG_TYPES.fixed),
    },
  ],
  headline: 'The Upgrade',
  subHeadline: 'Optimised to get you further. Faster. With Fe & Manuella.',
  ctaLabel: 'Discover the program',
};

export default mock;
