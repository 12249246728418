import React, { useRef, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import styled, { css } from 'styled-components';
import { RelatedCardsCarousel } from '@organisms/RelatedCards';
import scrollTo from 'gatsby-plugin-smoothscroll';
import Button, { BUTTON_TYPES } from '../../atoms/Button';

// Contains all three.js / canvas related code
import MuscleSelectorScene from './Scene/MuscleSelectorScene';

const ConfirmButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(3)};
`;
const RestartButton = styled(Button)`
  margin-left: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(3)};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      flex-direction: row;
    `)}
  `}
`;

const RelatedCardsCarouselContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FeaturedArticles = styled(RelatedCardsCarousel)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(8)};

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(10)};
    `)}
  `}
`;

const Canvas = styled.canvas`
  width: 350px;
  height: 350px;
  cursor: pointer;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      width: 600px;
      height: 600px;
    `)}
  `}
`;

const Side = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2)};

  ${({ theme }) => css`
    ${theme.mediaquery.md(css`
      flex: 0 1 40%;
    `)}
  `}

  & .fade-enter {
    opacity: 0;
  }
  & .fade-enter-active {
    opacity: 1;
  }
  & .fade-exit {
    opacity: 1;
  }
  & .fade-exit-active {
    opacity: 0;
  }
  & .fade-enter-active {
    transition: opacity 500ms ease-out;
  }
  & .fade-exit-active {
    transition: opacity 500ms ease-in;
  }
`;

const Title = styled.h2`
  ${({ theme }) => theme.typography.titleXL};
  color: ${({ theme }) => theme.colors.white};
  max-width: 350px;
`;

const BorderTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.black};
  text-shadow: ${({ theme }) => theme.colors.textShadowWhite};
`;

const Subtitle = styled.h4`
  ${({ theme }) => theme.typography.titleS};
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const MuscleTool = (props) => {
  const {
    backCtaLabel,
    muscleGroupLabel,
    selectABodyTypeLabel,
    selectAMuscleGroupLabel,
    selectWorkoutsCtaLabel,
    targetedWorkoutsLabel,
    relatedContent,
  } = props;

  const scene = useRef();
  const canvas = useRef(null);

  const [selectedMuscle, setSelectectedMuscle] = useState('');
  const [selectedBodyType, setSelectedBodyType] = useState('');
  const [sideState, setSideState] = useState(0);
  const [isRelatedVisible, setIsRelatedVisible] = useState(false);
  const [isReadyToScroll, setIsReadyToScroll] = useState(false);

  const onMuscleSelect = (muscle) => {
    setIsRelatedVisible(false);
    setSelectectedMuscle(muscle);
  };

  useEffect(() => {
    if (!scene.current) {
      scene.current = new MuscleSelectorScene(
        canvas.current,
        setSelectedBodyType,
        onMuscleSelect,
        relatedContent
      );
    }
  }, [scene, relatedContent]);

  const goBack = () => {
    setIsRelatedVisible(false);
    scene.current.goBack();
  };

  const confirmSelectedMuscle = () => {
    if (
      selectedMuscle.length &&
      relatedContent[selectedMuscle]?.articles[0].cards
    ) {
      setIsRelatedVisible(true);
      setIsReadyToScroll(true);
    } else {
      setIsRelatedVisible(false);
    }
  };

  useEffect(() => {
    if (isRelatedVisible && isReadyToScroll) {
      scrollTo('#related-carousel');
      setIsReadyToScroll(false);
    }
  }, [isRelatedVisible, isReadyToScroll]);

  useEffect(() => {
    if (selectedBodyType.length === 0) {
      setSideState(0);
    } else if (selectedMuscle.length !== 0) {
      setSideState(2);
    } else {
      setSideState(1);
    }
  }, [selectedBodyType, selectedMuscle]);

  return (
    <>
      <Container>
        <Canvas ref={canvas} />

        <Side>
          <div>
            <SwitchTransition mode="out-in">
              <CSSTransition
                classNames="fade"
                key={`${sideState}_${selectedMuscle}`}
                timeout={500}
              >
                <div>
                  <Subtitle>{targetedWorkoutsLabel}</Subtitle>
                  {sideState === 0 && <Title>{selectABodyTypeLabel}</Title>}
                  {sideState === 1 && <Title>{selectAMuscleGroupLabel}</Title>}
                  {sideState === 2 && selectedMuscle.length !== 0 && (
                    <>
                      <BorderTitle> {muscleGroupLabel}</BorderTitle>
                      <Title>{relatedContent[selectedMuscle]?.name}</Title>
                      <div>
                        <RestartButton
                          type={BUTTON_TYPES.secondary}
                          onClick={goBack}
                        >
                          {backCtaLabel}
                        </RestartButton>
                        <ConfirmButton onClick={confirmSelectedMuscle}>
                          {selectWorkoutsCtaLabel}
                        </ConfirmButton>
                      </div>
                    </>
                  )}
                </div>
              </CSSTransition>
            </SwitchTransition>
          </div>
        </Side>
      </Container>
      {isRelatedVisible && (
        <RelatedCardsCarouselContainer id="related-carousel">
          {relatedContent[selectedMuscle]?.articles[0].cards && (
            <FeaturedArticles
              cards={relatedContent[selectedMuscle]?.articles[0].cards}
            />
          )}
        </RelatedCardsCarouselContainer>
      )}
    </>
  );
};

MuscleTool.propTypes = {
  relatedContent: PropTypes.object.isRequired,
  backCtaLabel: PropTypes.string,
  muscleGroupLabel: PropTypes.string,
  selectABodyTypeLabel: PropTypes.string,
  selectAMuscleGroupLabel: PropTypes.string,
  selectWorkoutsCtaLabel: PropTypes.string,
  targetedWorkoutsLabel: PropTypes.string,
};

export default MuscleTool;
