import { muscleCardAdapter } from '@organisms/RelatedCards';

export default ({
  id,
  abs,
  bicep,
  chest,
  glutes,
  lowerLeg,
  lowerBack,
  shoulder,
  tricep,
  upperBack,
  upperLeg,
  absLabel,
  backCtaLabel,
  bicepLabel,
  chestLabel,
  glutesLabel,
  lowerLegLabel,
  shoulderLabel,
  lowerBackLabel,
  muscleGroupLabel,
  selectABodyTypeLabel,
  selectAMuscleGroupLabel,
  selectWorkoutsCtaLabel,
  targetedWorkoutsLabel,
  tricepLabel,
  upperBackLabel,
  upperLegLabel,
}) => {
  const selectableMuscles = {
    abs: {
      name: absLabel,
      articles: abs && [muscleCardAdapter(abs)],
    },
    biceps: {
      name: bicepLabel,
      articles: bicep && [muscleCardAdapter(bicep)],
    },
    chest: {
      name: chestLabel,
      articles: chest && [muscleCardAdapter(chest)],
    },
    glutes: {
      name: glutesLabel,
      articles: glutes && [muscleCardAdapter(glutes)],
    },
    lowerLegs: {
      name: lowerLegLabel,
      articles: lowerLeg && [muscleCardAdapter(lowerLeg)],
    },
    lowerBack: {
      name: lowerBackLabel,
      articles: lowerBack && [muscleCardAdapter(lowerBack)],
    },
    shoulders: {
      name: shoulderLabel,
      articles: shoulder && [muscleCardAdapter(shoulder)],
    },
    triceps: {
      name: tricepLabel,
      articles: tricep && [muscleCardAdapter(tricep)],
    },
    upperBack: {
      name: upperBackLabel,
      articles: upperBack && [muscleCardAdapter(upperBack)],
    },
    upperLegs: {
      name: upperLegLabel,
      articles: upperLeg && [muscleCardAdapter(upperLeg)],
    },
  };

  return {
    id,
    abs,
    bicep,
    chest,
    glutes,
    lowerLeg,
    lowerBack,
    shoulder,
    tricep,
    upperBack,
    upperLeg,
    absLabel,
    backCtaLabel,
    bicepLabel,
    chestLabel,
    lowerBackLabel,
    muscleGroupLabel,
    selectABodyTypeLabel,
    selectAMuscleGroupLabel,
    selectWorkoutsCtaLabel,
    targetedWorkoutsLabel,
    tricepLabel,
    upperBackLabel,
    upperLegLabel,
    relatedContent: selectableMuscles,
  };
};
