export default function throttle(callback, limit) {
  let tick = false;
  return function () {
    if (!tick) {
      callback();
      tick = true;
      setTimeout(function () {
        tick = false;
      }, limit);
    }
  };
}
