import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import Carousel from '@molecules/Carousel';
import Thumbnail from '@molecules/Thumbnail';
import Tooltip from '@atoms/Tooltip/Tooltip';

const Container = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    display: block;
    margin-bottom: ${theme.spacing(10)};
    .swiper-title {
      text-align: left;
      padding-left: var(--outer-gap);
      ${({ biggerCards, biggerCardsTitle }) =>
        biggerCards || biggerCardsTitle
          ? theme.typography.titleXL
          : theme.typography.titleM};

      ::before {
        display: none;
      }

      ${theme.mediaquery.sm(
        css`
          padding-left: calc(var(--outer-gap) + var(--col) * 2);
          ${({ biggerCards }) =>
            biggerCards ? theme.typography.titleXL : theme.typography.titleL};
        `
      )}

      ${theme.mediaquery.md(
        css`
          padding-left: calc(var(--outer-gap) + var(--col) * 3);
          max-width: 50%;
          box-sizing: content-box;
        `
      )}
    }

    .swiper-slide {
      ${({ biggerCards }) =>
        biggerCards
          ? css`
              width: calc(var(--col) * 4 - var(--inner-gap));

              ${theme.mediaquery.sm(
                css`
                  width: calc(var(--col) * 14 - var(--inner-gap));
                  &:not(:last-child) {
                    margin-right: calc(var(--inner-gap) + var(--col));
                  }
                `
              )}

              ${theme.mediaquery.md(
                css`
                  width: calc(var(--col) * 8 - var(--inner-gap));
                `
              )}
            `
          : css`
              width: calc(var(--col) * 3 - var(--inner-gap));

              ${theme.mediaquery.sm(
                css`
                  width: calc(var(--col) * 9 - var(--inner-gap));
                `
              )}

              ${theme.mediaquery.md(
                css`
                  width: calc(var(--col) * 6 - var(--inner-gap));
                `
              )}
            `}
    }
  `}
`;

const Title = styled.h2`
  display: flex;
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    padding: 0 var(--inner-gap);
    margin-bottom: ${theme.spacing(3)};

    ${theme.mediaquery.md(css`
      box-sizing: content-box;
    `)}

    ${theme.mediaquery.sm(css`
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const RelatedThumbnailsCarousel = ({ cards, title, className, tooltip }) => {
  return (
    <Container className={className}>
      {!!title && <Title>{title}</Title>}
      <Carousel>
        {cards.map((card, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={`${idx}_${card.title}`} className="swiper-slide">
            <Thumbnail
              title={
                cards.length === 5 && idx === 1 ? (
                  <div style={{ display: 'flex' }}>
                    {card.title}
                    {cards.length === 5 && idx === 1 && (
                      <Tooltip tooltip={tooltip} top />
                    )}
                  </div>
                ) : (
                  card.title
                )
              }
              subtitle={card.subtitle}
              image={card.thumbnail}
            />
          </SwiperSlide>
        ))}
      </Carousel>
    </Container>
  );
};

RelatedThumbnailsCarousel.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.shape(Thumbnail.propTypes)),
  className: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

export default RelatedThumbnailsCarousel;
