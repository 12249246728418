export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
};

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.state && location.state.keepScrollPosition) {
    return false;
  }
  return true;
};
