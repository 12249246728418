import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Card from '@molecules/Card';
import SectionTitle from '@atoms/SectionTitle';
import { grid } from '@styles/grid';
import { breakpoints } from '@utils';

export const HIGHLIGHTED_CARD = Object.freeze({
  first: 0,
  third: 2,
});

const List = styled.ul`
  ${grid};
  grid-row-gap: var(--inner-gap);
  max-width: calc(340px + var(--inner-gap) * 2);
  margin: 0 auto;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      --columns: 8;
      padding: 0 calc(var(--outer-gap) + var(--col) * 6);
      margin: auto;
      max-width: none;
    `)}

    ${theme.mediaquery.md(css`
      --columns: 12;
      padding: 0 calc(var(--outer-gap) + var(--col) * 3);
      grid-auto-flow: column;
    `)}
  `}
`;

const Item = styled.li`
  grid-column: 1 / -1;

  ${({ theme, highlighted }) => css`
    ${theme.mediaquery.xs(css`
      grid-column: ${!highlighted && 'span 2'};
    `)}

    ${theme.mediaquery.sm(css`
      grid-column: ${!highlighted && 'span 4'};
    `)}

    ${theme.mediaquery.md(css`
      grid-column: ${highlighted ? 'span 6' : 'span 3'};
      grid-row: ${highlighted ? 'span 2' : 'initial'};
    `)}
  `}
`;

const regularSizes = `(min-width: ${breakpoints.xs}px) 158px, (min-width: ${breakpoints.sm}px) 21vw, (min-width: ${breakpoints.md}px) 16vw, 87vw`;
const highlightedSizes = `(min-width: ${breakpoints.xs}px) 340px, (min-width: ${breakpoints.sm}px) 44vw, (min-width: ${breakpoints.md}px) 34vw, 87vw`;

const RelatedCards = ({ title, cards, highlightedCard }) => (
  <div>
    {!!title && <SectionTitle title={title} />}
    <List>
      {cards.map((card, index) => (
        <Item key={card.id} highlighted={index === highlightedCard}>
          <Card
            {...{
              ...card,
              background: {
                ...card.background,
                sizes:
                  index === highlightedCard ? highlightedSizes : regularSizes,
              },
            }}
            highlighted={index === highlightedCard}
          />
        </Item>
      ))}
    </List>
  </div>
);

RelatedCards.propTypes = {
  title: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)),
  highlightedCard: PropTypes.number.isRequired,
};

export default RelatedCards;
