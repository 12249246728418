import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { graphql } from 'gatsby';
import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import { getSettings, getPageMeta } from '@utils';
import Disclaimer from '@molecules/Disclaimer';
import { buttonStyle } from '@atoms/Button';
import PageComponents from './PageComponents';
import trackEvent from './trackEvent';

const HCPPage = ({ data, pageContext, path }) => {
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = data.contentfulHcpPage;
  const pageSeo = getPageMeta(page.seo, settings.brandName);
  // Check if window is defined (so if in the browser or in node.js).
  const isBrowser = typeof window !== 'undefined';
  const disclaimer = data.contentfulSettings.allHcpPageDisclaimer;

  const [disclaimerVisible, setDisclaimerVisible] = useState(true);
  const [isSavedInStorage, setIsSavedInStorage] = useState(
    isBrowser &&
      JSON.parse(
        localStorage.getItem(`${disclaimer?.disclaimerTitle} displayed`) ===
          'true'
      )
  );

  const handleLocalStorage = () => {
    localStorage.setItem(`${disclaimer.disclaimerTitle} displayed`, 'true');
  };

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  const Wrapper = styled.div`
    width: 100vw;
    padding: ${({ theme }) => theme.spacing(25)}
      ${({ theme }) => theme.spacing(4)} 0 ${({ theme }) => theme.spacing(4)};
  `;

  const HCPButton = styled.button`
    ${({ theme }) => css`
      max-width: 25%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      ${buttonStyle(theme, 'primary')};
      position: fixed;
      bottom: ${theme.spacing(4)};
      right: ${theme.spacing(4)};
      ${theme.mediaquery.sm(css`
        max-width: initial;
      `)}
    `}
  `;

  return (
    <MainLayout settings={settings}>
      <Metadata path={path} meta={pageSeo} />
      <Wrapper>
        {isSavedInStorage && <PageComponents modules={page.pageComponents} />}
        <Disclaimer
          disclaimerTitle={disclaimer?.disclaimerTitle}
          disclaimerText={disclaimer?.disclaimerText}
          checkboxLabel={disclaimer?.checkboxLabel}
          buttonLabel={disclaimer?.buttonLabel}
          disclaimerVisible={disclaimerVisible}
          setDisclaimerVisible={setDisclaimerVisible}
          isSavedInStorage={isSavedInStorage}
          setIsSavedInStorage={setIsSavedInStorage}
          handleLocalStorage={handleLocalStorage}
        />
        <HCPButton
          onClick={() => {
            setDisclaimerVisible(true);
            localStorage.setItem(
              `${disclaimer.disclaimerTitle} displayed`,
              'false'
            );
            setIsSavedInStorage(false);
          }}
        >
          {disclaimer.agreementButtonLabel}
        </HCPButton>
      </Wrapper>
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getHCPPageData($nodeLocale: String!, $tag: String!, $id: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }

    contentfulHcpPage(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
      contentful_id: { eq: $id }
    ) {
      seo {
        ...Metadata
      }
      category {
        name
      }
      pageComponents {
        ...HcpPageComponents
      }
    }
  }
`;

HCPPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    localizedPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default HCPPage;
