import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_TYPES, IMG_FITS } from '@atoms/Img';
import { rgba } from '@utils';
import Link, { LINK_TYPES } from '@atoms/Link';

const Container = styled(Link)`
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing(5)};
  position: relative;
  cursor: pointer;
`;

const fullLayer = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Overlay = styled.div`
  ${fullLayer};
  background: ${({ theme }) => rgba(theme.colors.black, 0.4)};
`;

const Content = styled.div`
  ${fullLayer};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(3)};
`;

const TitleContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Title = styled.p`
  ${({ theme, highlighted }) => css`
    ${highlighted ? theme.typography.titleM : theme.typography.titleXS};
    color: ${theme.colors.white};
    padding-top: ${theme.spacing(1)};

    ${theme.mediaquery.sm(css`
      ${highlighted ? theme.typography.titleM : theme.typography.titleS};
    `)}
  `}
`;

const CardHCP = ({ background, title, highlighted, slug }) => {
  return (
    <Container title={title} url={slug} type={LINK_TYPES.internal}>
      <div css={fullLayer}>
        <Img small={background} type={IMG_TYPES.fluid} fit={IMG_FITS.cover} />
      </div>
      <Overlay />
      <Content>
        <TitleContainer>
          <Title highlighted={highlighted}>{title}</Title>
        </TitleContainer>
      </Content>
    </Container>
  );
};

CardHCP.propTypes = {
  background: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  highlighted: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

export default React.memo(CardHCP);
