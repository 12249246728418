import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  name,
  profilePictureDesktop,
  socialLinkFacebook,
  socialLinkInstagram,
  socialLinkTwitter,
  socialLinkWebsite,
  shortBio,
}) => {
  const social = [
    { icon: 'instagram', link: socialLinkInstagram },
    { icon: 'twitter', link: socialLinkTwitter },
    { icon: 'facebook', link: socialLinkFacebook },
    { icon: 'website', link: socialLinkWebsite },
  ].filter((s) => s.link);
  return {
    name,
    picture: getImageFields(profilePictureDesktop, IMG_TYPES.fluid),
    social,
    shortBio,
  };
};
