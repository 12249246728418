import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import GlobalProvider from '@stores/GlobalProvider';
import Scripts from '@atoms/Scripts';
import Navigation from '@organisms/Navigation';
import Footer from '@organisms/Footer';

import { SettingsShape } from '@stores/Settings';

const Main = styled.main`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.black};
  `}
`;

const MainLayout = ({ children, settings }) => {
  return (
    <GlobalProvider settings={settings}>
      <Scripts
        useProduction={process.env.GATSBY_TRACKING_ENV === 'production'}
      />
      <Navigation />
      <Main>{children}</Main>
      <Footer />
    </GlobalProvider>
  );
};

MainLayout.propTypes = {
  settings: PropTypes.shape(SettingsShape).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default MainLayout;
