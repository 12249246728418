import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';
import SwiperCore, { A11y, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import NavigationButton, { NAVIGATION_TYPES } from '@atoms/NavigationButton';
import { breakpoints } from '@utils';

SwiperCore.use([Navigation, A11y, Pagination]);

const PaginationContainer = styled.ul`
  ${({ theme }) => css`
    margin-top: ${theme.spacing(4)};
    display: flex;

    li:not(:last-of-type) {
      margin-right: 6px;
    }

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(8)};
    `)}
  `}
`;

const Controls = styled.div`
  ${({ theme }) => css`
    .carousel-button-prev,
    .carousel-button-next {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(calc(-50% - ${theme.spacing(4)} - 2px));
      z-index: 2;
      outline: none;
      ${theme.mediaquery.sm(
        css`
          display: flex;
        `
      )}
    }

    .carousel-button-next {
      right: var(--outer-gap);
      ${theme.mediaquery.sm(css`
        right: calc(var(--outer-gap) + var(--col) * 3 - var(--inner-gap));
      `)}
      ${theme.mediaquery.md(css`
        right: calc(var(--outer-gap) + var(--col) + var(--inner-gap));
      `)}
    }

    .carousel-button-prev {
      left: var(--outer-gap);
      ${theme.mediaquery.sm(css`
        left: calc(var(--outer-gap) + var(--col) * 3 - var(--inner-gap));
      `)}
      ${theme.mediaquery.md(css`
        left: calc(var(--outer-gap) + var(--col) + var(--inner-gap));
      `)}
    }

    .swiper-button-disabled {
      display: none;
      pointer-events: none;
    }
  `}
`;

const SwiperContainer = styled.div`
  ${({ theme }) => css`
    .swiper-container {
      padding: 0 var(--outer-gap);

      ${theme.mediaquery.sm(
        css`
          padding-left: calc(var(--outer-gap) + var(--col) * 2);
        `
      )}

      ${theme.mediaquery.md(
        css`
          padding-left: calc(var(--outer-gap) + var(--col) * 3);
        `
      )}
    }
  `}
`;

const Carousel = ({
  id,
  children,
  customSpacing = false,
  centeredCards = false,
  slidesGrouped = 1,
}) => {
  const classNames = {
    prev: `swiper-button-prev-${id}`,
    next: `swiper-button-next-${id}`,
  };

  return (
    <SwiperContainer>
      <Swiper
        spaceBetween={24}
        slidesPerGroup={slidesGrouped}
        slidesPerView="auto"
        navigation={{
          nextEl: `.${classNames.next}`,
          prevEl: `.${classNames.prev}`,
        }}
        allowSlideNext
        allowSlidePrev
        allowTouchMove
        centeredSlides={centeredCards}
        breakpoints={{
          [breakpoints.sm]: {
            spaceBetween: customSpacing ? 0 : 16,
          },
        }}
        pagination={{
          el: '.carousel-pagination',
          bulletClass: 'carousel-dot',
          bulletActiveClass: 'carousel-dot--active',
          bulletElement: 'li',
        }}
      >
        <Controls>
          <NavigationButton
            className={`${classNames.prev} carousel-button-prev`}
            direction={NAVIGATION_TYPES.left}
          />
          <NavigationButton
            className={`${classNames.next} carousel-button-next`}
            direction={NAVIGATION_TYPES.right}
          />
        </Controls>
        {children}
        <PaginationContainer className="carousel-pagination" />
      </Swiper>
    </SwiperContainer>
  );
};

Carousel.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  customSpacing: PropTypes.bool,
  centeredCards: PropTypes.bool,
  slidesGrouped: PropTypes.number,
};

export default Carousel;
