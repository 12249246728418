export default function debounce(func, wait, immediate) {
  let timeout;
  return function (...args) {
    const later = function () {
      timeout = null;
      if (!immediate) Reflect.apply(func, this, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) Reflect.apply(func, this, args);
  };
}
