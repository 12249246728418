import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SettingsContext } from '@stores/Settings';
import mappers from './mappers';

const Wrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    background: ${theme.colors.black};
    /* Safeguard of transparent line on blurred hero */
    margin-top: -1px;
    margin-bottom: -1px;
  `}
`;

const PageComponents = ({ modules, ...rest }) => {
  const { id } = useContext(SettingsContext);

  if (!modules) return null;
  return (
    <Wrapper className="main">
      {modules.map((properties) => {
        const child = mappers[properties.__typename];
        if (!child) {
          console.warn(
            `Skipping unsupported page component "${
              properties.__typename
            }", expecting one of: ${Object.keys(mappers).join(', ')}`
          );
          return null;
        }

        const { Component, mapper } = child;
        return (
          <Component
            key={`${properties.__typename}-${properties.id}`}
            {...mapper(properties, { id, ...rest })}
          />
        );
      })}
    </Wrapper>
  );
};

PageComponents.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object),
};

export default PageComponents;
