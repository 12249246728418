import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Lightningsmall from '@icons/xsmall-lightning.svg';

const Tag = styled.div`
  ${({ theme }) => css`
    display: inline-flex;
    align-items: center;
    height: ${theme.spacing(3)};
    ${theme.typography.titleXS};
    color: ${theme.colors.black};
    background: ${theme.colors.white};
    padding: 0 ${theme.spacing(2)} 0 ${theme.spacing(1)};
    border-radius: ${theme.spacing(2)};

    path {
      fill: ${theme.colors.black};
    }
  `}
`;

const InfluencerTag = ({ className, name }) => (
  <Tag className={className}>
    <Lightningsmall />
    <p>{name}</p>
  </Tag>
);

InfluencerTag.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
};

export default React.memo(InfluencerTag);
