import React, { useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import VideoPush from '@molecules/VideoPush';
import {
  HeroBlurred,
  HeroClassic,
  HeroPursuit,
  HeroText,
} from '@molecules/Hero';
import { useInView } from 'react-intersection-observer';
import { HeroContext } from '@stores/Hero';

const LAYOUTS = Object.freeze({
  blurred: 'blurred',
  classic: 'classic',
  pursuit: 'pursuit',
});

const Hero = (props) => {
  const { Component, layoutProps } = useMemo(() => {
    const {
      layout,
      headline,
      tagline,
      image,
      alignment,
      video,
      videoMode,
      videoCtaLabel,
      influencer,
      imageMobileRatio,
      imageDesktopRatio,
      cta,
      isFunnelFirst,
      path,
      pageId,
    } = props;

    if (!image) {
      return {
        Component: HeroText,
        layoutProps: {
          headline,
          alignment,
          influencer,
          cta,
        },
      };
    }

    switch (layout) {
      case LAYOUTS.blurred:
        return {
          Component: HeroBlurred,
          layoutProps: {
            headline,
            alignment,
            influencer,
            cta,
            image,
            video,
            path,
            pageId,
          },
        };
      case LAYOUTS.pursuit:
        return {
          Component: HeroPursuit,
          layoutProps: {
            headline,
            alignment,
            influencer,
            cta,
            image,
            video,
            videoMode,
            videoCtaLabel,
            tagline,
            isFunnelFirst,
            path,
            pageId,
          },
        };
      default:
        return {
          Component: HeroClassic,
          layoutProps: {
            headline,
            alignment,
            influencer,
            cta,
            image,
            video,
            videoMode,
            videoCtaLabel,
            tagline,
            imageDesktopRatio,
            imageMobileRatio,
            path,
            pageId,
          },
        };
    }
  }, [props]);

  const { setHeroIsVisible } = useContext(HeroContext);

  const [ref, inView] = useInView({ threshold: 0 });

  useEffect(() => {
    if (inView) {
      setHeroIsVisible(true);
    } else {
      setHeroIsVisible(false);
    }
  }, [inView, setHeroIsVisible]);

  return (
    <div ref={ref}>
      <Component {...layoutProps} />
    </div>
  );
};

Hero.propTypes = {
  layout: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.object,
  alignment: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  pageId: PropTypes.string.isRequired,
  video: PropTypes.shape(VideoPush.propTypes),
  videoMode: PropTypes.string,
  videoCtaLabel: PropTypes.string,
  tagline: PropTypes.string,
  influencer: PropTypes.object,
  cta: PropTypes.any,
  imageMobileRatio: PropTypes.number,
  imageDesktopRatio: PropTypes.number,
  isFunnelFirst: PropTypes.bool,
};

export default Hero;
