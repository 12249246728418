import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Bubble from '@atoms/Bubble';
import Disclaimer from '@icons/disclaimer.svg';

const StyledTooltip = styled.span`
  ${({ theme }) => css`
    display: flex;
    ${theme.mediaquery.sm(css`
      position: relative;
    `)};
  `}
`;

// default position on below Disclaimer on the right.
// left puts it on the left
// on mobile it centers on the device
// top will center in parent and move above Disclaimer
const StyledBubble = styled(Bubble)`
  ${({ theme, left, top, wide }) => css`
    position: absolute;
    left: ${top ? 'calc(100% / 2)' : 'unset'};
    left: ${left ? 'unset' : 0};
    right: ${left ? 0 : 'unset'};
    top: ${top ? 'unset' : 0};
    bottom: ${top ? 0 : 'unset'};

    min-width: ${wide ? '280px' : 'unset'};
    width: ${top ? '200px' : 'calc(100vw / 1.5)'};
    max-width: min(calc(100vw / 2), 400px);

    transform: ${top ? 'translate(0, -45px)' : 'translate(0, 30px)'};
    letter-spacing: 0;



    padding: ${theme.spacing(2)}


    > div:after {
      left: 16px;
      transform: none;
    }
  `}
`;

const StyledDisclaimer = styled(Disclaimer)`
  ${({ theme }) => css`
    pointer-events: initial;
    cursor: pointer;
    position: relative;
    margin-left: ${theme.spacing(1)};
    z-index: 1;
  `}
`;

const Tooltip = React.memo(({ tooltip, left, top }) => {
  const [bubbleOpened, setBubbleOpened] = useState(false);
  const wideBubble = tooltip && tooltip.length > 200;

  function clickHandler(e) {
    e.preventDefault();
    setBubbleOpened(true);
  }
  return (
    <>
      {tooltip && (
        <StyledTooltip
          onMouseEnter={() => setBubbleOpened(true)}
          onMouseLeave={() => setBubbleOpened(false)}
          onClick={(e) => clickHandler(e)}
        >
          <StyledDisclaimer />
          <StyledBubble
            onClose={() => setBubbleOpened(false)}
            isOpen={bubbleOpened}
            isPlusOneTooltip
            left={left}
            top={top}
            wide={wideBubble}
          >
            {tooltip}
          </StyledBubble>
        </StyledTooltip>
      )}
    </>
  );
});

Tooltip.propTypes = {
  tooltip: PropTypes.string,
  left: PropTypes.bool,
  top: PropTypes.bool,
};

export default Tooltip;
