import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import MasculineModel from '../models/masculine.gltf';
import FeminineModel from '../models/feminine.gltf';

import { MODEL_STATES, BODY_TYPES } from './States';
import { EasingFunctions } from './Easing';

const MODELS = Object.freeze({
  [BODY_TYPES.MASCULINE]: MasculineModel,
  [BODY_TYPES.FEMININE]: FeminineModel,
});

const COLORS = Object.freeze({
  DEFAULT: new THREE.Color('white'),
  HOVERED: new THREE.Color('orange'),
  SELECTED: new THREE.Color('orange'),
});

class MuscleHuman extends THREE.Object3D {
  constructor(bodyType, selectableMuscles) {
    super();

    this.fadeSpeed = {
      in: 0.2,
      out: 0.16,
      hover: 0.07,
    };

    this.targetOpacity = 1;

    this.rotationSpeed = 0.03;
    this.isRotating = false;
    this.moveSpeed = 0.09;

    this.bodyType = bodyType;

    this.state = MODEL_STATES.DEFAULT;

    this.name = bodyType;
    this.selectedMuscle = null;
    this.hoveredMuscle = null;

    this.position.z = -8;
    this.position.x = -4.5;

    if (MODELS[bodyType]) {
      this.setupModel(MODELS[bodyType], selectableMuscles);

      if (bodyType === BODY_TYPES.MASCULINE) {
        // this.rotation.y = Math.PI / 1;
        // this.rotationSpeed *= -1;
      } else {
        this.position.x *= -1;
      }
    }

    this.startPosition = this.position.clone();
    this.targetPosition = new THREE.Vector3(0, 0, 0);
    this.moveProgress = 0;
  }

  async setupModel(model, selectableMuscles) {
    const full = await MuscleHuman.loadModel(model);
    /** @todo THIS WILL CHANGED AND BE CLEANED UP WHEN I HAVE THE FINAL MODELS */
    const scale = 0.065;
    full.position.y = 6;
    full.scale.set(scale, scale, scale);
    full.name = `blocking_${this.bodyType}`;

    this.wireframeMaterial = new THREE.MeshBasicMaterial({
      color: 0xffffff,
      wireframe: true,
      transparent: true,
      opacity: 1,
    });

    const blockingMaterial = new THREE.MeshBasicMaterial({
      color: 0x000000,
      opacity: 0,
    });

    full.traverse((o) => {
      // eslint-disable-next-line no-param-reassign
      if (o.isMesh) o.material = blockingMaterial;
    });

    this.wireframeMesh = full.clone();

    this.wireframeMesh.name = `wireframe_${this.bodyType}`;

    this.wireframeMesh.traverse((o) => {
      // eslint-disable-next-line no-param-reassign
      if (o.isMesh) o.material = this.wireframeMaterial.clone();
    });

    this.wireframeMesh.children.forEach((child) => {
      /* eslint-disable no-param-reassign */
      child.visible = false;
      child.material.color = COLORS.DEFAULT;
      child.material.wireframe = true;
      /* eslint-enable no-param-reassign */
    });

    full.remove(...full.children);

    this.add(full);
    this.add(this.wireframeMesh);

    this.setSelectableMuscles(selectableMuscles);
  }

  setSelectableMuscles(muscles) {
    this.wireframeMesh?.children?.forEach((child) => {
      /* eslint-disable no-param-reassign */
      if (muscles[child?.name]?.articles?.length > 0) {
        child.selectable = true;
      } else {
        child.selectable = false;
      }
      /* eslint-enable no-param-reassign */
    });
  }

  static loadModel(model) {
    return new Promise((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.load(
        model,
        (gltf) => {
          resolve(gltf.scene.children[0]);
        },
        undefined,
        (error) => {
          reject(error);
        }
      );
    });
  }

  setState(state) {
    switch (state) {
      case MODEL_STATES.MOVE_IN:
        this.setMuscleVisibility(true);
        break;
      case MODEL_STATES.ACTIVE:
        this.moveProgress = 0;
        break;
      case MODEL_STATES.DEFAULT:
        this.setMuscleVisibility(false);
        this.wireframeMesh.material.opacity = 1;
        this.moveProgress = 0;
        break;
      case MODEL_STATES.HIDDEN:
        this.visible = false;
        this.wireframeMesh.material.opacity = 0;
        break;
      case MODEL_STATES.FADE_IN:
        this.visible = true;
        break;
      default:
        break;
    }

    this.state = state;
  }

  /**
   * Set rendering of the Muscle Meshes. Turned off when the human is not in
   * focus mode for better performance
   * @param {boolean} visible
   */
  setMuscleVisibility(visible) {
    this.wireframeMesh.children.forEach((child) => {
      if (child instanceof THREE.Mesh && child.selectable) {
        // eslint-disable-next-line no-param-reassign
        child.visible = visible;
      }
    });
  }

  setSelectedMuscle(muscle) {
    if (this.selectedMuscle) {
      this.selectedMuscle.material.color = new THREE.Color(COLORS.DEFAULT);
    }

    this.selectedMuscle = muscle;

    if (this.selectedMuscle) {
      this.selectedMuscle.material.color = new THREE.Color(COLORS.SELECTED);
    }
  }

  setHoveredMuscle(muscle) {
    if (muscle === this.selectedMuscle) return;

    if (muscle) {
      if (this.hoveredMuscle) {
        this.hoveredMuscle.material.color = new THREE.Color(COLORS.DEFAULT);
      }
      this.hoveredMuscle = muscle;
      this.hoveredMuscle.material.color = new THREE.Color(COLORS.HOVERED);
    } else {
      this.hoveredMuscle = null;
      this.wireframeMesh.children.forEach((child) => {
        if (child !== this.selectedMuscle)
          // eslint-disable-next-line no-param-reassign
          child.material.color = COLORS.DEFAULT;
      });
    }
  }

  setMuscleOpacity(opacity) {
    this.wireframeMesh.children.forEach((child) => {
      // eslint-disable-next-line no-param-reassign
      child.material.opacity = EasingFunctions.easeInOutQuad(opacity);
    });
  }

  static mapRange(value, inMin, inMax, outMin, outMax) {
    return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
  }

  update(deltaTime) {
    switch (this.state) {
      case MODEL_STATES.FADE_OUT:
        this.wireframeMesh.material.opacity -= this.fadeSpeed.out * deltaTime;
        if (this.wireframeMesh.material.opacity <= 0) {
          this.setState(MODEL_STATES.HIDDEN);
        }
        break;

      case MODEL_STATES.FADE_IN:
        this.wireframeMesh.material.opacity += this.fadeSpeed.in * deltaTime;

        if (this.wireframeMesh.material.opacity >= 1) {
          this.setState(MODEL_STATES.DEFAULT);
        }

        break;

      case MODEL_STATES.MOVE_IN:
        this.moveProgress += this.moveSpeed * deltaTime;
        this.position.lerpVectors(
          this.startPosition,
          this.targetPosition,
          EasingFunctions.easeOutQuad(this.moveProgress)
        );
        this.setMuscleOpacity(this.moveProgress);
        this.wireframeMesh.material.opacity = MuscleHuman.mapRange(
          1 - this.moveProgress,
          0,
          1,
          0.25,
          1
        );
        if (this.moveProgress >= 1) {
          this.setState(MODEL_STATES.ACTIVE);
        }

        break;

      case MODEL_STATES.MOVE_OUT:
        this.moveProgress += this.moveSpeed * deltaTime;
        this.position.lerpVectors(
          this.targetPosition,
          this.startPosition,
          EasingFunctions.easeInQuad(this.moveProgress)
        );
        this.setMuscleOpacity(1 - this.moveProgress);
        this.wireframeMesh.material.opacity = MuscleHuman.mapRange(
          this.moveProgress,
          0,
          1,
          0.25,
          1
        );
        if (this.moveProgress >= 1) {
          this.setState(MODEL_STATES.DEFAULT);
        }

        break;

      case MODEL_STATES.DEFAULT:
        if (!this.wireframeMesh) return;

        if (this.targetOpacity > this.wireframeMesh.material.opacity) {
          this.wireframeMesh.material.opacity += this.fadeSpeed.hover;
        } else if (this.targetOpacity < this.wireframeMesh.material.opacity) {
          this.wireframeMesh.material.opacity -= this.fadeSpeed.hover;
        }

        this.wireframeMesh.material.opacity =
          Math.floor(this.wireframeMesh.material.opacity * 100) / 100;
        break;

      default:
        break;
    }

    if (this.isRotating) {
      this.rotation.y += this.rotationSpeed * deltaTime;
    }
  }
}
export default MuscleHuman;
