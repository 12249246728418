import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { buttonStyle } from '@atoms/Button';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import Link from '@atoms/Link';
import ImagePush from '../ImagePush/ImagePush';

const Container = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ breakLines, theme }) => css`
    padding: ${theme.spacing(4)} 0;
    margin: ${theme.spacing(2)} var(--outer-gap);
    position: relative;

    ::before,
    ::after {
      display: ${breakLines ? 'block' : 'none'};
      position: absolute;
      content: '';
      width: 100%;
      height: 2px;
      left: 0;
      background-color: ${theme.colors.white};
    }

    ::before {
      top: 0;
    }

    ::after {
      bottom: 0;
    }

    ${theme.mediaquery.md(css`
      padding: ${theme.spacing(10)} 0;
      margin: 0px var(--outer-gap);
      ::before,
      ::after {
        width: calc(var(--outer-gap) + var(--col) * 17);
        left: calc(var(--col) * 3);
      }
    `)}
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    color: ${theme.colors.white};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;

    ${theme.mediaquery.md(css`
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const List = styled.ul`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;

    ${theme.mediaquery.sm(css`
      flex-direction: row;
    `)}
  `}
`;

const Item = styled.li`
  ${({ theme }) => css`
    padding: 15px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    ${theme.mediaquery.sm(css`
      padding: 0 15px;
      width: 50%;
    `)}

    ${theme.mediaquery.md(css`
      width: 25%;
    `)}

    ${theme.mediaquery.lg(css`
      width: 20%;
    `)}
  `}
`;

const ImgContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 300px;

    ${theme.mediaquery.md(css`
      height: 200px;
    `)}

    ${theme.mediaquery.lg(css`
      height: 200px;
    `)}
  `}
`;

const StyledImg = styled(Img)``;

const button = css`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin-top: ${theme.spacing(8)};
  `}
`;

const StyledLink = styled(Link)`
  ${button};
  margin-top: 30px;
  border-radius: 100px;
  padding: 10px 24px;
`;

const ImagePushList = ({ title, imagePushes }) => {
  return (
    <Container>
      <Inner>
        {!!title && <Title>{title}</Title>}
        <List>
          {imagePushes.map((imagePush) => (
            <Item key={imagePush.id}>
              <ImgContainer>
                <StyledImg
                  type={IMG_TYPES.fluid}
                  fit={IMG_FITS.contain}
                  small={imagePush.pictures.mobile}
                  medium={imagePush.pictures.desktop}
                />
              </ImgContainer>
              {imagePush.cta && <StyledLink {...imagePush.cta} />}
            </Item>
          ))}
        </List>
      </Inner>
    </Container>
  );
};

ImagePushList.propTypes = {
  title: PropTypes.string,
  imagePushes: PropTypes.arrayOf(ImagePush),
};

export default ImagePushList;
