import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import WhatsInside from '@atoms/WhatsInside';

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    text-align: center;
    max-width: 300px;
    margin: 0 auto;
  `}
`;

const WhatsInsideList = styled.ul`
  ${({ theme }) => css`
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: calc((150px * 2) + var(--inner-gap) * 2);
    gap: var(--inner-gap);
    justify-content: center;
    margin-top: ${theme.spacing(3)};
    padding: 0 var(--outer-gap);

    ${theme.mediaquery.sm(css`
      margin-top: ${theme.spacing(6)};
      max-width: calc((150px * 3) + var(--inner-gap) * 3);
    `)}

    li {
      max-width: 150px;
    }
  `}
`;

const BenefitsList = (props) => {
  const { title, benefitsIcons } = props;

  return (
    <section>
      {title && <Title>{title}</Title>}
      {benefitsIcons && (
        <WhatsInsideList>
          {benefitsIcons.map((benefit) => (
            <li key={`whatsinside_${benefit.label}`}>
              <WhatsInside {...benefit} />
            </li>
          ))}
        </WhatsInsideList>
      )}
    </section>
  );
};

BenefitsList.propTypes = {
  title: PropTypes.string.isRequired,
  benefitsIcons: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
};

export default BenefitsList;
