import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const minutesToISO8601 = (min) => {
  const days = Math.floor(min / 1440);
  const minSubDays = min - days * 1440;
  const hours = Math.floor(minSubDays / 60);
  const minSubHours = minSubDays - hours * 60;

  const dur = ['PT'];
  if (days > 0) {
    dur.push(`${days}D`);
  }
  if (hours > 0) {
    dur.push(`${hours}H`);
  }
  dur.push(`${minSubHours}M`);

  return dur.join('');
};

export default (data, heroData, logo, brandName) => {
  const prepTime = data.instructions.prep || 0;
  const cookTime = data.instructions.cooking || 0;
  const heroImg = heroData.image ? heroData.image.large.src : null;

  return {
    '@context': 'https://schema.org',
    '@type': 'Recipe',
    name: data.title,
    image: heroImg && {
      '@type': 'ImageObject',
      url: heroImg,
    },
    author: {
      '@type': 'Organization',
      name: brandName,
    },
    datePublished: data.createdAt,
    description: data.description,
    prepTime: data.instructions.prep && minutesToISO8601(prepTime),
    cookTime: data.instructions.cooking && minutesToISO8601(cookTime),
    totalTime: minutesToISO8601(prepTime + cookTime),
    recipeYield: data.servingAmount,
    ingredients: data.ingredients,
    recipeInstructions: data.steps
      .map(({ description }) => documentToPlainTextString(description))
      .join('\n\n'),
    publisher: {
      '@type': 'Organization',
      name: brandName,
      logo: {
        '@type': 'ImageObject',
        url: logo,
      },
    },
  };
};
