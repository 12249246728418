import { getImageFields, breakpoints } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { adapter as influencerAdapter } from '@molecules/InfluencerTagline';

export default ({ id, headline, subHeadline, ctaLabel, program }) => {
  const { influencers, slug, hero, supportingImages } = program;

  const imgSizes = `(min-width: ${breakpoints.sm}px) 53vw, (min-width: ${breakpoints.md}px) 46vw, (min-width: ${breakpoints.lg}px) 38vw, 87vw`;

  let image = getImageFields(supportingImages[0], IMG_TYPES.fluid);

  if (hero.imageMobile || hero.imageDesktop) {
    image = getImageFields(
      hero.imageMobile || hero.imageDesktop,
      IMG_TYPES.fluid
    );
  }

  return {
    id,
    headline,
    subHeadline,
    ctaLabel,
    influencers: influencers.map((influencer) => influencerAdapter(influencer)),
    slug,
    image: {
      ...image,
      sizes: imgSizes,
    },
  };
};
