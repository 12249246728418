import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { A11y } from 'swiper';

import Link, { LINK_TYPES } from '@atoms/Link';
import { buttonStyle } from '@atoms/Button';

SwiperCore.use([A11y]);

function RecipeSelector({ className, ctaText, selectionList, title }) {
  const [swiper, setSwiper] = React.useState({ realIndex: 1 });
  const update = React.useState()[1];
  const reRender = () => update({});
  const url = selectionList[swiper.realIndex].slug;
  return (
    <Container className={className}>
      <div>
        <Title>{title}</Title>
      </div>
      <SwiperContainer>
        <Swiper
          onSwiper={setSwiper}
          onSlideChange={reRender}
          slidesPerView={5}
          centeredSlides
          initialSlide={1}
        >
          {selectionList.map(({ text }) => (
            <SwiperSlide key={text}>
              {({ isActive }) => (
                <Slide>
                  <Text isActive={isActive}>{text}</Text>
                </Slide>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </SwiperContainer>
      <LinkContainer>
        <StyledLink url={url} type={LINK_TYPES.internal}>
          {ctaText}
        </StyledLink>
      </LinkContainer>
    </Container>
  );
}

RecipeSelector.propTypes = {
  className: PropTypes.string,
  selectionList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
};

export default RecipeSelector;

const Container = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  flex-direction: column;
  ${({ theme }) => css`
    padding: ${theme.spacing(8)} 0;
    ${theme.mediaquery.md(css`
      flex-direction: row;
      margin: 0 calc(var(--outer-gap) + (var(--col) * 3));
      padding: ${theme.spacing(10)} ${theme.spacing(8)};
    `)}
    ${theme.mediaquery.lg(css`
      margin: 0 calc(var(--outer-gap) + (var(--col) * 5));
    `)}
  `}
`;

const Title = styled.h3`
  ${({ theme }) => theme.typography.titleM}
  color: black;
  position: relative;
  z-index: 2;
`;

const SwiperContainer = styled.div`
  overflow: hidden;
  width: 100%;
  flex-basis: 100%;

  ${({ theme }) => css`
    padding: ${theme.spacing(4)} 0;

    ${theme.mediaquery.md(css`
      padding: 0;
    `)}
  `}

  .swiper-container:after,
  .swiper-container:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 20%;
  }
  .swiper-container:after {
    right: 0;
    z-index: 1;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 100%
    );
    ${({ theme }) =>
      css`
        ${theme.mediaquery.md(css`
          width: 50%;
        `)}
      `}
  }
  .swiper-container:before {
    left: 0;
    z-index: 2;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 100%
    );
  }

  .swiper-slide {
    margin: auto 0;
  }
`;

const Slide = styled.div`
  display: flex;
`;

const Text = styled.div`
  ${({ theme }) => theme.typography.titleXL}
  font-size: 4rem;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  text-align: center;
  margin: 0 auto;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.black : theme.colors.white};
  text-shadow: ${({ theme }) => theme.colors.textShadowBlack};
  transition: color 300ms ease;
`;

const LinkContainer = styled.div`
  ${({ theme }) => css`
    ${theme.mediaquery.md(css`
      position: absolute;
      right: ${theme.spacing(8)};
    `)}
  `}
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'secondary')};
  `}
  position: relative;
  z-index: 1;
  white-space: nowrap;
`;
