import { css } from 'styled-components';
import { easings } from '@utils';

export const TOTAL_ANIMATION_DURATION = 1000;

export const lifestyleTransition = css`
  &.lifestyle-enter,
  &.lifestyle-appear {
    transform: scale(1);
    opacity: 0;
    transition: transform ${TOTAL_ANIMATION_DURATION * 0.6}ms ${easings.in},
      opacity ${TOTAL_ANIMATION_DURATION * 0.6}ms ${easings.in};

    &-active,
    &-done {
      transform: scale(1.2);
      opacity: 1;
    }
  }

  &.lifestyle-exit {
    transform: scale(1.2);
    opacity: 1;
    transition: transform ${TOTAL_ANIMATION_DURATION * 0.6}ms
        ${TOTAL_ANIMATION_DURATION * 0.2}ms ${easings.out},
      opacity ${TOTAL_ANIMATION_DURATION * 0.6}ms
        ${TOTAL_ANIMATION_DURATION * 0.2}ms ${easings.out};

    &-active {
      transform: scale(1);
      opacity: 0;
    }
  }
`;

export const marqueeTransition = css`
  &.marquee-enter,
  &.marquee-appear {
    > div {
      transition: transform ${TOTAL_ANIMATION_DURATION * 0.9}ms ${easings.in};
      transform: translateX(calc(50% + 50vw));

      &:nth-child(2) {
        transform: translateX(calc(-50% - 50vw));
      }

      &:last-of-type {
        transition-delay: ${TOTAL_ANIMATION_DURATION * 0.1}ms;
      }
    }

    &-active,
    &-done {
      > div {
        transform: translateX(0);
        &:nth-child(2) {
          transform: translateX(-5%);
        }
        &:last-of-type {
          transform: translateX(-15%);
        }
      }
    }
  }

  &.marquee-exit {
    > div {
      transition: transform ${TOTAL_ANIMATION_DURATION * 0.9}ms ${easings.out};
      transform: translateX(0);
      &:nth-child(2) {
        transform: translateX(-5%);
      }
      &:last-of-type {
        transform: translateX(-15%);
      }
    }

    &-active {
      > div {
        transform: translateX(calc(-50% - 50vw));

        &:nth-child(2) {
          transform: translateX(calc(50% + 50vw));
        }
        &:last-of-type {
          transform: translateX(calc(-50% - 50vw));
        }
      }
    }
  }
`;

export const productImageTransition = css`
  &.product-image-enter,
  &.product-image-appear {
    transform: translateX(100vw) rotate(-50deg);
    transition: transform ${TOTAL_ANIMATION_DURATION * 0.6}ms ${easings.in};

    &-active,
    &-done {
      transform: rotate(12deg);
    }
  }

  &.product-image-exit {
    transform: rotate(12deg);
    transition: transform ${TOTAL_ANIMATION_DURATION * 0.6}ms ${easings.out};

    &-active {
      transform: translateX(calc(-50% - 60vw)) rotate(-12deg);
    }
  }
`;

export const mainTransition = css`
  &.main-enter,
  &.main-appear {
    opacity: 0;
    transition: opacity ${TOTAL_ANIMATION_DURATION * 0.3}ms ease-in;

    &-active,
    &-done {
      opacity: 1;
    }
  }

  &.main-exit {
    opacity: 1;
    transition: opacity ${TOTAL_ANIMATION_DURATION * 0.3}ms ease-out;

    &-active {
      opacity: 0;
    }
  }
`;
