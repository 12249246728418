// This placeholder function mimics Contentful's GraphQL Asset:
// https://www.contentful.com/developers/docs/references/graphql/#/reference/schema-generation/assets
// it can then be passed through `src/gatsby/image`
// to look like something that gatsby-image `<Img />` can use
// https://www.gatsbyjs.org/packages/gatsby-image/#fragments

// given an object that looks like this:
// https://www.contentful.com/developers/docs/references/graphql/#/reference/schema-generation/assets
// plus some options, return an object that looks like this:
// https://www.gatsbyjs.org/docs/gatsby-image/?origin_team=T026W4N31#returns-1
//
// allows usage of gatsby-image `<Img />`
// without gatsby-source-contentful, notably with gatsby-source-graphql
// only supports a subset of features. improve as necessary.

const TYPES = Object.freeze({
  fixed: 'fixed',
  fluid: 'fluid',
});

const getSize = (name, width, multiplier, aspectRatio) => [
  name,
  Math.round(width * multiplier),
  Math.round((width * multiplier) / aspectRatio),
];

// https://placeholder.com/
const getTransformedPlaceholderUrl = (url, attrs) =>
  url.replace('{width}', attrs.w).replace('{height}', attrs.h);

const getGatsbyImage = (
  { width, height, url },
  { type = TYPES.fluid, maxWidth: dirtyMaxWidth } = {},
  index = undefined
) => {
  let sizes;

  if (!Object.values(TYPES).includes(type)) {
    throw new Error(
      `Invalid type "${type}", expecting one of: ${Object.values(TYPES).join(
        ', '
      )}`
    );
  }

  if (typeof width !== 'number') {
    throw new Error(`Invalid width "${width}", expecting a number`);
  }
  if (typeof height !== 'number') {
    throw new Error(`Invalid height "${height}", expecting a number`);
  }
  if (typeof url !== 'string') {
    throw new Error(`Invalid url "${url}", expecting a string`);
  }

  const aspectRatio = width / height;
  const maxWidth = typeof dirtyMaxWidth !== 'undefined' ? dirtyMaxWidth : width;

  if (type === TYPES.fixed) {
    sizes = [
      getSize('1x', maxWidth, 1, aspectRatio),
      getSize('1.5x', maxWidth, 1.5, aspectRatio),
      getSize('2x', maxWidth, 2, aspectRatio),
      getSize('3x', maxWidth, 3, aspectRatio),
    ];
  }

  if (type === TYPES.fluid) {
    sizes = [
      getSize(`${Math.round(maxWidth / 4)}w`, maxWidth, 1 / 4, aspectRatio),
      getSize(`${Math.round(maxWidth / 2)}w`, maxWidth, 1 / 2, aspectRatio),
      getSize(`${Math.round(maxWidth * 1)}w`, maxWidth, 1, aspectRatio),
      getSize(`${Math.round(maxWidth * 1.5)}w`, maxWidth, 1.5, aspectRatio),
      getSize(`${Math.round(maxWidth * 2)}w`, maxWidth, 2, aspectRatio),
    ];
  }

  sizes = sizes.filter(
    // eslint-disable-next-line no-unused-vars
    ([_, thisWidth, thisHeight]) => thisWidth <= 4000 && thisHeight <= 4000
  ); // contentful's maximum

  return {
    width,
    height,
    aspectRatio,
    src: getTransformedPlaceholderUrl(url, {
      w: width,
      h: height,
    }),
    srcSet: sizes
      .map(
        ([size, thisWidth, thisHeight]) =>
          `${getTransformedPlaceholderUrl(url, {
            w: thisWidth,
            h: thisHeight,
          })} ${size}`
      )
      .join(', '),
    sizes: `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`,
    id: index,
  };
};

const BASE_URL = 'https://via.placeholder.com/{width}x{height}/{color}?{token}';

const placeholder = (width, height, type, color = 'cccccc', index) => {
  return getGatsbyImage(
    {
      width,
      height,
      url: BASE_URL.replace('{token}', Math.random()).replace('{color}', color),
    },
    { type },
    index
  );
};

export default placeholder;
