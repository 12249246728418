import React, { useState, useRef, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SettingsContext } from '@stores/Settings';

import { buttonStyle } from '@atoms/Button';
import InputForm from '@atoms/Input';
import { grid } from '@styles/grid';

const Container = styled.section`
  ${grid}
  display:flex;
  justify-content: center;
`;

const Inner = styled.div`
  ${({ theme }) => css`
    width: 100%;

    ${theme.mediaquery.md(css`
      width: 70vw;
      padding: 0 var(--outer-gap);
    `)}
  `}
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
`;

const UserInputsContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(6)} 0 0;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};

    ${theme.mediaquery.sm(css`
      flex-direction: row;
      justify-content: space-between;
    `)}

    & > div {
      flex: 1;
    }

    input {
      width: 100%;
      text-align: left;
      padding: 0 ${theme.spacing(3)};
      font-size: 0.9rem;
    }
  `}
`;

const StyledSubmitButton = styled.button`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    margin: ${theme.spacing(1)} auto;
    display: inline-block;
    text-align: center;
    ${theme.mediaquery.md(css`
      margin: ${theme.spacing(3)} auto;
    `)}
    transition:all 0.2s ease-in-out;

    &[disabled] {
      pointer-events: none;
      opacity: 0.3;
    }
  `}
`;

const EmailSentMessage = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};
    margin-top: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
    text-align: center;

    ${theme.mediaquery.md(css`
      margin-top: ${theme.spacing(4)};
      margin-bottom: ${theme.spacing(4)};
    `)}
  `}
`;

const LeadsForm = ({
  nameLabel,
  nameTooltip,
  namePlaceholder,
  emailLabel,
  emailTooltip,
  emailPlaceholder,
  phoneLabel,
  phoneTooltip,
  phonePlaceholder,
  cityLabel,
  cityTooltip,
  cityPlaceholder,
  submitButtonText,
  leadsFormEmailDisplayName,
  emailSentMessage,
  emailErrorMessage,
}) => {
  const { emailDisplayName, receivingEmail, emailConsent } = useContext(
    SettingsContext
  );
  const formEmailDisplayName = leadsFormEmailDisplayName ?? emailDisplayName;

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isConsentGiven, setIsConsentGiven] = useState(false);

  const nameSelectorRef = useRef(null);
  const [nameValue, setNameValue] = useState('');
  const emailSelectorRef = useRef(null);
  const [emailValue, setEmailValue] = useState('');
  const phoneSelectorRef = useRef(null);
  const [phoneValue, setPhoneValue] = useState('');
  const citySelectorRef = useRef(null);
  const [cityValue, setCityValue] = useState('');
  const [isEmailSentError, setIsEmailSentError] = useState(false);

  const sendEmail = useCallback(async () => {
    const message = `A user has used the leads form on the website. The details are as follows:`;
    const emailMarkup = `<html lang="en">
      <span>${message}</span>
      <br /><br />
      <span>${nameLabel}: ${nameValue}</span>
      <br />
      <span>${emailLabel}: ${emailValue}</span>
      <br />
      <span>${phoneLabel}: ${phoneValue}</span>
      <br />
      <span>${cityLabel}: ${cityValue}</span>
      <br />
    </html>`;

    const options = {
      from: `${formEmailDisplayName} <onboarding@resend.dev>`,
      to: [`${receivingEmail}`],
      subject: 'Leads form submission',
      html: emailMarkup,
    };

    const response = await fetch('/.netlify/functions/sendEmail/sendEmail', {
      method: 'POST',
      body: JSON.stringify(options),
    });

    if (response.ok) {
      setIsEmailSent(true);
      // console.log('Email sent: ', response);
    } else {
      setIsEmailSentError(true);
      console.error('Error sending email');
    }
  }, [
    nameLabel,
    nameValue,
    emailLabel,
    emailValue,
    phoneLabel,
    phoneValue,
    cityLabel,
    cityValue,
    formEmailDisplayName,
    receivingEmail,
  ]);

  const handleValue = useCallback((e) => {
    switch (e.target) {
      case nameSelectorRef.current:
        setNameValue(String(e.target.value));
        break;
      case emailSelectorRef.current:
        setEmailValue(String(e.target.value));
        break;
      case phoneSelectorRef.current:
        setPhoneValue(String(e.target.value));
        break;
      case citySelectorRef.current:
        setCityValue(String(e.target.value));
        break;
      default:
        break;
    }
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (
        nameValue &&
        emailValue &&
        phoneValue &&
        cityValue &&
        isConsentGiven
      ) {
        const submittedValues = {
          event: 'Submit Leads Form',
          action: 'submit',
          name: nameValue,
          email: emailValue,
          phone: phoneValue,
          city: cityValue,
        };

        dataLayer.push(submittedValues);
        if (!isEmailSent) {
          sendEmail();
        }
      }
    },
    [
      nameValue,
      emailValue,
      phoneValue,
      cityValue,
      isConsentGiven,
      isEmailSent,
      sendEmail,
    ]
  );

  return (
    <Container>
      <Inner>
        {!isEmailSent && (
          <>
            <FormContainer onSubmit={handleSubmit}>
              <UserInputsContainer>
                <InputForm
                  type="text"
                  value={nameValue || null}
                  label={nameLabel}
                  tooltip={nameTooltip}
                  placeholder={namePlaceholder}
                  onChange={handleValue}
                  ref={nameSelectorRef}
                  name="Name"
                  width="100%"
                  isRequired
                />
                <InputForm
                  type="email"
                  value={emailValue || null}
                  label={emailLabel}
                  tooltip={emailTooltip}
                  placeholder={emailPlaceholder}
                  onChange={handleValue}
                  ref={emailSelectorRef}
                  name="Email"
                  width="100%"
                  isRequired
                />
                <InputForm
                  type="tel"
                  value={phoneValue || null}
                  label={phoneLabel}
                  tooltip={phoneTooltip}
                  placeholder={phonePlaceholder}
                  onChange={handleValue}
                  ref={phoneSelectorRef}
                  name="Phone"
                  width="100%"
                  isRequired
                />
                <InputForm
                  type="text"
                  value={cityValue || null}
                  label={cityLabel}
                  tooltip={cityTooltip}
                  placeholder={cityPlaceholder}
                  onChange={handleValue}
                  ref={citySelectorRef}
                  name="City"
                  width="100%"
                  isRequired
                />
              </UserInputsContainer>
              <InputForm
                id="leads_form_consent"
                type="checkbox"
                value={isConsentGiven}
                label={emailConsent}
                onChange={() => setIsConsentGiven(!isConsentGiven)}
                isRequired
              />
              <StyledSubmitButton
                type="submit"
                disabled={
                  !nameValue ||
                  !emailValue ||
                  !phoneValue ||
                  !cityValue ||
                  !isConsentGiven
                }
              >
                {submitButtonText}
              </StyledSubmitButton>
            </FormContainer>
            {isEmailSentError && (
              <EmailSentMessage>{emailErrorMessage}</EmailSentMessage>
            )}
          </>
        )}

        {isEmailSent && <EmailSentMessage>{emailSentMessage}</EmailSentMessage>}
      </Inner>
    </Container>
  );
};

LeadsForm.propTypes = {
  nameLabel: PropTypes.string.isRequired,
  nameTooltip: PropTypes.string,
  namePlaceholder: PropTypes.string,
  emailLabel: PropTypes.string.isRequired,
  emailTooltip: PropTypes.string,
  emailPlaceholder: PropTypes.string,
  phoneLabel: PropTypes.string.isRequired,
  phoneTooltip: PropTypes.string,
  phonePlaceholder: PropTypes.string,
  cityLabel: PropTypes.string.isRequired,
  cityTooltip: PropTypes.string,
  cityPlaceholder: PropTypes.string,
  submitButtonText: PropTypes.string.isRequired,
  leadsFormEmailDisplayName: PropTypes.string,
  emailSentMessage: PropTypes.string.isRequired,
  emailErrorMessage: PropTypes.string.isRequired,
};

export default React.memo(LeadsForm);
