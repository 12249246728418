import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import favicon from '@images/favicon/favicon.png';
import altFaviconIco from '@images/altFavicon/altFavicon.ico';
import altFavicon32 from '@images/altFavicon/altFavicon-32x32.png';
import altFavicon16 from '@images/altFavicon/altFavicon-16x16.png';
import altFaviconAppleTouch from '@images/altFavicon/altApple-touch-icon.png';

import { mergeSansNullOrUndefined } from '@utils';
import { SettingsContext } from '@stores/Settings';

const Metadata = ({ path, meta, jsonld }) => {
  const { seo, locale, domain, market, gtagID } = useContext(SettingsContext);
  const {
    title,
    description,
    image,
    twitterAuthor,
    facebookPageId,
    siteName,
    canonicalUrLs,
  } = mergeSansNullOrUndefined(seo, meta);

  const url = `${domain}${path}`;
  const imgSrc = image && image.src;
  const imgUrl = `https:${imgSrc}`;
  const imgUrlArr = imgUrl && imgUrl.split('.');
  const imgType = imgUrl && `image/${imgUrlArr[imgUrlArr.length - 1]}`;

  const altFaviconMarkets = ['FR', 'IT', 'GR'];
  const useAltFavicon = altFaviconMarkets.includes(market);
  const finalFaviconIco = useAltFavicon ? altFaviconIco : favicon;
  const finalFavicon32 = useAltFavicon ? altFavicon32 : favicon;
  const finalFavicon16 = useAltFavicon ? altFavicon16 : favicon;
  const finalFaviconAppleTouch = useAltFavicon ? altFaviconAppleTouch : favicon;

  const finalLocale = market === 'GR' ? 'el-GR' : locale;

  const canonical = canonicalUrLs || [];

  return (
    <Helmet
      htmlAttributes={{
        lang: finalLocale,
      }}
      title={title}
      link={[
        { rel: 'shortcut icon', type: 'image/ico', href: finalFaviconIco },
        { rel: 'icon', type: 'image/ico', href: finalFaviconIco },
        {
          rel: 'apple-touch-icon',
          type: 'image/ico',
          sizes: '180x180',
          href: finalFaviconAppleTouch,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: finalFavicon32,
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: finalFavicon16,
        },
        ...canonical.map((u) => ({
          rel: 'canonical',
          href: u,
        })),
        {
          rel: 'preconnect',
          href: 'https://s.ytimg.com',
          crossorigin: true,
        },
        {
          rel: 'preconnect',
          href: 'https://adservice.google.com',
          crossorigin: true,
        },
        {
          rel: 'preconnect',
          href: 'https://youtube.com',
          crossorigin: true,
        },
        {
          rel: 'preconnect',
          href: 'https://www.youtube.com',
          crossorigin: true,
        },
        {
          rel: 'preconnect',
          href: 'https://www.google-analytics.com',
          crossorigin: true,
        },
        {
          rel: 'preconnect',
          href: 'https://engage.commander1.com',
          crossorigin: true,
        },
      ]}
      meta={[
        // static
        { name: 'msapplication-TileColor', content: '#000000' },
        { name: 'theme-color', content: '#ffffff' },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `theme-color`,
          content: `#FFFFFF`,
        },
        // locale
        {
          property: `og:locale`,
          content: locale.replace('-', '_'),
        },
        // url
        {
          property: `og:url`,
          content: url,
        },
        {
          name: `twitter:url`,
          content: url,
        },

        // description
        {
          property: `og:title`,
          content: title,
        },
        {
          name: `twitter:title`,
          content: title,
        },

        // description
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          name: `twitter:description`,
          content: description,
        },

        // image
        // og:image, width, height, type
        // twitter:image
        ...(image
          ? [
              {
                property: `og:image`,
                content: imgUrl,
              },
              {
                property: `og:image:width`,
                content: image.width,
              },
              {
                property: `og:image:height`,
                content: image.height,
              },
              {
                property: `og:image:type`,
                content: imgType,
              },
              {
                name: `twitter:image`,
                content: imgUrl,
              },
            ]
          : []),

        // site name
        {
          property: `og:site_name`,
          content: siteName,
        },

        // external IDs
        {
          name: `fb:page_id`,
          content: facebookPageId,
        },
        {
          name: `twitter:creator`,
          content: twitterAuthor,
        },
        {
          name: `twitter:site`,
          content: twitterAuthor,
        },
      ]}
    >
      {!!jsonld && (
        <script type="application/ld+json">{JSON.stringify(jsonld)}</script>
      )}
      {/* {!!gtagID && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gtagID}`}
        />
      )} */}
      {!!gtagID && (
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            
            gtag('config', "${gtagID}", {"send_page_view": false});
          `}
        </script>
      )}
    </Helmet>
  );
};

Metadata.propTypes = {
  meta: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      height: PropTypes.number.isRequired,
    }),
    twitterAuthor: PropTypes.string,
    facebookPageId: PropTypes.string,
    siteName: PropTypes.string,
  }).isRequired,
  path: PropTypes.string.isRequired,
  jsonld: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Metadata;
