import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SwiperSlide } from 'swiper/react';
import { grid } from '@styles/grid';
import CartIcon from '@icons/shopping-cart.svg';
import Img from '@atoms/Img';
import Link, { adapter as linkAdapter } from '@atoms/Link';
import { MiniCarousel } from '@molecules/Carousel';

const ProductCategoryListGrid = styled.ul`
  ${({ theme }) => css`
    ${grid};
    max-width: calc(342px + var(--inner-gap) * 2);
    ${theme.mediaquery.sm(css`
      max-width: calc(780px + var(--inner-gap) * 2);
    `)}
    grid-gap: 12px;
    margin: 0 auto;
    ${theme.mediaquery.sm(css`
      grid-gap: 24px;
    `)}
  `}
`;

const GridItem = styled.ul`
  ${({ theme }) => css`
    grid-column: span 2;
    aspect-ratio: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #fff;
    border-radius: ${theme.spacing(3)};
    position: relative;
    overflow: hidden;
    padding: ${theme.spacing(1)};
    ${theme.mediaquery.sm(css`
      padding: ${theme.spacing(2)};
      grid-column: span 8;
    `)}

    .swiper-slide {
      &:not(:last-child) {
        margin-right: calc(var(--inner-gap) + var(--col));
      }
      height: 100%;
    }
  `}
`;

const StyledImg = styled(Img)`
  ${({ theme }) => css`
    height: 120px;
    ${theme.mediaquery.sm(css`
      height: 180px;
    `)}
    width: 100%;
  `}
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleS};
    position: absolute;
    bottom: ${theme.spacing(2)};
    left: ${theme.spacing(1)};
    margin-right: ${theme.spacing(1)};
    margin-top: ${theme.spacing(2)};
    word-break: break-word;

    ${theme.mediaquery.sm(css`
      ${theme.typography.titleS};
      left: ${theme.spacing(2)};
    `)};
  `}
`;

const StoreLinkContainer = styled(Link)`
  ${({ theme }) => css`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 64px;
    width: 64px;
    border-radius: ${theme.spacing(3)};
    ${theme.mediaquery.xs(css`
      height: 80px;
      width: 80px;
      border-radius: ${theme.spacing(4)};
    `)};
    ${theme.mediaquery.sm(css`
      height: 96px;
      width: 96px;
      border-radius: ${theme.spacing(5)};
    `)};
  `};
`;

const StyledCartIcon = styled(CartIcon)`
  ${({ theme }) => css`
    width: 32px;
    height: 32px;
    fill: ${theme.colors.black};

    ${theme.mediaquery.xs(css`
      width: 40px;
      height: 40px;
    `)};
    ${theme.mediaquery.sm(css`
      width: 48px;
      height: 48px;
    `)};
  `};
`;

const ProductCategoryList = ({ productCategories, storeLink }) => (
  <ProductCategoryListGrid>
    {productCategories.map(({ category, id: catId, products }) => (
      <GridItem key={catId}>
        <MiniCarousel id={catId}>
          {products.map((product) => (
            <SwiperSlide
              key={product.id}
              color={product.color} // MiniCarousel looks up this color value on the active slide for the pagination color
              className="swiper-slide"
            >
              <StyledImg small={product.image} />
            </SwiperSlide>
          ))}
        </MiniCarousel>
        <Title>{category.name}</Title>
      </GridItem>
    ))}
    <GridItem>
      <StoreLinkContainer {...linkAdapter(storeLink)}>
        <StyledCartIcon />
        <Title>{storeLink.title}</Title>
      </StoreLinkContainer>
    </GridItem>
  </ProductCategoryListGrid>
);

ProductCategoryList.propTypes = {
  productCategories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      category: PropTypes.object.isRequired,
      products: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          flavour: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
          priority: PropTypes.number.isRequired,
          slug: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired,
        })
      ),
    })
  ).isRequired,
  storeLink: PropTypes.string,
};

export default ProductCategoryList;
