import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import InnerHTML from 'dangerously-set-html-content';
import { breakpoints } from '@utils';
import { useWindowSize } from '@hooks';

const Wrapper = styled.div`
  position: relative;
  padding-top: ${({ theme }) => theme.spacing(10)};
  min-height: 100vh;
  ${({ isDesktop }) => css`
    display: ${!isDesktop ? 'block' : 'flex'};
    justify-content: center;
    align-items: center;
  `}
`;

const cognitoScript = `
<script src="https://www.cognitoforms.com/scripts/embed.js"></script>
`;

const CognitoForm = ({ formTitle, cognitoSrc }) => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [viewportWidth] = useWindowSize();

  useEffect(() => {
    if (viewportWidth >= breakpoints.md) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  }, [viewportWidth]);

  return (
    <>
      <Wrapper isDesktop={isDesktop}>
        {!isDesktop ? (
          <iframe
            src={cognitoSrc}
            style={{
              position: 'relative',
              width: '100%',
              minWidth: '100%',
            }}
            frameBorder="0"
            scrolling="yes"
            seamless="seamless"
            width="100%"
            title={formTitle}
          />
        ) : (
          <iframe
            src={cognitoSrc}
            style={{
              position: 'relative',
              width: '1500px',
              minWidth: '100%',
            }}
            frameBorder="0"
            scrolling="yes"
            seamless="seamless"
            width="1500px"
            title={formTitle}
          />
        )}
        <InnerHTML html={cognitoScript} />
      </Wrapper>
    </>
  );
};

CognitoForm.propTypes = {
  formTitle: PropTypes.string.isRequired,
  cognitoSrc: PropTypes.string.isRequired,
};

export default CognitoForm;
