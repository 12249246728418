import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import RecipeStep from '@molecules/RecipeStep';
import { SettingsContext } from '@stores/Settings';
import SectionTitle from '@atoms/SectionTitle';
import { fullbleedgrid } from '@styles/grid';
import NutritionalTable from '@molecules/NutritionalTable';

const TopContainer = styled.div`
  ${fullbleedgrid};
`;

const StyledSectionTitle = styled(SectionTitle)`
  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      padding: 0;
      text-align: left;
      position: relative;
    `)}
  `}
`;

const StyledSectionTitleMobile = styled(StyledSectionTitle)`
  ${({ theme }) => css`
    display: block;
    ${theme.mediaquery.sm(css`
      display: none;
    `)}
  `}
`;

const TopContainerItem = styled.div`
  ${({ theme }) => css`
    grid-column: 1 / -1;

    &:last-of-type {
      margin-top: ${theme.spacing(5)};
    }

    ${theme.mediaquery.sm(css`
      grid-column: span 13;

      &:first-of-type {
        padding-left: calc(var(--outer-gap) + var(--col) * 3);

        ${StyledSectionTitle}:before {
          left: calc((var(--outer-gap) + var(--col) * 3) * -1);
        }
      }

      &:last-of-type {
        margin-top: 0;
        padding-right: calc(var(--outer-gap) + var(--col) * 3);

        ${StyledSectionTitle}:before {
          display: none;
        }
      }
    `)}

    ${theme.mediaquery.md(css`
      &:first-of-type {
        padding-left: calc(var(--outer-gap) + var(--col) * 7);

        ${StyledSectionTitle}:before {
          left: calc((var(--outer-gap) + var(--col) * 7) * -1);
        }
      }

      &:last-of-type {
        padding-right: calc(var(--outer-gap) + var(--col) * 7);
      }
    `)}
  `}
`;

const TopContainerTitle = styled(TopContainer)`
  ${({ theme }) => css`
    display: none;
    ${theme.mediaquery.sm(css`
      display: grid;
      align-items: end;
    `)}
  `}
`;

const TopText = styled.p`
  ${({ theme, withBullet }) => css`
    ${theme.typography.bodyL};
    padding: 0 var(--outer-gap);

    ${withBullet &&
    css`
      &:before {
        content: '• ';
      }
    `}

    ${theme.mediaquery.sm(css`
      padding: 0;
    `)}
  `}
`;

const StyledNutritionalTable = styled(NutritionalTable)`
  width: initial;
  margin-left: initial;
  margin-right: initial;
  grid-column: 1 / -1;

  ${({ theme }) => css`
    ${theme.mediaquery.sm(css`
      grid-column: 5 / span 18;
    `)}

    ${theme.mediaquery.md(css`
      grid-column: 9 / span 10;
    `)}
  `}
`;

const Recipe = ({ instructions, ingredients, steps, nutritionalTable }) => {
  const {
    servingLabel,
    ingredientsLabel,
    servingForLabel,
    preparationTimeLabel,
    cookingTimeLabel,
    totalTimeLabel,
    minutesLabel,
  } = useContext(SettingsContext).translations;

  return (
    <section>
      <TopContainerTitle>
        <TopContainerItem>
          <StyledSectionTitle title={servingLabel} />
        </TopContainerItem>
        <TopContainerItem>
          <StyledSectionTitle title={ingredientsLabel} />
        </TopContainerItem>
      </TopContainerTitle>
      <TopContainer>
        <TopContainerItem>
          <StyledSectionTitleMobile title={servingLabel} />
          <TopText>{`${servingForLabel} : ${instructions.serving}`}</TopText>
          <TopText>{`${preparationTimeLabel} : ${instructions.prep} ${minutesLabel}`}</TopText>
          {instructions.cooking && (
            <TopText>{`${cookingTimeLabel} : ${instructions.cooking} ${minutesLabel}`}</TopText>
          )}
          {instructions.cooking && (
            <TopText>{`${totalTimeLabel} : ${instructions.total} ${minutesLabel}`}</TopText>
          )}
        </TopContainerItem>
        <TopContainerItem>
          <StyledSectionTitleMobile title={ingredientsLabel} />
          <ul>
            {ingredients.map((ingredient) => (
              <li key={ingredient}>
                <TopText withBullet>{ingredient}</TopText>
              </li>
            ))}
          </ul>
        </TopContainerItem>
      </TopContainer>
      {!!nutritionalTable && (
        <TopContainer>
          <StyledNutritionalTable
            {...nutritionalTable}
            visibleRows={3}
            labelOnTop={false}
          />
        </TopContainer>
      )}
      <ol>
        {steps.map(({ id, ...rest }) => (
          <li key={id}>
            <RecipeStep {...rest} />
          </li>
        ))}
      </ol>
    </section>
  );
};

Recipe.propTypes = {
  instructions: PropTypes.shape({
    serving: PropTypes.string.isRequired,
    total: PropTypes.number,
    prep: PropTypes.number.isRequired,
    cooking: PropTypes.number,
  }).isRequired,
  ingredients: PropTypes.arrayOf(PropTypes.string).isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape(RecipeStep.propTypes)),
  nutritionalTable: PropTypes.shape(NutritionalTable.propTypes),
};

export default React.memo(Recipe);
