import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';

import { buttonStyle } from '@atoms/Button';
import Link from '@atoms/Link';
import Tooltip from '@atoms/Tooltip';

const TextContainer = styled.div`
  ${({ theme, flexDir, imagePosition }) => css`
    width: 100%;
    flex: 0 0 auto;
    position: relative;
    z-index: ${imagePosition === 'Background' ? 2 : 0};

    ${theme.mediaquery.sm(css`
      width: ${flexDir === 'column' ? '100%' : '50%'};
    `)}
  `}
`;

const TitleContainer = styled.div`
  position: relative;
  display: inline-flex;

  margin-top: ${({ theme, imagePosition }) =>
    imagePosition === 'Background' ? 0 : theme.spacing(4)};

  & > span > svg {
    margin-left: ${({ theme }) => theme.spacing(2)};

    path {
      fill: ${({ theme, themeMode, imagePosition }) =>
        themeMode === 'Light' && imagePosition !== 'Background'
          ? theme.colors.black
          : theme.colors.white};
    }
  }
`;

const Title = styled.h2`
  ${({ theme }) => css`
    ${theme.typography.titleL};
    position: relative;

    ${theme.mediaquery.md(css`
      margin-bottom: 0;
    `)}
  `}
`;

const Subtitle = styled.h3`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    position: relative;
    margin-top: ${theme.spacing(3)};

    ${theme.mediaquery.md(css`
      margin-bottom: 0;
    `)}
  `}
`;

const Body = styled.p`
  ${({ theme }) => css`
    ${theme.typography.body};
    position: relative;
    margin-top: ${theme.spacing(3)};

    ${theme.mediaquery.md(css`
      margin-bottom: 0;
    `)}
  `}
`;

const StyledButton = styled(Link)`
  ${({ theme, themeMode, imagePosition }) => css`
    ${buttonStyle(
      theme,
      themeMode === 'Light' && imagePosition !== 'Background'
        ? 'secondary'
        : 'primary'
    )};
    display: inline-flex;
    margin-top: ${theme.spacing(3)};
  `}
`;

const MediaContainer = styled.div`
  ${({ theme, flexDir, imagePosition }) => css`
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    ${imagePosition === 'Background' &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        background: rgba(0, 0, 0, 0.3);
      }
    `}

    ${theme.mediaquery.sm(css`
      width: ${flexDir === 'column' ? '100%' : '50%'};
      align-items: center;
    `)}
  `}
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;

  .gatsby-image-wrapper {
    height: 100%;
  }
`;

const DescriptionPushContent = (props) => {
  const {
    title,
    subtitle,
    body,
    tooltip,
    cta,
    image,
    imagePositionStyle,
    themeMode,
    PushContainerFlexDirection,
    isImage,
  } = props;
  return (
    <>
      {isImage && (
        <MediaContainer
          flexDir={PushContainerFlexDirection}
          imagePosition={imagePositionStyle}
        >
          <ImageContainer>
            <Img
              type={IMG_TYPES.fluid}
              fits={IMG_FITS.none}
              small={image.small}
              medium={image.large}
              alt={title}
            />
          </ImageContainer>
        </MediaContainer>
      )}
      <TextContainer
        flexDir={PushContainerFlexDirection}
        imagePosition={imagePositionStyle}
      >
        {title && (
          <TitleContainer
            themeMode={themeMode}
            imagePosition={imagePositionStyle}
          >
            <Title>{title}</Title>
            {tooltip && <Tooltip tooltip={tooltip} left />}
          </TitleContainer>
        )}
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {body && <Body>{body}</Body>}
        {cta && (imagePositionStyle !== 'Background' || tooltip) && (
          <StyledButton
            themeMode={themeMode}
            imagePosition={imagePositionStyle}
          >
            {cta.label}
          </StyledButton>
        )}
      </TextContainer>
    </>
  );
};

DescriptionPushContent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string,
  tooltip: PropTypes.string,
  cta: PropTypes.object,
  image: PropTypes.object,
  imagePositionStyle: PropTypes.string,
  themeMode: PropTypes.string,
  PushContainerFlexDirection: PropTypes.string,
  isImage: PropTypes.bool,
};

export default DescriptionPushContent;
