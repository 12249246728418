import { adapter as recipeStepAdapter } from '@molecules/RecipeStep';
import { adapter as nutritionalTableAdapter } from '@molecules/NutritionalTable';

export default ({
  id,
  title,
  shortDescription,
  createdAt,
  cookingTimeInMinutes,
  preparationTimeInMinutes,
  servingAmount,
  servingLabel,
  ingredients,
  steps,
  nutritionalTable,
}) => ({
  id,
  /* begin used for metas only */
  title,
  description: shortDescription && shortDescription.shortDescription,
  createdAt,
  servingAmount,
  /* end used for metas only */
  ingredients,
  instructions: {
    serving: `${servingAmount} ${servingLabel}`,
    total:
      !!cookingTimeInMinutes && preparationTimeInMinutes + cookingTimeInMinutes,
    prep: preparationTimeInMinutes,
    cooking: cookingTimeInMinutes,
  },
  steps: steps.map((step) => recipeStepAdapter(step)),
  nutritionalTable: nutritionalTable
    ? nutritionalTableAdapter(nutritionalTable)
    : null,
});
