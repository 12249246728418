import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export default ({
  cardId,
  cardTitle,
  cardTagline,
  cardBackground,
  author,
  entryType,
  slug,
  category,
  tribe,
  secondaryTribe,
}) => {
  let tribes = [];
  if (tribe !== null) {
    tribes.push(tribe.filterUrlValue);
  }
  if (secondaryTribe !== null) {
    tribes = tribes
      .concat(secondaryTribe.map((item) => item.filterUrlValue))
      .filter(
        (item, index, array) => array.findIndex((v) => v === item) === index
      );
  }

  return {
    id: cardId,
    slug,
    title: cardTitle,
    subtitle: cardTagline,
    type: entryType,
    background: getImageFields(cardBackground, IMG_TYPES.fluid),
    influencer: author && author.name,
    categoryType: category.name,
    tribes,
  };
};

export const HcpCardAdapter = ({
  cardId,
  cardTitle,
  cardBackground,
  slug,
  category,
}) => {
  return {
    id: cardId,
    slug,
    title: cardTitle,
    background:
      cardBackground && getImageFields(cardBackground, IMG_TYPES.fluid),
    categoryType: category.name,
  };
};
