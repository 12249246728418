// given an object, like
// filterNullOrUndefined({ foo: 'foo', bar: null })
// will return the object without nulls or undefined values
export const filterNullOrUndefined = (obj) =>
  Object.entries(obj).reduce(
    (acc, [key, val]) => ({
      ...acc,
      ...(typeof val === 'undefined' || val === null ? {} : { [key]: val }),
    }),
    {}
  );

// given a list of objects, like
// mergeSansNull({ foo: 'foo'}, { bar: 'bar' }, { qux: null })
// will merge the objects together without nulls or undefined values

export const mergeSansNullOrUndefined = (...objs) => {
  return objs.reduce(
    (acc, obj) => ({
      ...acc,
      ...filterNullOrUndefined(obj),
    }),
    {}
  );
};
