import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Img, { IMG_TYPES } from '@atoms/Img';

const InfluencerTaglineContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledImg = styled(Img)`
  ${({ theme }) => css`
    width: ${theme.spacing(4)} !important;
    height: ${theme.spacing(4)} !important;
    border-radius: 50%;
    overflow: hidden;

    ${theme.mediaquery.sm(css`
      width: ${theme.spacing(5)} !important;
      height: ${theme.spacing(5)} !important;
    `)}
  `}
`;

const Text = styled.p`
  ${({ theme }) => css`
    margin-left: ${theme.spacing(1)};
    ${theme.typography.footnote};

    & b {
      font-weight: ${theme.typography.fontWeights.bold};
    }
  `}
`;

const InfluencerTagline = ({
  picture,
  name,
  categories,
  className,
  ...rest
}) => {
  return (
    <InfluencerTaglineContainer className={className} {...rest}>
      <StyledImg small={picture} type={IMG_TYPES.fixed} />
      <Text>
        <b>{name}</b> - {categories}
      </Text>
    </InfluencerTaglineContainer>
  );
};

InfluencerTagline.propTypes = {
  picture: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  categories: PropTypes.string.isRequired,
  className: PropTypes.node,
};

export default InfluencerTagline;
