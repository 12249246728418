import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_FITS, IMG_TYPES } from '@atoms/Img';
import Link, { LINK_TYPES } from '@atoms/Link';

export const BUTTON_WIDTH = 150;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    width: ${BUTTON_WIDTH}px;
    height: 50px;
    background: ${theme.colors.white};
    overflow: hidden;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.spacing(2)};
  `}
`;

const RetailerButton = ({ name, url, logo }) => (
  <StyledLink title={name} url={url} type={LINK_TYPES.external} target="_blank">
    <Img type={IMG_TYPES.fixed} fits={IMG_FITS.cover} small={logo} />
  </StyledLink>
);

RetailerButton.propTypes = {
  name: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  logo: PropTypes.object.isRequired,
};

export default RetailerButton;
