import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { SettingsContext } from '@stores/Settings';
import Img from '@atoms/Img';
import Link, { LINK_TYPES } from '@atoms/Link';
import { buttonStyle } from '@atoms/Button';

const Container = styled.div`
  ${({ theme }) => css`
    overflow: hidden;
    margin-top: ${theme.spacing(6)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `}
`;

const StyledImg = styled(Img)`
  position: absolute;
  margin: 0 auto;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
`;

const Infos = styled.div`
  ${({ theme, color }) => css`
    --product-color: ${color};
    margin-top: ${theme.spacing(3)};
    text-align: center;

    ${theme.mediaquery.sm(css`
      margin: 0 auto;
      margin-top: ${theme.spacing(5)};
    `)}
  `}
`;

const infosTextStyle = css`
  ${({ theme }) => css`
    ${theme.typography.titleM};
    color: ${theme.colors.white};

    span {
      color: var(--product-color);
      text-transform: initial;
    }
  `}
`;

const Protein = styled.p`
  ${infosTextStyle};
`;

const Title = styled.p`
  ${infosTextStyle};
  margin-top: ${({ theme }) => theme.spacing(4)};

  span {
    display: block;
    text-transform: uppercase;
  }
`;

const StyledLink = styled(Link)`
  ${({ theme }) => css`
    ${buttonStyle(theme, 'primary')};
    display: inline-flex;
    margin-top: ${theme.spacing(5)};
  `}
`;

const ProductCTA = ({ image, card, CTALabel }) => {
  const { translations } = useContext(SettingsContext);
  return (
    <Container>
      <StyledImg
        small={image.fixed}
        key={image.fixed.src}
        alt={card.flavour}
        width={image.fixed.width}
        height={image.fixed.height}
      />
      <Infos color={card.productColor}>
        <Protein>
          <span>{card.proteinGramage}</span> {translations.proteinGramageLabel}
        </Protein>
        <Title>
          <span>{card.flavour}</span>
          {card.productCategory.name}
        </Title>
        <StyledLink
          url={`${card.slug}/${card.slug}`}
          title={CTALabel}
          label={CTALabel}
          type={LINK_TYPES.internal}
          isProduct
        />
      </Infos>
    </Container>
  );
};

ProductCTA.propTypes = {
  image: PropTypes.object,
  card: PropTypes.shape(ProductCTA.propTypes).isRequired,
  CTALabel: PropTypes.string,
};

export default ProductCTA;
