import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Link from '@atoms/Link';
import DescriptionPushContent from './DescriptionPushContent';

const Container = styled.section`
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;

  ${({ theme, themeMode, imagePosition }) => css`
    padding: calc(35vw / 3.5)
      ${imagePosition === 'Background' ? 'var(--outer-gap)' : 0};
    background-color: ${themeMode === 'Light'
      ? theme.colors.white
      : theme.colors.black};
    color: ${themeMode === 'Light' && imagePosition !== 'Background'
      ? theme.colors.black
      : theme.colors.white};
  `}
`;

const PushLinkContainer = styled(Link)`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--outer-gap);
    width: 100%;
    text-align: left;
    border-radius: 40px;
    overflow: hidden;
    position: relative;
    min-height: 500px;

    ${theme.mediaquery.sm(css`
      text-align: center;
      width: 70vw;
    `)}
  `}
`;

const PushContainer = styled.div`
  ${({ theme, flexDir, imagePosition }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--outer-gap);
    padding: ${imagePosition === 'Background' ? 'var(--outer-gap)' : 0}
      var(--outer-gap);
    width: 100%;
    text-align: left;
    border-radius: ${imagePosition === 'Background' ? '40px' : 0};
    overflow: hidden;
    position: relative;
    min-height: ${imagePosition === 'Background' ? '500px' : 0};

    ${theme.mediaquery.sm(css`
      flex-direction: ${flexDir};
      align-items: center;
      justify-content: ${flexDir === 'column' && imagePosition !== 'Background'
        ? 'flex-start'
        : 'center'};
      text-align: ${flexDir === 'column' ? 'center' : 'left'};
      width: 70vw;
    `)}
  `}
`;

const DescriptionPush = (props) => {
  const { tooltip, cta, image, imagePosition, themeMode } = props;

  const imagePositionStyle = imagePosition || 'Center Aligned';
  const themeModeStyle = themeMode || 'Dark';
  let PushContainerFlexDirection = 'column';

  if (imagePositionStyle === 'Left Aligned') {
    PushContainerFlexDirection = 'row';
  }

  if (imagePositionStyle === 'Right Aligned') {
    PushContainerFlexDirection = 'row-reverse';
  }

  const isImage =
    image && image.small && image.large && imagePositionStyle !== 'No Image';

  return (
    <Container themeMode={themeModeStyle} imagePosition={imagePositionStyle}>
      {imagePositionStyle === 'Background' && !tooltip ? (
        <PushLinkContainer {...cta}>
          <DescriptionPushContent
            {...props}
            themeMode={themeModeStyle}
            PushContainerFlexDirection={PushContainerFlexDirection}
            imagePositionStyle={imagePositionStyle}
            isImage={isImage}
          />
        </PushLinkContainer>
      ) : (
        <PushContainer
          flexDir={PushContainerFlexDirection}
          imagePosition={imagePositionStyle}
        >
          <DescriptionPushContent
            {...props}
            themeMode={themeModeStyle}
            PushContainerFlexDirection={PushContainerFlexDirection}
            imagePositionStyle={imagePositionStyle}
            isImage={isImage}
          />
        </PushContainer>
      )}
    </Container>
  );
};

DescriptionPush.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  body: PropTypes.string,
  tooltip: PropTypes.string,
  cta: PropTypes.object,
  image: PropTypes.object,
  imagePosition: PropTypes.string,
  themeMode: PropTypes.string,
};

export default DescriptionPush;
