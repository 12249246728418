import { IMG_TYPES } from '@atoms/Img';
import mappers from '@templates/mappers';
import getRecipeJSON from './getRecipeMeta';
import getImageFields from './getImageFields';

export default (page, settings, heroData, brandName) => {
  const containsRecipe =
    page &&
    page.pageComponents.length !== 0 &&
    page.pageComponents.some(
      (item) => item.__typename === 'ContentfulRecipeDetails'
    );
  const jsonld = [
    containsRecipe &&
      getRecipeJSON(
        mappers.ContentfulRecipeDetails.mapper(
          page.pageComponents.find(
            (item) => item.__typename === 'ContentfulRecipeDetails'
          )
        ),
        heroData,
        getImageFields(settings.logo, IMG_TYPES.fluid).src,
        brandName
      ),
  ];

  return jsonld;
};
