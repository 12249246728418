import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import MainLayout from '@layouts/Main';
import Metadata from '@atoms/Metadata';
import { getSettings, getCommonJSON, getPageMeta } from '@utils';

import Error from '@molecules/Error';
import trackEvent from './trackEvent';

const ErrorPage = ({ data, pageContext, path }) => {
  // Grab settings from the data, which is the result from the gql query below
  const settings = getSettings(
    data.contentfulSettings,
    data.allContentfulLabel,
    pageContext
  );
  const page = pageContext.errorPageData;
  const pageSeo = getPageMeta(page.seo, settings.brandName);

  const jsonld = [...getCommonJSON(null, settings, null, settings.brandName)];

  useEffect(() => {
    window.tc_vars = {
      country: process.env.GATSBY_MARKET,
      environment: process.env.GATSBY_TRACKING_ENV,
      pageName: pageSeo.title,
      url: `${pageContext.domain}${path}`,
    };

    trackEvent(path);
  }, [pageSeo.title, pageContext.domain, path]);

  return (
    <MainLayout settings={settings}>
      <Metadata
        path={pageContext.id === 'not-found' ? '404' : 'home'}
        meta={{}}
        jsonld={jsonld}
      />
      <Error {...page} />
    </MainLayout>
  );
};

/**
 * GraphQL Page query
 */
export const pageQuery = graphql`
  query getErrorPageData($nodeLocale: String!, $tag: String!) {
    contentfulSettings(
      metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
      node_locale: { eq: $nodeLocale }
    ) {
      ...Settings
    }

    allContentfulLabel(
      filter: {
        metadata: { tags: { elemMatch: { contentful_id: { eq: $tag } } } }
        node_locale: { eq: $nodeLocale }
      }
    ) {
      nodes {
        ...Label
      }
    }
  }
`;

ErrorPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    nodeLocale: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    marketData: PropTypes.object.isRequired,
    domain: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    errorPageData: PropTypes.object.isRequired,
  }).isRequired,
  path: PropTypes.string.isRequired,
};

export default ErrorPage;
