import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Img, { IMG_TYPES, IMG_FITS } from '@atoms/Img';
import { rgba } from '@utils';
import LightningFilled from '@icons/cardlightningfilled.svg';
import Link, { LINK_TYPES } from '@atoms/Link';
import InfluencerTag from '@atoms/InfluencerTag';
import PlusOneButton from '@atoms/PlusOneButton';
import { cardIcons } from './types';

const Container = styled(Link)`
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  border-radius: ${({ theme }) => theme.spacing(5)};
  position: relative;
  cursor: pointer;
`;

const fullLayer = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Overlay = styled.div`
  ${fullLayer};
  background: ${({ theme }) => rgba(theme.colors.black, 0.4)};
`;

const Content = styled.div`
  ${fullLayer};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(3)};
`;

const IconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(3)};
    left: ${theme.spacing(3)};
  `}
`;

const InfluencerIconContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(3)};
    right: ${theme.spacing(3)};
  `}
`;

const StyledInfluencerTag = styled(InfluencerTag)`
  ${({ theme, highlighted }) => css`
    ${highlighted
      ? css`
          align-self: flex-start;
          margin-bottom: ${theme.spacing(2)};

          ${theme.mediaquery.sm(css`
            margin-bottom: ${theme.spacing(3)};
          `)}
        `
      : css`
          display: none;
        `}
  `}
`;

const TitleContainer = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const Title = styled.p`
  ${({ theme, highlighted }) => css`
    ${highlighted ? theme.typography.titleM : theme.typography.titleXS};
    color: ${theme.colors.white};
    padding-top: ${theme.spacing(1)};

    ${theme.mediaquery.sm(css`
      ${highlighted ? theme.typography.titleM : theme.typography.titleS};
    `)}
  `}
`;

const Card = ({
  background,
  type,
  title,
  influencer = undefined,
  highlighted = false,
  slug,
}) => {
  const Icon = cardIcons[type];

  return (
    <Container title={title} url={slug} type={LINK_TYPES.internal}>
      <div css={fullLayer}>
        <Img small={background} type={IMG_TYPES.fluid} fit={IMG_FITS.cover} />
      </div>
      <Overlay />
      <Content>
        <IconContainer>
          <Icon />
        </IconContainer>
        {!!influencer && !highlighted && (
          <InfluencerIconContainer>
            <LightningFilled />
          </InfluencerIconContainer>
        )}
        {!!influencer && (
          <StyledInfluencerTag name={influencer} highlighted={highlighted} />
        )}
        <TitleContainer>
          <Title highlighted={highlighted}>{title}</Title>
        </TitleContainer>
      </Content>
      <PlusOneButton favoriteId={slug} slug={slug} />
    </Container>
  );
};

Card.propTypes = {
  background: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  influencer: PropTypes.string,
  highlighted: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

export default React.memo(Card);
