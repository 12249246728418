import { css } from 'styled-components';

export const breakpoints = {
  xs: 375,
  sm: 768,
  md: 1024,
  lg: 1330,
};

export const mediaquery = Object.keys(breakpoints).reduce(
  (acc, label) => ({
    ...acc,
    [label]: (...rules) => css`
      @media (min-width: ${breakpoints[label]}px) {
        ${rules}
      }
    `,
  }),
  {}
);
