import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { adapter as linkAdapter } from '@atoms/Link';
import { getImageFields, getMetaImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';

export const SettingsContext = React.createContext();

const SettingsProvider = ({ value, children }) => {
  if (typeof value !== 'object') {
    throw new Error(`Expecting Settings object, received "${value}"`);
  }

  const mappedValues = useMemo(() => {
    return {
      seo: {
        ...value.seo,
        image:
          value.seo?.image &&
          getMetaImageFields(value.seo.image, IMG_TYPES.fixed),
      },
      id: value.id,
      locale: value.locale,
      domain: value.domain,
      gtagID: value.gtagID,
      market: value.market,
      brandName: value.brandName,
      localizedPageData: Object.keys(value.localizedPageData).map(
        (locale) => value.localizedPageData[locale]
      ),
      clpSlug: value.clpSlug,
      nav: {
        logo: getImageFields(value.logo, IMG_TYPES.fluid),
        navLinks: value.mainNavLinks.map((navLink) => linkAdapter(navLink)),
        alignMenuLeft: value.alignMenuLeft,
        shoppingCartUrl: value.shoppingCartUrl,
      },
      footer: {
        countryFlag: value.countryFlag,
        footerLinks: value.footerLinks.map((footerLink) =>
          linkAdapter(footerLink)
        ),
        footerCopyright: value.footerCopyright,
        facebookLink: value.facebookLink,
        twitterLink: value.twitterLink,
        instaLink: value.instaLink,
        pinterestLi: value.pinterestLink,
        youtubeLink: value.youtubeLink,
      },
      translations: value.labels.reduce(
        (acc, label) => ({
          ...acc,
          [label.key]: label.value,
        }),
        {}
      ),
      allArticlesPageTitle: value.allArticlesPageTitle,
      allArticlesPageSlug: value.allArticlesPageSlug,
      allArticlesPageSeo: {
        ...value.allArticlesPageSeo,
        image:
          value.allArticlesPageSeo?.image &&
          getMetaImageFields(value.allArticlesPageSeo.image, IMG_TYPES.fixed),
      },
      allHcpPageTitle: value.allHcpPageTitle,
      allHcpPageSlug: value.allHcpPageSlug,
      emailDisplayName: value.emailDisplayName,
      receivingEmail: value.receivingEmail,
      emailConsent: value.emailConsent?.value,
    };
  }, [value]);

  return (
    <SettingsContext.Provider value={mappedValues}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsShape = {
  seo: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }),
  mainNavLinks: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  footerLinks: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  facebookURL: PropTypes.string,
  twitterURL: PropTypes.string,
  instagramURL: PropTypes.string,
  pinterestURL: PropTypes.string,
  youtubeURL: PropTypes.string,
  canonicalUrLs: PropTypes.arrayOf(PropTypes.string),
  emailDisplayName: PropTypes.string,
  receivingEmail: PropTypes.string,
  emailConsent: PropTypes.object,
};

SettingsProvider.propTypes = {
  value: PropTypes.shape(SettingsShape).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SettingsProvider;
