import { getImageFields } from '@utils';
import { IMG_TYPES } from '@atoms/Img';
import { adapter as linkAdapter } from '@atoms/Link';

export default ({
  imageDesktop,
  imageMobile,
  cta,
  textOverlay,
  useImageGradient,
  fullBleedImage,
}) => {
  return {
    pictures: {
      desktop: {
        ...getImageFields(imageDesktop, IMG_TYPES.fluid),
        id: imageDesktop.id,
      },
      mobile: {
        ...getImageFields(imageMobile, IMG_TYPES.fluid),
        id: imageMobile.id,
      },
    },
    showGradient: useImageGradient,
    cta: cta && linkAdapter(cta),
    textOverlay,
    fullWidth: fullBleedImage,
  };
};
